import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Api } from "../../../services/axios.service";
import { msHelper } from "../../../services/ms-helper.service";

interface RoomImageBlockProps {
  image: {
    id: string;
    image: string;
    tag: string;
  };
  getRoomImages: () => void;
}

const RoomImageBlock: React.FC<RoomImageBlockProps> = ({
  image,
  getRoomImages,
}) => {
  const [deletingImage, setDeletingImage] = useState<boolean>(false);

  const deleteImage = (imageId: string) => {
    setDeletingImage(true);
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);
    formData.append("id", imageId);

    Api.post("Admin/rooms/delete_room_image", formData)
      .then((res) => {
        setDeletingImage(false);
        if (res.data["status"] === "success") {
          getRoomImages();
        }
      })
      .catch((error) => {
        setDeletingImage(false);
        console.log(error);
      });
  };

  return (
    <div className="mt-3 card">
      <img
        className="img-fluid"
        src={`${msHelper.ImageUrl}/rooms/${image.image}`}
        alt={image.tag}
      />
      <div style={{ position: "absolute", background: "#fff", right: 0 }}>
        <button
          onClick={() => deleteImage(image.id)}
          className="btn m-0 px-2 py-0"
          style={{ fontWeight: "bold", color: "red" }}
        >
          <FontAwesomeIcon icon={faTimes as any} />
        </button>
      </div>
      <div style={{ textAlign: "center" }}>{image.tag}</div>
    </div>
  );
};

export default RoomImageBlock;
