import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faEye,
  faEyeSlash,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { Api } from "../../../services/axios.service";
import EditVendorModal from "./EditVendorModal";
import { VendorData } from "../VendorsPage";

interface VendorEditBlockProps {
  item: VendorData;
  refreshList: () => void;
}

const VendorEditBlock: React.FC<VendorEditBlockProps> = ({
  item,
  refreshList,
}) => {
  const [modalShow, setModalShow] = useState<boolean>(false);

  const activeInactive = (id: number) => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("id", id.toString());

    Api.post("Admin/vendors/active_inactive_vendor", formData).then((res) => {
      if (res.data["status"] === "success") {
        refreshList();
      } else {
        console.log(res.data);
      }
    });
  };

  const toggleModal = () => {
    setModalShow(!modalShow);
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Button variant="info" onClick={() => setModalShow(true)}>
          <FontAwesomeIcon icon={faEdit as any} />
        </Button>
        <Link to={`/view-vendor/${item.id}`} className="btn btn-success">
          <FontAwesomeIcon icon={faUserAlt as any} />
        </Link>
        <Button
          variant="primary"
          onClick={() => {
            activeInactive(item.id);
          }}
        >
          <FontAwesomeIcon
            icon={(item.active === "0" ? faEyeSlash : faEye) as any}
          />
        </Button>
      </ButtonGroup>

      <EditVendorModal
        show={modalShow}
        toggleModal={toggleModal}
        refreshList={refreshList}
        item={item as any}
      />
    </>
  );
};

export default VendorEditBlock;
