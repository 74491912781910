import React, { Component } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import AddOfferModal from "./components/AddOfferModal";
import OffersEditBlock from "./components/OffersEditBlock";

interface AllOffersState {
  Table_data: any[]; // Adjust the type based on your API response structure
  editData: any | null; // Adjust the type based on what `editData` should hold
  showAddModel: boolean;
  TableLoading: boolean;
}

const fields = ["id", "title", "sub_title", "valid_till", "action"];

class AllOffers extends Component<{}, AllOffersState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      Table_data: [],
      editData: null,
      showAddModel: false,
      TableLoading: true,
    };
    this.loadData = this.loadData.bind(this);
    this.closeAddModel = this.closeAddModel.bind(this);
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    if (token) {
      formData.append("token", token);
      Api.post("Admin/offers", formData)
        .then((res) => {
          if (res.data["status"] === "success") {
            this.setState({
              Table_data: res.data["data"],
              TableLoading: false,
            });
          } else {
            this.setState({ Table_data: [], TableLoading: false });
          }
        })
        .catch((error) => {
          console.error("Error fetching offers:", error);
          this.setState({ Table_data: [], TableLoading: false });
        });
    }
  }

  closeAddModel() {
    this.setState({
      showAddModel: false,
    });
  }

  render() {
    return (
      <div>
        <CCol xs="12" lg="12">
          <CCard>
            <CCardHeader>
              All Offers
              <button
                onClick={() => {
                  this.setState({
                    showAddModel: true,
                  });
                }}
                className="btn btn-success float-right"
              >
                Add Offers <FontAwesomeIcon icon={faPlus as any} />
              </button>
            </CCardHeader>
            <CCardBody>
              {this.state.TableLoading ? (
                <LoadingComponent />
              ) : (
                <CDataTable
                  items={this.state.Table_data}
                  fields={fields}
                  striped
                  itemsPerPage={10}
                  pagination
                  scopedSlots={{
                    action: (item: any) => (
                      <td>
                        <OffersEditBlock
                          refreshList={this.loadData}
                          item={item}
                        />
                      </td>
                    ),
                  }}
                />
              )}
            </CCardBody>
          </CCard>
        </CCol>

        <AddOfferModal
          show={this.state.showAddModel}
          closeModel={this.closeAddModel}
          refreshList={this.loadData}
          {...this.props}
        />
      </div>
    );
  }
}

export default AllOffers;
