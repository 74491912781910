import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { msHelper } from "../../../services/ms-helper.service";

interface AmenitiesCheckBoxProps {
  amenity: {
    id: number;
    amenity: string;
  };
  amenities: string[];
}

const AmenitiesCheckBox: React.FC<AmenitiesCheckBoxProps> = ({
  amenity,
  amenities,
}) => {
  console.log("amenities", amenities);

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (amenities.length > 0) {
      setChecked(
        msHelper.amenitiesFind(
          amenity.id,
          amenities
            ? Array.isArray(JSON.parse(JSON.stringify(amenities)))
              ? JSON.parse(JSON.stringify(amenities))
              : []
            : []
        ) === 1
      );
    }
  }, [amenity, amenities]);

  return (
    <>
      <Form.Check
        name="amenities"
        className="mycheckbox"
        value={amenity.id.toString()}
        inline
        label={amenity.amenity}
        type={"checkbox"}
        checked={checked}
        onChange={() => setChecked(!checked)}
        id={`inline-checkbox-${amenity.amenity}`}
      />
    </>
  );
};

export default AmenitiesCheckBox;
