import React, { useState } from "react";
import { Modal, Form, Button, Col } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

interface EditRefundStatusModelProps {
  data: {
    id: number;
    status: string;
    note: string;
  };
  show: boolean;
  closeModel: () => void;
  refreshList: () => void;
}

const EditRefundStatusModel: React.FC<EditRefundStatusModelProps> = ({
  data,
  show,
  closeModel,
  refreshList,
}) => {
  const [formError, setFormError] = useState<any>(""); // use any for complex error object
  const [formSuccess, setFormSuccess] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");
    setLoading(true);

    const formData = new FormData(e.currentTarget);

    const token = localStorage.getItem("token") || "";
    const ID = data.id;
    formData.append("token", token);
    formData.append("refund_id", ID.toString());
    formData.append("status", e.currentTarget.status.value);
    formData.append("note", e.currentTarget.note.value);

    try {
      const res = await Api.post("Admin/refund/update_status", formData);
      if (res.data.status === "success") {
        setFormSuccess(res.data.msg);
        refreshList();
        setTimeout(() => {
          closeModel();
          setFormError("");
          setFormSuccess("");
          setLoading(false);
        }, 1000);
      } else {
        setFormError(res.data.formErrors);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error updating status:", error);
      setLoading(false);
    }
  };

  return (
    <Modal show={show} onHide={closeModel} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Update Property Status</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {formSuccess && <CAlert color="success">{formSuccess}</CAlert>}
        <Form encType="multipart/form-data" onSubmit={handleSubmit}>
          <Form.Row>
            <Col lg="12">
              <Form.Group controlId="exampleForm.SelectCustom">
                <Form.Label>Status</Form.Label>
                <Form.Control name="status" as="select" custom>
                  <option value="">Select Status</option>
                  <option
                    value="requested"
                    selected={data.status === "requested"}
                  >
                    Requested
                  </option>
                  <option
                    value="partially refunded"
                    selected={data.status === "partially refunded"}
                  >
                    Partially Refunded
                  </option>
                  <option value="success" selected={data.status === "success"}>
                    Success
                  </option>
                  <option
                    value="cancelled"
                    selected={data.status === "cancelled"}
                  >
                    Cancelled
                  </option>
                </Form.Control>
                <div className="invalid-feedback d-block">
                  {formError.status}
                </div>
              </Form.Group>
              <Form.Group controlId="exampleForm.ControlTextarea1">
                <Form.Label>Note:</Form.Label>
                <Form.Control
                  defaultValue={data.note}
                  required
                  name="note"
                  as="textarea"
                  rows={3}
                />
                <div className="invalid-feedback d-block">{formError.note}</div>
              </Form.Group>
              <Form.Group>
                <Form.Check
                  label="Send SMS"
                  value="true"
                  name="send_sms"
                  type="checkbox"
                />
              </Form.Group>
            </Col>
          </Form.Row>

          {loading ? (
            <Button variant="primary" type="button" disabled>
              Please Wait....
            </Button>
          ) : (
            <Button variant="primary" type="submit">
              Update
            </Button>
          )}
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default EditRefundStatusModel;
