import React, { useState, useEffect, useRef } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

const GeneralInformationPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState<any>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [mobile, setMobile] = useState<string>("");
  const [userHelpEmail, setUserHelpEmail] = useState<string>("");
  const [userHelpMobile, setUserHelpMobile] = useState<string>("");
  const [vendorSupportEmail, setVendorSupportEmail] = useState<string>("");
  const [vendorSupportMobile, setVendorSupportMobile] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const [room2Charges, setRoom2Charges] = useState<number>(100);
  const [room3Charges, setRoom3Charges] = useState<number>(100);
  const [room4Charges, setRoom4Charges] = useState<number>(100);
  const [room5Charges, setRoom5Charges] = useState<number>(100);
  const [room6Charges, setRoom6Charges] = useState<number>(100);
  const [night2Charges, setNight2Charges] = useState<number>(100);
  const [night4Charges, setNight4Charges] = useState<number>(100);
  const [night8Charges, setNight8Charges] = useState<number>(100);
  const [guest2Charges, setGuest2Charges] = useState<number>(100);
  const [guest3Charges, setGuest3Charges] = useState<number>(100);
  const [aUserAmount, setAUserAmount] = useState<number>(0);
  const [bUserAmount, setBUserAmount] = useState<number>(0);
  const [referralAmountDeduction, setReferralAmountDeduction] =
    useState<number>(0);

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    Api.post("Admin/Settings", formData).then((res) => {
      const data = res.data["data"];
      if (res.data["status"] === "success") {
        setEmail(data.email);
        setMobile(data.mobile);
        setUserHelpEmail(data.user_help_email);
        setUserHelpMobile(data.user_help_mobile);
        setVendorSupportEmail(data.vendor_support_email);
        setVendorSupportMobile(data.vendor_support_mobile);
        setAddress(data.address);
        setRoom2Charges(data.room_2_charges);
        setRoom3Charges(data.room_3_charges);
        setRoom4Charges(data.room_4_charges);
        setRoom5Charges(data.room_5_charges);
        setRoom6Charges(data.room_6_charges);
        setNight2Charges(data.night_2_charges);
        setNight4Charges(data.night_4_charges);
        setNight8Charges(data.night_8_charges);
        setGuest2Charges(data.guest_2_charges);
        setGuest3Charges(data.guest_3_charges);
      } else {
        setEmail("");
        setMobile("");
        setUserHelpEmail("");
        setUserHelpMobile("");
        setVendorSupportEmail("");
        setVendorSupportMobile("");
        setAddress("");
      }
    });
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormError("");
    setFormSuccess("");

    const formdata = new FormData(e.currentTarget);

    let token = localStorage.getItem("token");

    formdata.append("token", token ?? "");
    formdata.append("email", email);
    formdata.append("mobile", mobile);
    formdata.append("user_help_email", userHelpEmail);
    formdata.append("user_help_mobile", userHelpMobile);
    formdata.append("vendor_support_email", vendorSupportEmail);
    formdata.append("vendor_support_mobile", vendorSupportMobile);
    formdata.append("address", address);

    // Room Charges
    formdata.append("room_2_charges", room2Charges.toString());
    formdata.append("room_3_charges", room3Charges.toString());
    formdata.append("room_4_charges", room4Charges.toString());
    formdata.append("room_5_charges", room5Charges.toString());
    formdata.append("room_6_charges", room6Charges.toString());

    // Night Charges
    formdata.append("night_2_charges", night2Charges.toString());
    formdata.append("night_4_charges", night4Charges.toString());
    formdata.append("night_8_charges", night8Charges.toString());

    // Guest Charges
    formdata.append("guest_2_charges", guest2Charges.toString());
    formdata.append("guest_3_charges", guest3Charges.toString());

    // Referrals amounts
    formdata.append("a_user_amount", aUserAmount.toString());
    formdata.append("b_user_amount", bUserAmount.toString());
    formdata.append(
      "referral_amount_deduction",
      referralAmountDeduction.toString()
    );

    Api.post("Admin/settings/update_genral_info", formdata).then((res) => {
      window.scrollTo(0, 0);
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        if (formRef.current) {
          formRef.current.reset();
        }
      } else {
        setFormError(res.data["formErrors"]);
      }
    });
  };

  return (
    <div>
      <CCard>
        <CCardHeader>
          <h4>General Information</h4>
        </CCardHeader>
        <CCardBody>
          {formError && (
            <div>
              <CAlert color="danger">{formError}</CAlert>
            </div>
          )}
          {formSuccess && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form ref={formRef} onSubmit={handleSubmit}>
            <Row className="form-sections">
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    type="email"
                    placeholder="Enter Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {formError.email}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    name="mobile"
                    type="text"
                    placeholder="Enter Mobile number"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {formError.mobile}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>User Help Email</Form.Label>
                  <Form.Control
                    name="user_help_email"
                    type="email"
                    placeholder="Enter Email"
                    value={userHelpEmail}
                    onChange={(e) => setUserHelpEmail(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {formError.user_help_email}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>User Help Mobile</Form.Label>
                  <Form.Control
                    name="user_help_mobile"
                    type="text"
                    placeholder="Enter Mobile number"
                    value={userHelpMobile}
                    onChange={(e) => setUserHelpMobile(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {formError.user_help_mobile}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Vendor Support Email</Form.Label>
                  <Form.Control
                    name="vendor_support_email"
                    type="email"
                    placeholder="Enter Email"
                    value={vendorSupportEmail}
                    onChange={(e) => setVendorSupportEmail(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {formError.vendor_support_email}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Vendor Support Mobile</Form.Label>
                  <Form.Control
                    name="vendor_support_mobile"
                    type="text"
                    placeholder="Enter Mobile number"
                    value={vendorSupportMobile}
                    onChange={(e) => setVendorSupportMobile(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {formError.vendor_support_mobile}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    name="address"
                    as="textarea"
                    rows={3}
                    placeholder="Enter Address"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                  <div className="invalid-feedback d-block">
                    {formError.address}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <h5>Room Charges</h5>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Room 2 Charges</Form.Label>
                  <Form.Control
                    name="room_2_charges"
                    type="number"
                    placeholder="Enter Room 2 Charges"
                    value={room2Charges}
                    onChange={(e) =>
                      setRoom2Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Room 3 Charges</Form.Label>
                  <Form.Control
                    name="room_3_charges"
                    type="number"
                    placeholder="Enter Room 3 Charges"
                    value={room3Charges}
                    onChange={(e) =>
                      setRoom3Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Room 4 Charges</Form.Label>
                  <Form.Control
                    name="room_4_charges"
                    type="number"
                    placeholder="Enter Room 4 Charges"
                    value={room4Charges}
                    onChange={(e) =>
                      setRoom4Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Room 5 Charges</Form.Label>
                  <Form.Control
                    name="room_5_charges"
                    type="number"
                    placeholder="Enter Room 5 Charges"
                    value={room5Charges}
                    onChange={(e) =>
                      setRoom5Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Room 6 Charges</Form.Label>
                  <Form.Control
                    name="room_6_charges"
                    type="number"
                    placeholder="Enter Room 6 Charges"
                    value={room6Charges}
                    onChange={(e) =>
                      setRoom6Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <h5>Night Charges</h5>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Night 2 Charges</Form.Label>
                  <Form.Control
                    name="night_2_charges"
                    type="number"
                    placeholder="Enter Night 2 Charges"
                    value={night2Charges}
                    onChange={(e) =>
                      setNight2Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Night 4 Charges</Form.Label>
                  <Form.Control
                    name="night_4_charges"
                    type="number"
                    placeholder="Enter Night 4 Charges"
                    value={night4Charges}
                    onChange={(e) =>
                      setNight4Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Night 8 Charges</Form.Label>
                  <Form.Control
                    name="night_8_charges"
                    type="number"
                    placeholder="Enter Night 8 Charges"
                    value={night8Charges}
                    onChange={(e) =>
                      setNight8Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <h5>Guest Charges</h5>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Guest 2 Charges</Form.Label>
                  <Form.Control
                    name="guest_2_charges"
                    type="number"
                    placeholder="Enter Guest 2 Charges"
                    value={guest2Charges}
                    onChange={(e) =>
                      setGuest2Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Guest 3 Charges</Form.Label>
                  <Form.Control
                    name="guest_3_charges"
                    type="number"
                    placeholder="Enter Guest 3 Charges"
                    value={guest3Charges}
                    onChange={(e) =>
                      setGuest3Charges(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col lg="12">
                <h5>Referrals Amounts</h5>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>A User Amount</Form.Label>
                  <Form.Control
                    name="a_user_amount"
                    type="number"
                    placeholder="Enter A User Amount"
                    value={aUserAmount}
                    onChange={(e) => setAUserAmount(parseFloat(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>B User Amount</Form.Label>
                  <Form.Control
                    name="b_user_amount"
                    type="number"
                    placeholder="Enter B User Amount"
                    value={bUserAmount}
                    onChange={(e) => setBUserAmount(parseFloat(e.target.value))}
                  />
                </Form.Group>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Referral Amount Deduction</Form.Label>
                  <Form.Control
                    name="referral_amount_deduction"
                    type="number"
                    placeholder="Enter Referral Amount Deduction"
                    value={referralAmountDeduction}
                    onChange={(e) =>
                      setReferralAmountDeduction(parseFloat(e.target.value))
                    }
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" variant="primary">
              Save
            </Button>
          </Form>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default GeneralInformationPage;
