import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import moment from "moment";
import { Form } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import { msHelper } from "../../services/ms-helper.service";

const fields = [
  "booking_id",
  "user_name",
  "hotel_name",
  "hotel_id",
  "checkin_date",
  "checkout_date",
  "Booking_date",
  "status",
];

interface Booking {
  booking_id: string;
  user_name: string | null;
  full_name: string;
  hotel_name: string;
  hotel_id: number;
  checkin_date: string;
  checkout_date: string;
  created_at: string;
  status: string;
  order_id: string;
}

interface Vendor {
  id: number;
  full_name: string;
}

const ViewBookingDataPage = () => {
  const params = useParams();
  const [tableData, setTableData] = useState<Booking[]>([]);
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [loadingVendor, setLoadingVendor] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  useEffect(() => {
    loadData();
    getVendorsList();
  }, []);

  const loadData = async () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    try {
      const response = await Api.post(
        `Admin/dashboard/top_widget_data_filter/${params?.filter}`,
        formData
      );
      if (response.data.status === "success") {
        setTableData(response.data.data);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    } catch (error) {
      console.error("Error loading data", error);
      setTableLoading(false);
    }
  };

  const getVendorsList = async () => {
    setLoadingVendor(true);
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    try {
      const response = await Api.post("Admin/vendors", formData);
      if (response.data.status === "success") {
        setVendorList(response.data.data);
      } else {
        setVendorList([]);
      }
      setLoadingVendor(false);
    } catch (error) {
      console.error("Error loading vendors", error);
      setLoadingVendor(false);
    }
  };

  const filterVendorBooking = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTableLoading(true);
    const vendorId = e.target.value;

    if (vendorId === "") {
      loadData();
      return;
    }

    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("vendor_id", vendorId);

    try {
      const response = await Api.post(
        "Admin/Booked_room/get_vendor_booked_rooms",
        formData
      );
      if (response.data.status === "success") {
        setTableData(response.data.data);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    } catch (error) {
      console.error("Error filtering vendor bookings", error);
      setTableLoading(false);
    }
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader className="d-flex justify-content-between align-items-center">
            Silver Stays Booking
            <div className="filter_Form">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  name="vendor_filter"
                  onChange={filterVendorBooking}
                  as="select"
                >
                  <option value="">--- Select Vendor ---</option>
                  {loadingVendor ? (
                    <option>--- Loading..... ---</option>
                  ) : (
                    vendorList.map((list) => (
                      <option key={list.id} value={list.id}>
                        {list.full_name}
                      </option>
                    ))
                  )}
                </Form.Control>
              </Form.Group>
            </div>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                tableFilter
                pagination
                itemsPerPage={10}
                scopedSlots={{
                  booking_id: (item: Booking) => <td>{"#" + item.order_id}</td>,
                  user_name: (item: Booking) => (
                    <td>
                      {item.user_name === null
                        ? item.full_name
                        : item.user_name}
                    </td>
                  ),
                  hotel_id: (item: Booking) => (
                    <td>{"#" + msHelper.PadNumber(item.hotel_id, 4)}</td>
                  ),
                  Booking_date: (item: Booking) => (
                    <td>{moment(item.created_at).format("YYYY-MM-DD")}</td>
                  ),
                  status: (item: Booking) => (
                    <td>
                      <span
                        className={
                          item.status === "booked"
                            ? "badge badge-success"
                            : item.status === "cancelled"
                            ? "badge badge-danger"
                            : item.status === "pending"
                            ? "badge badge-warning"
                            : "badge badge-secondary"
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default ViewBookingDataPage;
