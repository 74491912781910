interface IBreadcrumb {
  url?: string;
  name: string;
}
export const getBreadCrumbs = (url: string): IBreadcrumb[] => {
  return data.find((e) => url.startsWith(e.url))?.breadcrumbs ?? [];
};

const data: { url: string; breadcrumbs: IBreadcrumb[] }[] = [
  {
    url: "/dashboard",
    breadcrumbs: [{ name: "Dashboard" }],
  },
  {
    url: "/users",
    breadcrumbs: [{ name: "Users" }],
  },
  {
    url: "/view-user",
    breadcrumbs: [{ name: "View User" }],
  },
  {
    url: "/view-booking-data",
    breadcrumbs: [{ name: "view booking data" }],
  },
  {
    url: "/view-issue-data",
    breadcrumbs: [{ name: "view issue data" }],
  },
  {
    url: "/in-active-users",
    breadcrumbs: [{ name: "In-Active Users" }],
  },
  {
    url: "/vendors",
    breadcrumbs: [{ name: "Vendors" }],
  },
  {
    url: "/in-active-vendor",
    breadcrumbs: [{ url: "", name: "In-Active Vendors" }],
  },
  {
    url: "/property-list",
    breadcrumbs: [{ name: "Property List" }],
  },
  {
    url: "/view-property-detail",
    breadcrumbs: [{ name: "view property detail" }],
  },
  {
    url: "/view-vendor",
    breadcrumbs: [{ name: "View Vendor" }],
  },
  {
    url: "/helps",
    breadcrumbs: [{ name: "Helps" }],
  },
  {
    url: "/contacts",
    breadcrumbs: [{ name: "Contact Us" }],
  },
  {
    url: "/refund-request",
    breadcrumbs: [{ name: "Refund Request" }],
  },
  {
    url: "/booked-rooms/all-bookings",
    breadcrumbs: [
      { name: "Booked Rooms", url: "/booked-rooms" },
      { name: "All Bookings" },
    ],
  },
  {
    url: "/booked-rooms/all-app-bookings",
    breadcrumbs: [
      { name: "Booked Rooms", url: "/booked-rooms" },
      { name: "Silver Stays Booking" },
    ],
  },
  {
    url: "/booked-rooms/all-previous-bookings",
    breadcrumbs: [
      { name: "Booked Rooms", url: "/booked-rooms" },
      { name: "Previous Bookings" },
    ],
  },
  {
    url: "/booked-rooms/all-no-show-bookings",
    breadcrumbs: [
      { name: "Booked Rooms", url: "/booked-rooms" },
      { name: "No Show Bookings" },
    ],
  },
  {
    url: "/booked-rooms/all-walk-in-bookings",
    breadcrumbs: [
      { name: "Booked Rooms", url: "/booked-rooms" },
      { name: "Walk-In Bookings" },
    ],
  },
  {
    url: "/booked-rooms",
    breadcrumbs: [{ name: "Booked Rooms" }],
  },
  {
    url: "/price-modal",
    breadcrumbs: [{ name: "Price Modal" }],
  },
  {
    url: "/raise-issue",
    breadcrumbs: [{ name: "Raise Issue" }],
  },
  {
    url: "/hotels/edit-hotel",
    breadcrumbs: [{ name: "Edit Hotel" }],
  },
  {
    url: "/hotels/add-hotels",
    breadcrumbs: [{ name: "Hotels", url: "/hotels" }, { name: "Add Hotel" }],
  },
  {
    url: "/hotels/all-hotels",
    breadcrumbs: [{ name: "Hotels", url: "/hotels" }, { name: "All Hotel" }],
  },
  {
    url: "/hotels/all-ratings",
    breadcrumbs: [{ name: "Hotels", url: "/hotels" }, { name: "All Rating" }],
  },
  {
    url: "/hotels/room-price-requests",
    breadcrumbs: [
      { name: "Hotels", url: "/hotels" },
      { name: "Hotel Room Price Request" },
    ],
  },
  {
    url: "/hotels",
    breadcrumbs: [{ name: "Hotels" }],
  },
  {
    url: "/rooms-category/all-rooms-category",
    breadcrumbs: [{ name: "All Room Category" }],
  },
  {
    url: "/rooms",
    breadcrumbs: [{ name: "All Rooms" }],
  },
  {
    url: "/room/edit",
    breadcrumbs: [{ name: "Edit Room" }],
  },
  {
    url: "/home-setting/home-cities",
    breadcrumbs: [{ name: "Home Cities" }],
  },
  {
    url: "/home-setting/popular-hotels",
    breadcrumbs: [{ name: "Popular Hotels" }],
  },
  {
    url: "/coupons/all-coupons",
    breadcrumbs: [{ name: "All Coupons" }],
  },
  {
    url: "/coupons/edit-coupon",
    breadcrumbs: [{ name: "Edit Coupon" }],
  },
  {
    url: "/coupons/add-coupons",
    breadcrumbs: [{ name: "Add Coupon" }],
  },
  {
    url: "/offers/all-offers",
    breadcrumbs: [{ name: "All Offers" }],
  },
  {
    url: "/amenities/all-amenities",
    breadcrumbs: [{ name: "All Amenities" }],
  },
  {
    url: "/amenities/add-amenities",
    breadcrumbs: [{ name: "Add Amenities" }],
  },
  {
    url: "/notification/send",
    breadcrumbs: [{ name: "Send Notification" }],
  },
  {
    url: "/notification/default-notifications",
    breadcrumbs: [{ name: "Default Notifications" }],
  },
  {
    url: "/policy/all-policy",
    breadcrumbs: [{ name: "All Policy" }],
  },
  {
    url: "/settings/guest-policy",
    breadcrumbs: [
      { name: "Settings", url: "/settings" },
      { name: "Guest Policy" },
    ],
  },
  {
    url: "/settings/cancellation-policy",
    breadcrumbs: [
      { name: "Settings", url: "/settings" },
      { name: "Cancellation policy" },
    ],
  },
  {
    url: "/settings/term-and-condition",
    breadcrumbs: [
      { name: "Settings", url: "/settings" },
      { name: "Privacy Policy" },
    ],
  },
  {
    url: "/settings/genral-information",
    breadcrumbs: [
      { name: "Settings", url: "/settings" },
      { name: "General Information" },
    ],
  },
  {
    url: "/settings/vendor-policy",
    breadcrumbs: [
      { name: "Settings", url: "/settings" },
      { name: "Vendor Policy" },
    ],
  },
  {
    url: "/settings",
    breadcrumbs: [{ name: "Settings" }],
  },
];
