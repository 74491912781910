import React, { useState } from "react";
import { Modal, Form, Button, Col, Image } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";
import { msHelper } from "../../../services/ms-helper.service";

interface EditRoomCateModalProps {
  show: boolean;
  closeModel: () => void;
  refreshList: () => void;
  data: {
    id: number;
    category: string;
    cate_image: string;
  };
}

const EditRoomCateModal: React.FC<EditRoomCateModalProps> = ({
  show,
  closeModel,
  refreshList,
  data,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [category, setCategory] = useState<string>(data.category);
  const [cateimage, setCateImage] = useState<File | string>(data.cate_image);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCategory(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.currentTarget);

    let token = localStorage.getItem("token");
    var id = data.id;

    formData.append("token", token as string);
    formData.append("id", id.toString());
    formData.append("category", formData.get("category") as string);
    formData.append("cateimage", cateimage as File | string);

    var config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/categories/update_room_category", formData, config)
      .then((res) => {
        if (res.data["status"] === "success") {
          setFormSuccess(res.data["msg"]);
          refreshList();
          setTimeout(() => {
            closeModel();
            setFormError("");
            setFormSuccess("");
            setCateImage(data.cate_image);
          }, 1000);
        } else {
          setFormError(res.data["msg"]);
          console.log(res.data);
        }
      })
      .catch((error) => {
        console.error("Error updating room category:", error);
        setFormError("An error occurred while updating the room category.");
      });
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setCateImage(e.target.files[0]);
    }
  };

  return (
    <div>
      <Modal show={show} onHide={closeModel} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Room Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError !== "" && (
            <div>
              <CAlert color="danger">{formError}</CAlert>
            </div>
          )}
          {formSuccess !== "" && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Category Name</Form.Label>
                  <Form.Control
                    name="category"
                    type="text"
                    placeholder="Enter category name"
                    value={category}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Select Image</Form.Label>
                  <Form.File onChange={onChange} name="cateimage" />
                </Form.Group>
              </Col>
              <Col lg="6">
                <div>
                  <Image
                    style={{ width: 150, height: 100 }}
                    src={`${msHelper.ImageUrl}room_category/${cateimage}`}
                  />
                </div>
              </Col>
            </Form.Row>

            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditRoomCateModal;
