import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Col, Image } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";
import { msHelper } from "../../../services/ms-helper.service";

interface EditHomeCitiesModalProps {
  show: boolean;
  closeModel: () => void;
  refreshList: () => void;
  data: {
    id: number;
    city_img: string;
    state_id: number;
    city_id: number;
    // Add other properties as needed
  };
}

const EditHomeCitiesModal: React.FC<EditHomeCitiesModalProps> = ({
  show,
  closeModel,
  refreshList,
  data,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [city_img, setCityImg] = useState<any>(data.city_img);
  const [state_list, setStateList] = useState<any[]>([]);
  const [city_list, setCityList] = useState<any[]>([]);
  const [select_state_loading, setSelectStateLoading] = useState<boolean>(true);
  const [select_city_loading, setSelectCityLoading] = useState<boolean>(true);
  const [selectedcity, setSelectedCity] = useState<number>(0);
  const [selectedstate, setSelectedState] = useState<number>(0);

  useEffect(() => {
    loadStates();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setCityImg(value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCityImg(e.target.files?.[0]);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.target as any);
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", data.id.toString());
    formData.append("city_id", formData.get("city") as string);
    formData.append("state_id", formData.get("state_id") as string);
    formData.append("city_img", city_img);
    var config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/app_home/update_city", formData, config)
      .then((res) => {
        if (res.data["status"] === "success") {
          setFormSuccess(res.data["msg"]);
          refreshList();
          setTimeout(() => {
            closeModel();
            setFormError("");
            setFormSuccess("");
            setCityImg(data.city_img);
          }, 1000);
        } else {
          setFormError(res.data["msg"]);
        }
      })
      .catch((error) => {
        setFormError("Error updating city: " + error.message);
      });
  };

  const loadStateCity = (state: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("state_id", state.toString());

    Api.post("Admin/region/get_cities_by_state_id", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setCityList(res.data["data"]);
          setSelectCityLoading(false);
        } else {
          setCityList([]);
        }
      })
      .catch((error) => {
        console.error("Error loading cities:", error);
      });
  };

  const loadStates = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    Api.post("Admin/region/get_states", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setStateList(res.data["data"]);
          setSelectStateLoading(false);
          loadStateCity(data.state_id);
        } else {
          setStateList([]);
        }
      })
      .catch((error) => {
        console.error("Error loading states:", error);
      });
  };

  return (
    <div>
      <Modal
        size="lg"
        show={show}
        onHide={closeModel}
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && <CAlert color="danger">{formError}</CAlert>}
          {formSuccess && <CAlert color="success">{formSuccess}</CAlert>}
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Row>
              <Col lg="6">
                <Form.Group controlId="stateSelect">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    name="state_id"
                    onChange={(event) => {
                      let state = parseInt(event.target.value);
                      if (state === 0) {
                        setCityList([]);
                      } else {
                        loadStateCity(state);
                      }
                    }}
                    value={data.state_id}
                    custom
                  >
                    <option value={0}>--- Select State ---</option>
                    {state_list.map((state) => (
                      <option key={state.id} value={state.id}>
                        {state.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group controlId="citySelect">
                  <Form.Label>City</Form.Label>
                  <Form.Control
                    as="select"
                    name="city"
                    onChange={(event) => {
                      setSelectedCity(parseInt(event.target.value));
                    }}
                    value={selectedcity}
                    custom
                  >
                    <option value={0}>--- Select City ---</option>
                    {city_list.map((city) => (
                      <option key={city.id} value={city.id}>
                        {city.city_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col lg="6">
                <Form.Group controlId="cityImg">
                  <Form.Label>City Image</Form.Label>
                  <Form.File name="city_img" onChange={onChange} />
                </Form.Group>
              </Col>
              <Col lg="6">
                <div>
                  <Image
                    style={{ width: 50 }}
                    src={`${msHelper.ImageUrl}/cities/${city_img}`}
                  />
                </div>
              </Col>
            </Form.Row>
            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditHomeCitiesModal;
