import React, { useState } from "react";
import { Modal, Form, Button, Col } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

interface EditStatusModalProps {
  show: boolean;
  closeModel: () => void;
  refreshList: () => void;
  data: {
    id: number;
    status: string;
  };
}

const EditStatusModal: React.FC<EditStatusModalProps> = (props) => {
  const { show, closeModel, refreshList, data } = props;
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormError("");
    setFormSuccess("");
    setLoading(true);

    const formData = new FormData(e.currentTarget);

    const token = localStorage.getItem("token");
    const bookingId = data.id;
    formData.append("token", token || "");
    formData.append("booking_id", bookingId.toString());
    formData.append("status", e.currentTarget.status.value);

    Api.post("Admin/booked_room/update_booking_status", formData).then(
      (res) => {
        if (res.data.status === "success") {
          setFormSuccess(res.data.msg);
          refreshList();
          setTimeout(() => {
            closeModel();
            setFormError("");
            setFormSuccess("");
            setLoading(false);
          }, 1000);
        } else {
          setFormError(res.data.msg);
          setLoading(false);
        }
      }
    );
  };

  return (
    <div>
      <Modal show={show} onHide={closeModel} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Booking Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && (
            <div>
              <CAlert color="danger">{formError}</CAlert>
            </div>
          )}
          {formSuccess && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form encType={"multipart/form-data"} onSubmit={handleSubmit}>
            <Form.Row>
              <Col lg="12">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Status</Form.Label>
                  <Form.Control name="status" as="select" custom>
                    <option value="">Select Status</option>
                    <option
                      value="no show"
                      selected={data.status === "no show"}
                    >
                      No Show
                    </option>
                    <option
                      value="cancelled"
                      selected={data.status === "cancelled"}
                    >
                      Cancelled
                    </option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>

            {loading ? (
              <Button variant="primary" type="button">
                Please Wait....
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Update
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditStatusModal;
