import React from "react";
import SiderDrawerProvider from "../../context/side-drawer-context/SideDrawer.context";
import Sidebar from "./sidebar/Sidebar";
import Header from "./header/Header";
import Content from "./content/Content";
import Footer from "./footer/Footer";

const TheLayout = () => {
  return (
    <SiderDrawerProvider>
      <div className="c-app c-default-layout">
        <Sidebar />
        <div className="c-wrapper">
          <Header />
          <div className="c-body">
            <Content />
          </div>
          <Footer />
        </div>
      </div>
    </SiderDrawerProvider>
  );
};

export default TheLayout;
