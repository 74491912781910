import React, { useCallback, useEffect, useRef, useState } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ApiRequest from "../../../services/ApiRequest.service";
import { API_URLS } from "../../../services/api-urls.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";
import PriceRequestUpdateModal from "../../../container/modal/price-request-update-modal/PriceRequestUpdateModal";

const fields = [
  "id",
  "vendor_name",
  "hotel_name",
  "room_category",
  "hotel_new_price",
  "hotel_old_price",
  "status",
  "action",
];

const HotelRoomPriceRequestsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [status, setStatus] = useState<string>("");
  const priceModalRef = useRef<any>(null);

  const getData = useCallback(() => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("status", status);

    ApiRequest({
      url: API_URLS.GET_PRICE_UPDATE_REQUESTS,
      values: formData,
      loading: setLoading,
      onSuccessCallback: (data) => {
        setTableData(data.data);
      },
      onError: () => {
        setTableData([]);
      },
    });
  }, [status]);

  useEffect(() => {
    getData();
  }, [getData]);

  const getStatusClass = (requestStatus: string) => {
    if (requestStatus === "pending") {
      return "badge badge-warning";
    } else if (requestStatus === "approved") {
      return "badge badge-success";
    } else {
      return "badge badge-danger";
    }
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader
            className={"d-flex justify-content-between align-items-center"}
          >
            Hotel Room Price Request
            <div className="filter_Form">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  onChange={(e) => setStatus(e.target.value)}
                  name="property_filter"
                  as="select"
                >
                  <option value="">--- Select Status ---</option>
                  <option value="pending">Pending</option>
                  <option value="rejected">Rejected</option>
                  <option value="approved">Approved</option>
                </Form.Control>
              </Form.Group>
            </div>
          </CCardHeader>
          <CCardBody>
            {loading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  id: (item: any) => <td>{"#" + item.id}</td>,
                  status: (item: any) => (
                    <td>
                      <span className={`${getStatusClass(item.status)}`}>
                        {item.status}
                      </span>
                    </td>
                  ),
                  hotel_new_price: (item: any) => <td>₹{item.new_price}/-</td>,
                  hotel_old_price: (item: any) => <td>₹{item.old_price}/-</td>,
                  action: (item: any) => (
                    <td>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            if (priceModalRef.current) {
                              priceModalRef.current.openModal();
                              priceModalRef.current.updateData(item);
                            }
                          }}
                          variant="success"
                        >
                          <FontAwesomeIcon icon={faEdit as any} />
                        </Button>
                      </ButtonGroup>
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
      <PriceRequestUpdateModal loadData={getData} ref={priceModalRef} />
    </div>
  );
};

export default HotelRoomPriceRequestsPage;
