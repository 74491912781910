import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../services/axios.service";
import EditHomeCitiesModal from "./EditHomeCitiesModal";

interface HomeCitiesEditBlockProps {
  item: {
    id: number;
    // Define other properties of `item` as needed
  };
  refreshList: () => void;
}

const HomeCitiesEditBlock: React.FC<HomeCitiesEditBlockProps> = ({
  item,
  refreshList,
}) => {
  const [editData, setEditData] = useState<any>(null);
  const [showModel, setShowModel] = useState(false);

  const closeModel = () => {
    setShowModel(false);
  };

  const deleteCity = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", id.toString());

    Api.post("Admin/app_home/delete_city", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          refreshList();
          alert(res.data["msg"]);
        } else {
          // Handle error
          console.error("Delete city error:", res.data["msg"]);
        }
      })
      .catch((error) => {
        console.error("Delete city error:", error);
      });
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Button
          variant="primary"
          onClick={() => {
            setEditData(item);
            setShowModel(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit as any} />
        </Button>
        <Button
          onClick={() => deleteCity(item.id)}
          className="delete-btn"
          variant="danger"
        >
          <FontAwesomeIcon icon={faTrash as any} />
        </Button>
      </ButtonGroup>
      {editData && (
        <EditHomeCitiesModal
          data={editData}
          show={showModel}
          closeModel={closeModel}
          refreshList={refreshList}
        />
      )}
    </>
  );
};

export default HomeCitiesEditBlock;
