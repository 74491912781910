import React, { useState, useEffect, FormEvent } from "react";
import { Button, Form, Col } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import ReactQuill from "react-quill";
import { Api } from "../../../services/axios.service";

const GuestPolicyPage = () => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [guestPolicy, setGuestPolicy] = useState<string>("");

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = () => {
    const formdata = new FormData();
    const token = localStorage.getItem("token");
    if (token) {
      formdata.append("token", token);
    }

    Api.post("Admin/Settings", formdata).then((res) => {
      const data = res.data["data"];
      if (res.data["status"] === "success") {
        setGuestPolicy(data.guest_policy);
      } else {
        setGuestPolicy("");
      }
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formdata = new FormData(e.currentTarget);
    const token = localStorage.getItem("token");
    if (token) {
      formdata.append("token", token);
    }
    formdata.append("guest_policy", guestPolicy);

    Api.post("Admin/settings/update_guest_policy", formdata).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
      } else {
        setFormError(res.data["formErrors"]);
      }
    });
  };

  return (
    <>
      <div>
        <CCard>
          <CCardHeader>
            <h4>Guest Policy</h4>
          </CCardHeader>
          <CCardBody>
            {formError && <CAlert color="danger">{formError}</CAlert>}
            {formSuccess && <CAlert color="success">{formSuccess}</CAlert>}
            <Form onSubmit={handleSubmit}>
              <Col lg="12">
                <ReactQuill
                  style={{ height: 300, marginTop: 15, paddingBottom: 70 }}
                  value={guestPolicy}
                  onChange={(text) => setGuestPolicy(text)}
                />
              </Col>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Form>
          </CCardBody>
        </CCard>
      </div>
    </>
  );
};

export default GuestPolicyPage;
