import React from "react";
import { Button, Spinner } from "react-bootstrap";

interface ButtonLoadingProps {
  type: "button" | "submit" | "reset" | undefined;
  className?: string;
  loading: boolean;
  title: string;
}

const ButtonLoading: React.FC<ButtonLoadingProps> = ({
  type,
  className,
  loading,
  title,
}) => {
  return (
    <Button type={type} className={className} disabled={loading}>
      {loading && (
        <Spinner
          className="mr-2"
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      {loading ? "Loading...." : title}
    </Button>
  );
};

export default ButtonLoading;
