import axios from "axios";
console.log("API_URLS", process.env.REACT_APP_BASE_URL);

export const Api = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
});

export const formDataHelper = () => {
  const formData = new FormData();
  let token = localStorage.getItem("token");
  formData.append("token", token ?? "");
  return formData;
};
