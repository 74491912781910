import React, { memo, useMemo } from "react";
import classes from "./index.module.css";
import { Link, useLocation } from "react-router-dom";
import { getBreadCrumbs } from "../../../../../helpers/getBreadcrumb.helper";

const Breadcrumbs = () => {
  const location = useLocation();
  console.log("navigation", location.pathname);

  const data = useMemo(() => {
    return getBreadCrumbs(location.pathname);
  }, [location.pathname]);

  return (
    <div className={classes.breadcrumb}>
      <Link to={"/"} className={classes.link}>
        Home
      </Link>
      {data.map((e, i) => (
        <>
          <span className={classes.splash}>/</span>
          {i === data.length - 1 ? (
            <span className={classes.splashTxt}>{e.name}</span>
          ) : (
            <Link to={e.url ?? ""} className={classes.link}>
              {e.name}
            </Link>
          )}
        </>
      ))}
    </div>
  );
};

export default memo(Breadcrumbs);
