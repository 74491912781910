import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEye,
  faEyeSlash,
  faUserAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Api } from "../../../services/axios.service";
import { User } from "../UsersPage";

interface UserEditBlockProps {
  item: User;
  refreshList: () => void;
}

const UserEditBlock: React.FC<UserEditBlockProps> = ({ item, refreshList }) => {
  const [formError, setFormError] = useState<string | null>(null);

  const activeInactive = async (id: number) => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", id.toString());

    try {
      const response = await Api.post(
        "Admin/users/active_inactive_user",
        formData
      );
      if (response.data.status === "success") {
        refreshList();
      } else {
        setFormError(response.data.msg);
        console.log(response.data);
      }
    } catch (error) {
      console.error("Error activating/inactivating user", error);
    }
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Link to={`/view-user/${item.id}`} className="btn btn-success">
          <FontAwesomeIcon icon={faUserAlt as any} />
        </Link>

        <Button variant="primary" onClick={() => activeInactive(item.id)}>
          <FontAwesomeIcon
            icon={(item.active === "0" ? faEyeSlash : faEye) as any}
          />
        </Button>
      </ButtonGroup>
      {formError && <div className="error">{formError}</div>}
    </>
  );
};

export default UserEditBlock;
