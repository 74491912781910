import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Image } from "react-bootstrap";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";
import { msHelper } from "../../../services/ms-helper.service";
import AminitiesEditBlock from "../components/AminitiesEditBlock";

const fields = ["id", "icon", "amenity", "action"];

const AllAminitiesPage = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    Api.post("Admin/amenities", formData).then((res) => {
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
      }
    });
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>All Categories</CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  icon: (item: any) => (
                    <td>
                      <div>
                        <Image
                          style={{ width: 25 }}
                          src={`${msHelper.ImageUrl}/amenity/${item.icon}`}
                        />
                      </div>
                    </td>
                  ),
                  action: (item: any) => (
                    <td>
                      <AminitiesEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default AllAminitiesPage;
