import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../services/axios.service";
import EditAminitiesModal from "./EditAminitiesModal";

interface AminitiesEditBlockProps {
  item: any; // Adjust type according to your item structure
  refreshList: () => void;
}

const AminitiesEditBlock: React.FC<AminitiesEditBlockProps> = ({
  item,
  refreshList,
}) => {
  const [editData, setEditData] = useState<any>(null);
  const [showModel, setShowModel] = useState<boolean>(false);

  const closeModel = () => {
    setShowModel(false);
  };

  const deleteAmenity = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", id.toString());

    Api.post("Admin/amenities/delete", formData).then((res) => {
      if (res.data["status"] === "success") {
        refreshList();
        alert(res.data["msg"]);
      } else {
        console.error(res.data);
        // Handle error state if needed
      }
    });
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Button
          variant="primary"
          onClick={() => {
            setEditData(item);
            setShowModel(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit as any} />
        </Button>
        <Button
          onClick={() => deleteAmenity(item.id)}
          className="delete-btn"
          variant="danger"
        >
          <FontAwesomeIcon icon={faTrash as any} />
        </Button>
      </ButtonGroup>
      {editData && (
        <EditAminitiesModal
          data={editData}
          show={showModel}
          closeModel={closeModel}
          refreshList={refreshList}
        />
      )}
    </>
  );
};

export default AminitiesEditBlock;
