import React, { Component, ChangeEvent, FormEvent } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

interface AddCouponState {
  loading: boolean;
  formError: any;
  formSuccess: string;
  discount_type: string;
  image: any;
  value: any;
}

class AddCoupon extends Component<{}, AddCouponState> {
  constructor(props: {}) {
    super(props);
    this.state = {
      loading: true,
      formError: "",
      formSuccess: "",
      discount_type: "",
      image: "",
      value: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnSelect = this.handleOnSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.onChange = this.onChange.bind(this);
  }

  handleOnSelect(e: ChangeEvent<HTMLSelectElement>) {
    this.setState({ discount_type: e.target.value });
  }

  handleChange(e: ChangeEvent<HTMLInputElement>) {
    this.setState({ [e.target.name]: e.target.value } as any);
  }

  onChange(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files?.[0]) this.setState({ image: e.target.files?.[0] });
  }

  handleSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    this.setState({
      formError: "",
      formSuccess: "",
    });

    const formData = new FormData(e.currentTarget);

    let token = localStorage.getItem("token");

    formData.append("token", token || "");
    formData.append("title", e.currentTarget.coupon_title.value);
    formData.append("sub_title", e.currentTarget.coupon_sub_title.value);
    formData.append("coupon_code", e.currentTarget.coupon_code.value);
    formData.append("discount_type", e.currentTarget.discount_type.value);
    formData.append(
      "coupon_appied_for",
      e.currentTarget.coupon_appied_for.value
    );
    formData.append("coupon_amount", e.currentTarget.coupon_amount.value);
    formData.append("expiry_date", e.currentTarget.expiry_date.value);
    formData.append("minimum_spend", e.currentTarget.minimum_spend.value);
    formData.append("maximum_spend", e.currentTarget.maximum_spend.value);
    formData.append(
      "usage_limit_coupon",
      e.currentTarget.usage_limit_coupon.value
    );
    formData.append("usage_limit_user", e.currentTarget.usage_limit_user.value);
    if (this.state.discount_type === "percentage") {
      formData.append("up_to_limit", e.currentTarget.up_to_limit.value);
    }

    Api.post("Admin/coupon/add", formData)
      .then((res) => {
        console.log(res.data);
        if (res.data["status"] === "success") {
          window.scrollTo(0, 0);
          this.setState({
            formSuccess: res.data["msg"],
            value: "",
          });
          (this.refs.form as any).reset();
        } else {
          this.setState({
            formError: res.data["formErrors"],
          });
        }
      })
      .catch((error) => {
        console.error("Error adding coupon:", error);
        this.setState({
          formError: "An error occurred while adding the coupon.",
        });
      });
  }

  render() {
    const { formError, formSuccess } = this.state;

    return (
      <div>
        <CCard>
          <CCardHeader>
            <h4>Add Coupon</h4>
          </CCardHeader>
          <CCardBody>
            {formSuccess !== "" && (
              <div>
                <CAlert color="success">{formSuccess}</CAlert>
              </div>
            )}
            <Form encType="multipart/form-data" onSubmit={this.handleSubmit}>
              <Row className="form-sections">
                <Col lg="12">
                  <h4>Coupon Information </h4>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="coupon_title"
                      type="text"
                      placeholder="Enter coupon title"
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.title}
                    </div>
                  </Form.Group>
                </Col>

                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Sub title</Form.Label>
                    <Form.Control
                      name="coupon_sub_title"
                      type="text"
                      placeholder="Enter coupon subtitle"
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.sub_title}
                    </div>
                  </Form.Group>
                </Col>

                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Coupon Code</Form.Label>
                    <Form.Control
                      name="coupon_code"
                      type="text"
                      placeholder="Enter coupon code"
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.coupon_code}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Discount Type</Form.Label>
                    <Form.Control
                      onChange={this.handleOnSelect}
                      name="discount_type"
                      as="select"
                      custom
                    >
                      <option value="">--- select Type ---</option>
                      <option value="percentage">Percentage </option>
                      <option value="fixed">Fixed</option>
                    </Form.Control>
                    <div className="invalid-feedback d-block">
                      {formError?.discount_type}
                    </div>
                  </Form.Group>
                  {this.state.discount_type === "percentage" && (
                    <Form.Group>
                      <small>up to limit</small>
                      <Form.Control
                        name="up_to_limit"
                        type="text"
                        placeholder="Enter Limit"
                      />
                      <div className="invalid-feedback d-block">
                        {formError?.up_to_limit}
                      </div>
                    </Form.Group>
                  )}
                </Col>
                <Col lg="4">
                  <Form.Group>
                    <Form.Label>Coupon Applied For</Form.Label>
                    <Form.Control name="coupon_appied_for" as="select" custom>
                      <option value="">--- select ---</option>
                      <option value="pay_at_hotel">Pay At Hotel </option>
                      <option value="online">Online</option>
                      <option value="both">Both</option>
                    </Form.Control>
                    <div className="invalid-feedback d-block">
                      {formError?.coupon_appied_for}
                    </div>
                  </Form.Group>
                </Col>

                <Col lg="4">
                  <Form.Group>
                    <Form.Label>Coupon Amount</Form.Label>
                    <Form.Control
                      name="coupon_amount"
                      type="text"
                      placeholder="Enter Amount"
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.coupon_amount}
                    </div>
                  </Form.Group>
                </Col>

                <Col lg="4">
                  <Form.Group>
                    <Form.Label>Coupon Expiry Date</Form.Label>
                    <Form.Control
                      name="expiry_date"
                      type="date"
                      placeholder="Enter coupon code"
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.expiry_date}
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="form-sections">
                <Col lg="12">
                  <h4>Usage Restriction </h4>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Minimum Spend</Form.Label>
                    <Form.Control
                      name="minimum_spend"
                      type="text"
                      placeholder="Enter Minimum Spend"
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.minimum_spend}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Maximum Spend </Form.Label>
                    <Form.Control
                      name="maximum_spend"
                      type="text"
                      placeholder="Enter Maximum Spend "
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.maximum_spend}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Row className="form-sections">
                <Col lg="12">
                  <h4>Usage Limits</h4>
                </Col>

                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Usage limit per coupon</Form.Label>
                    <Form.Control
                      name="usage_limit_coupon"
                      type="text"
                      placeholder="Usage limit per coupon"
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.usage_limit_coupon}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Usage limit per user</Form.Label>
                    <Form.Control
                      name="usage_limit_user"
                      type="text"
                      placeholder="Enter Usage limit per user "
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.usage_limit_user}
                    </div>
                  </Form.Group>
                </Col>
              </Row>

              <Button variant="primary" type="submit">
                Submit
              </Button>
            </Form>
          </CCardBody>
        </CCard>
      </div>
    );
  }
}

export default AddCoupon;
