import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";
import CouponsEditBlock from "../components/CouponsEditBlock";

const fields = [
  "id",
  "coupon_code",
  "coupon_amount",
  "discount_type",
  "expiry_date",
  "actions",
];

const AllCouponsPage = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [editData, setEditData] = useState<any | null>(null);
  const [showModel, setShowModel] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token || "");

    Api.post("Admin/coupon", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setTableData(res.data["data"]);
        } else {
          setTableData([]);
        }
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        setTableData([]);
      })
      .finally(() => {
        setTableLoading(false);
      });
  };

  const closeModel = () => {
    setShowModel(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>All Coupons</CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  actions: (item: any) => (
                    <td>
                      <CouponsEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default AllCouponsPage;
