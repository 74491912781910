import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { msHelper } from "../../../../services/ms-helper.service";

interface PoliciesCheckBoxProps {
  policy: {
    id: number; // Ensure `id` is correctly typed as a number
    policy: string;
  };
  policies: any;
}

const PoliciesCheckBox: React.FC<PoliciesCheckBoxProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(false); // Explicitly set the type of checked

  useEffect(() => {
    if (props.policies.length > 0) {
      const parsedPolicies = JSON.parse(props.policies);
      setChecked(msHelper.policiesFind(props.policy.id, parsedPolicies) === 1); // Ensure to check against 1 for boolean state
    }
  }, [props]);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <Form.Check
        name="policies"
        className="mycheckbox"
        value={props.policy.id.toString()} // Ensure value is a string
        label={props.policy.policy}
        type={"checkbox"}
        checked={checked}
        onChange={handleChange}
        id={`inline-checkbox-${props.policy.policy}`}
      />
    </>
  );
};

export default PoliciesCheckBox;
