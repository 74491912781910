import React, { useEffect, useRef, useState, ChangeEvent } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Api } from "../../../services/axios.service";
import RoomImageBlock from "./RoomImageBlock";
import ButtonLoading from "../../../container/button-loading/ButtonLoading";

interface AddHotelImagesProps {
  roomId: string;
  hotelId: string;
}

const AddHotelImages: React.FC<AddHotelImagesProps> = ({ roomId, hotelId }) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>([]);
  const [tag, setTag] = useState<string>("");
  const [formError, setFormError] = useState<
    string | { [key: string]: string }
  >("");
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [selectFile, setSelectFile] = useState<File | null>(null);
  const [imageModel, setImageModel] = useState<boolean>(false);
  const fileRef = useRef<HTMLInputElement>(null);

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    getRoomImages();
  }, [roomId, hotelId]);

  const uploadFileToServer = () => {
    setFormLoading(true);
    if (!selectFile) {
      alert("Please Select Room Image");
      setFormLoading(false);
      return;
    }

    setFormError("");
    setSuccess("");
    setError("");

    const formData = new FormData();
    const token = localStorage.getItem("token") || "";

    formData.append("token", token);
    formData.append("room_id", roomId);
    formData.append("hotel_id", hotelId);
    formData.append("tag", tag);
    formData.append("image", selectFile);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/rooms/add_room_image", formData, config)
      .then((res) => {
        setFormLoading(false);
        if (res.data["status"] === "success") {
          setSelectFile(null);
          if (fileRef.current) fileRef.current.value = "";
          setSuccess(res.data["msg"]);
          setTag("");
          getRoomImages();
          setFormError("");
        } else {
          if (res.data["form_error"] === 0) {
            setError(res.data["msg"]);
            setFormError("");
          } else {
            setFormError(res.data["formErrors"]);
          }
        }
      })
      .catch((error) => {
        setFormLoading(false);
        if (fileRef.current) fileRef.current.value = "";
        console.log(error);
      });
  };

  const getRoomImages = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);
    formData.append("id", roomId);

    Api.post("Admin/rooms/get_room_images", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setSelectedFiles(res.data["data"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className="pb-5 pt-5">
        <Button onClick={() => setImageModel(true)} className="btn btn-primary">
          ADD ROOM IMAGE
        </Button>
        <div className="mt-3">
          <Row>
            {selectedFiles.map((image, index) => (
              <Col lg="3" key={index.toString()}>
                <RoomImageBlock image={image} getRoomImages={getRoomImages} />
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Modal
        show={imageModel}
        onHide={() => setImageModel(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Image To Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success && (
            <div className="alert alert-success" role="alert">
              {success}
            </div>
          )}
          {error && (
            <div className="alert alert-error" role="alert">
              {error}
            </div>
          )}

          <div className="form-group">
            <label>Select Room Image</label>
            <input
              onChange={onFileChange}
              ref={fileRef}
              type="file"
              className="form-control-file"
            />
          </div>
          {selectFile && (
            <div className="mb-3">
              <img
                alt="Selected"
                style={{ width: "100%" }}
                className="img-thumbnail"
                src={URL.createObjectURL(selectFile)}
              />
            </div>
          )}
          <div className="form-group">
            <label>Add Tag</label>
            <input
              className="form-control"
              onChange={(e) => setTag(e.target.value)}
              type="text"
              placeholder="Add Tag Name"
            />
            {typeof formError === "object" && formError.tag && (
              <div className="invalid-feedback d-block">{formError.tag}</div>
            )}
          </div>

          <div onClick={uploadFileToServer}>
            <ButtonLoading
              type="button"
              title="ADD ROOM IMAGE"
              loading={formLoading}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddHotelImages;
