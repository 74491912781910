import React from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Api } from "../../../services/axios.service";

interface Props {
  item: {
    id: number;
  };
  refreshList: () => void;
}

const CouponsEditBlock: React.FC<Props> = ({ item, refreshList }) => {
  const deleteCoupon = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("id", id.toString());

    Api.post("Admin/coupon/delete", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          refreshList();
          alert(res.data["msg"]);
        } else {
          console.error("Delete request failed:", res.data["msg"]);
        }
      })
      .catch((error) => {
        console.error("Error deleting coupon:", error);
      });
  };

  return (
    <ButtonGroup className="mr-2" aria-label="First group">
      <Link to={`/coupons/edit-coupon/${item.id}`} className="btn btn-primary">
        <FontAwesomeIcon icon={faEdit as any} />
      </Link>
      <Button
        onClick={() => deleteCoupon(item.id)}
        className="delete-btn"
        variant="danger"
      >
        <FontAwesomeIcon icon={faTrash as any} />
      </Button>
    </ButtonGroup>
  );
};

export default CouponsEditBlock;
