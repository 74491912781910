import { CCol } from "@coreui/react";
import React from "react";
import TopWidgets from "./components/top-widgets/TopWidgets";
import SmallWidgets from "./components/small-widgets/SmallWidgets";
import BookingsGraph from "./components/booking-graph/BookingGraph";

const Dashboard = () => {
  return (
    <div>
      <CCol xs="12" lg="12">
        <TopWidgets />
        <SmallWidgets />
        <BookingsGraph />
      </CCol>
    </div>
  );
};

export default Dashboard;
