import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../services/axios.service";
import EditPolicyModal from "./EditPolicyModal";

interface PolicyEditBlockProps {
  item: { id: string; active: string };
  refreshList: () => void;
}

const PolicyEditBlock: React.FC<PolicyEditBlockProps> = ({
  item,
  refreshList,
}) => {
  const [editData, setEditData] = useState<any | null>(null);
  const [showModel, setShowModel] = useState<boolean>(false);

  const closeModel = () => {
    setShowModel(false);
  };

  const activeInactive = (id: string) => {
    const formData = new FormData();
    const token = localStorage.getItem("token");

    if (token) {
      formData.append("token", token);
    }
    formData.append("id", id);

    Api.post("Admin/policy/active_inactive", formData).then((res) => {
      if (res.data["status"] === "success") {
        refreshList();
      } else {
        console.log(res.data);
      }
    });
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Button
          variant="primary"
          onClick={() => {
            setEditData(item);
            setShowModel(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit as any} />
        </Button>
        <Button
          variant="success"
          onClick={() => {
            activeInactive(item.id);
          }}
        >
          <FontAwesomeIcon
            icon={(item.active === "0" ? faEyeSlash : faEye) as any}
          />
        </Button>
      </ButtonGroup>

      {editData && (
        <EditPolicyModal
          data={editData}
          show={showModel}
          closeModel={closeModel}
          refreshList={refreshList}
        />
      )}
    </>
  );
};

export default PolicyEditBlock;
