import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Api } from "../../../services/axios.service";

interface Room {
  id: string;
  active: string;
}

interface RoomsEditBlockProps {
  item: Room;
  refreshList: () => void;
}

const RoomsEditBlock: React.FC<RoomsEditBlockProps> = ({
  item,
  refreshList,
}) => {
  const [formError, setFormError] = useState<string | null>(null);

  const activeInactive = (id: string) => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", id);

    Api.post("Admin/rooms/active_inactive_room", formData).then((res) => {
      if (res.data["status"] === "success") {
        refreshList();
      } else {
        setFormError(res.data["msg"]);
        console.log(res.data);
      }
    });
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Link className="btn btn-primary" to={`/room/edit/${item.id}`}>
          <FontAwesomeIcon icon={faEdit as any} />
        </Link>
        <Button variant="success" onClick={() => activeInactive(item.id)}>
          <FontAwesomeIcon
            icon={(item.active === "0" ? faEyeSlash : faEye) as any}
          />
        </Button>
      </ButtonGroup>
      {formError && <p className="text-danger">{formError}</p>}
    </>
  );
};

export default RoomsEditBlock;
