import React, { useState } from "react";
import {
  CCard,
  CCardBody,
  CCardFooter,
  CCol,
  CProgress,
  CRow,
} from "@coreui/react";
import moment from "moment";
import MainChart from "./components/MainChart";

interface IBottomData {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  data: number[];
  label: string;
  pointHoverBackgroundColor: string;
  color: string;
}

function BookingsGraph() {
  const [bottomData, setBottomData] = useState<IBottomData[]>([]);
  const [totalMonthBooking, setTotalMonthBooking] = useState(0);

  const thisMonth = moment(new Date()).format("MMMM YYYY");

  const updateDataSet = (dataset: IBottomData[], totalMonthBooking: number) => {
    setTotalMonthBooking(totalMonthBooking);
    setBottomData(dataset);
  };

  return (
    <CCard>
      <CCardBody>
        <CRow>
          <CCol sm="5">
            <h4 id="traffic" className="card-title mb-0">
              {"This Month Bookings"}
            </h4>
            <div className="small text-muted">{thisMonth}</div>
          </CCol>
          {/* <CCol sm="7" className="d-none d-md-block">
                  <CButton color="primary" className="float-right">
                    <CIcon name="cil-cloud-download" />
                  </CButton>
                  <CButtonGroup className="float-right mr-3">
                    {["Day", "Month", "Year"].map((value) => (
                      <CButton
                        color="outline-secondary"
                        key={value}
                        className="mx-0"
                        active={value === "Month"}
                      >
                        {value}
                      </CButton>
                    ))}
                  </CButtonGroup>
                </CCol> */}
        </CRow>
        <MainChart
          style={{ height: "300px", marginTop: "40px" }}
          updateDataSet={updateDataSet}
        />
      </CCardBody>
      <CCardFooter>
        <CRow className="text-center">
          {bottomData.map((data) => {
            const count = data.data.reduce((a, b) => Number(a) + Number(b));
            let percentage = 0;
            if (totalMonthBooking > 0) {
              percentage = (count / totalMonthBooking) * 100;
            }

            return (
              <CCol md sm="12" className="mb-sm-2 mb-0">
                <div className="text-muted">{`${data.label}`}</div>
                <strong>{`${count} (${percentage}%)`}</strong>
                <CProgress
                  className="progress-xs mt-2"
                  precision={1}
                  color={`${data.color}`}
                  value={percentage}
                />
              </CCol>
            );
          })}
        </CRow>
      </CCardFooter>
    </CCard>
  );
}

export default BookingsGraph;
