import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import EditStatusModal from "./EditStatusModal";

interface BookedRoomsEditBlockProps {
  item: any;
  refreshList: () => void;
  // Add other props if necessary
}

const BookedRoomsEditBlock: React.FC<BookedRoomsEditBlockProps> = (props) => {
  const { item } = props;
  const [editData, setEditData] = useState<any | null>(null);
  const [showModel, setShowModel] = useState<boolean>(false);

  const closeModel = () => {
    setShowModel(false);
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Link
          to={`/view-booking-detail/${item.id}`}
          className="btn btn-primary"
        >
          <FontAwesomeIcon icon={faEye as any} />
        </Link>
        {!(item.status === "checkedin" || item.status === "checkedout") && (
          <Button
            variant="primary"
            onClick={() => {
              setEditData(item);
              setShowModel(true);
            }}
          >
            <FontAwesomeIcon icon={faEdit as any} />
          </Button>
        )}
      </ButtonGroup>
      {editData != null && (
        <EditStatusModal
          data={editData}
          show={showModel}
          closeModel={closeModel}
          {...props}
        />
      )}
    </>
  );
};

export default BookedRoomsEditBlock;
