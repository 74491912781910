import React from "react";
import { Col, Row } from "react-bootstrap";
import AppExit from "./components/AppExit";
import CityExit from "./components/CityExit";
import HotelExit from "./components/HotelExit";

const DefaultNotifications = () => {
  return (
    <div>
      <Row>
        <Col lg={"6"}>
          <AppExit />
        </Col>
        <Col lg={"6"}>
          <CityExit />
        </Col>
        <Col lg={"6"}>
          <HotelExit />
        </Col>
      </Row>
    </div>
  );
};

export default DefaultNotifications;
