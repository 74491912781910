import React, { useEffect, useState } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { useParams } from "react-router-dom";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";

const fields = [
  "id",
  "vendor_name",
  "hotel_name",
  "subject",
  "description",
  "bookings",
];

interface IssueData {
  id: number;
  vendor_name: string;
  hotel_name: string;
  subject: string;
  description: string;
  bookings: string;
}

const ViewIssueDataPage = () => {
  const params = useParams();
  const [tableData, setTableData] = useState<IssueData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const loadData = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");

    Api.post(
      `Admin/dashboard/top_widget_data_filter/${params.filter}`,
      formData
    ).then((res) => {
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader
            className={"d-flex justify-content-between align-items-center"}
          >
            All Raise Issues
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                tableFilter
                pagination
                itemsPerPage={10}
                scopedSlots={{
                  id: (item: IssueData) => <td>{item.id}</td>,
                  bookings: (item: IssueData) => (
                    <td>
                      {JSON.parse(item.bookings).map(
                        (booking: string, index: number) => (
                          <span key={index}>#{booking}, </span>
                        )
                      )}
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default ViewIssueDataPage;
