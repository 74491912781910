import React, { useState } from "react";
import { Modal, Form, Button } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import parse from "html-react-parser";
import { Api } from "../../../services/axios.service";

interface AddVendorModalProps {
  show: boolean;
  closeModel: () => void;
  refreshList: () => void;
}

const AddVendorModal: React.FC<AddVendorModalProps> = ({
  show,
  closeModel,
  refreshList,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormError("");
    setFormSuccess("");
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.target as HTMLFormElement);

    const token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("full_name", (e.target as any).fullname.value);
    formData.append("mobile", (e.target as any).mobile.value);
    formData.append("vendor_email", (e.target as any).vendor_email.value);
    formData.append("silver_email", (e.target as any).silver_email.value);
    formData.append("pass", (e.target as any).password.value);

    Api.post("Vendor/vendor_signup", formData).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        refreshList();
        setTimeout(() => {
          closeModel();
          setFormError("");
          setFormSuccess("");
        }, 1000);
      } else {
        setFormError(res.data["msg"]);
      }
    });
  };

  return (
    <div>
      <Modal
        show={show}
        onHide={() => {
          closeModel();
          setFormError("");
          setFormSuccess("");
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Vendor</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && <CAlert color="danger">{parse(formError)}</CAlert>}
          {formSuccess && <CAlert color="success">{parse(formSuccess)}</CAlert>}
          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="formBasicEmail">
              <Form.Label>Full Name</Form.Label>
              <Form.Control
                onChange={handleChange}
                name="fullname"
                type="text"
                placeholder="Enter Full Name"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Mobile</Form.Label>
              <Form.Control
                onChange={handleChange}
                name="mobile"
                type="number"
                placeholder="Enter Mobile Number"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Email{" "}
                <small className="text-muted font-weight-bold">
                  (provided by vendor)
                </small>
              </Form.Label>
              <Form.Control
                onChange={handleChange}
                name="vendor_email"
                type="email"
                placeholder="Enter Email"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>
                Email{" "}
                <small className="text-muted font-weight-bold">
                  (generated by silver stays)
                </small>
              </Form.Label>
              <Form.Control
                onChange={handleChange}
                name="silver_email"
                type="email"
                placeholder="Enter Silver's Email"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                onChange={handleChange}
                name="password"
                type="password"
                placeholder="Enter Password"
              />
            </Form.Group>
            <Button variant="primary" type="submit">
              Add Vendor
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddVendorModal;
