import React, { memo } from "react";
import {
  CHeader,
  CToggler,
  CHeaderBrand,
  CHeaderNav,
  CHeaderNavItem,
  CHeaderNavLink,
  CSubheader,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

// routes config
import useAuth from "../../../hooks/auth/useAuth";
import { useSideDrawerStore } from "../../../context/side-drawer-context/SideDrawer.context";
import HeaderNotification from "./components/header-notification/HeaderNotification";
import HeaderDropdownMsg from "./components/header-dropdown-msg/HeaderDropdownMsg";
import { useNavigate } from "react-router-dom";
import Breadcrumbs from "./components/breadcrumbs/Breadcrumbs";

const Header = () => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { show, toggleDrawer } = useSideDrawerStore();
  const toggleSidebar = () => {
    const val = [true, "responsive"].includes(show) ? false : "responsive";
    toggleDrawer(val);
  };

  const toggleSidebarMobile = () => {
    const val = [false, "responsive"].includes(show) ? true : "responsive";
    toggleDrawer(val);
  };

  return (
    <CHeader withSubheader>
      <CToggler
        inHeader
        className="ml-md-3 d-lg-none"
        onClick={toggleSidebarMobile}
      />
      <CToggler
        inHeader
        className="ml-3 d-md-down-none"
        onClick={toggleSidebar}
      />
      <CHeaderBrand className="mx-auto d-lg-none" to="/">
        <CIcon name="logo" height="48" alt="Logo" />
      </CHeaderBrand>

      <CHeaderNav className="d-md-down-none mr-auto">
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/dashboard">Dashboard</CHeaderNavLink>
        </CHeaderNavItem>
        <CHeaderNavItem className="px-3">
          <CHeaderNavLink to="/users">Users</CHeaderNavLink>
        </CHeaderNavItem>
      </CHeaderNav>

      <CHeaderNav className="px-3">
        <HeaderNotification />
        <HeaderDropdownMsg />
        <CHeaderNavLink
          onClick={() => {
            logout(() => {
              navigate("/");
            });
          }}
        >
          Log Out
        </CHeaderNavLink>
      </CHeaderNav>

      <CSubheader className="px-3 justify-content-between">
        <Breadcrumbs />
      </CSubheader>
    </CHeader>
  );
};

export default memo(Header);
