import { createContext, useContext, useState } from "react";

interface ISideDrawer {
  show: boolean | "responsive";
  toggleDrawer: (e: ISideDrawer["show"]) => void;
}

const SideDrawerContext = createContext<ISideDrawer | null>(null);

export const useSideDrawerStore = () =>
  useContext(SideDrawerContext) as ISideDrawer;

const SiderDrawerProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [show, setShow] = useState<ISideDrawer["show"]>("responsive");

  return (
    <SideDrawerContext.Provider
      value={{
        show,
        toggleDrawer: (e: ISideDrawer["show"]) => setShow(e),
      }}
    >
      {children}
    </SideDrawerContext.Provider>
  );
};

export default SiderDrawerProvider;
