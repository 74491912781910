import React, { useState, useEffect, memo } from "react";
import { Row, Col } from "react-bootstrap";
import { CWidgetDropdown } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarAlt,
  faCalendarCheck,
  faEyeSlash,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Api } from "../../../../services/axios.service";

const TopWidgets = () => {
  const [todaysBookingCount, setTodaysBookingCount] = useState(0);
  const [todaysCheckedInCount, setTodaysCheckedInCount] = useState(0);
  const [noShowRequestCount, setNoShowRequestCount] = useState(0);
  const [otherIssuesCount, setOtherIssuesCount] = useState(0);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    Api.post("Admin/dashboard/top_widget_data", formData).then((res) => {
      var data = res.data["data"];
      if (res.data["status"] === "success") {
        setTodaysBookingCount(data.today_bookings.length);
        setTodaysCheckedInCount(data.today_checkedin.length);
        setNoShowRequestCount(data.noshowrequest.length);
        setOtherIssuesCount(data.otherIssue.length);
      } else {
        if (res.data["status_code"] == 1640) {
          localStorage.clear();
          window.location.reload();
        }

        setTodaysBookingCount(0);
        setTodaysCheckedInCount(0);
        setNoShowRequestCount(0);
        setOtherIssuesCount(0);
      }
    });
  };
  return (
    <Row>
      <Col lg="3">
        <Link to="/view-booking-data/today">
          <CWidgetDropdown
            color="gradient-info"
            header={todaysBookingCount.toString()}
            text="Todays Booking"
            className="py-3"
          >
            <FontAwesomeIcon
              icon={faCalendarAlt as any}
              size="2x"
              className={"my-2"}
            />
          </CWidgetDropdown>
        </Link>
      </Col>
      <Col lg="3">
        <Link to="/view-booking-data/today_checkedin">
          <CWidgetDropdown
            color="gradient-success"
            header={todaysCheckedInCount.toString()}
            text="Todays Checked-In"
            className="py-3"
          >
            <FontAwesomeIcon
              icon={faCalendarCheck as any}
              size="2x"
              className={"my-2"}
            />
          </CWidgetDropdown>
        </Link>
      </Col>
      <Col lg="3">
        <Link to="/view-issue-data/no_show">
          <CWidgetDropdown
            color="gradient-warning"
            header={noShowRequestCount?.toString()}
            text="No Show Request"
            className="py-3 text-large"
          >
            <FontAwesomeIcon
              icon={faEyeSlash as any}
              size="2x"
              className={"my-2"}
            />
          </CWidgetDropdown>
        </Link>
      </Col>
      <Col lg="3">
        <Link to="/view-issue-data/other_request">
          <CWidgetDropdown
            color="gradient-danger"
            header={otherIssuesCount.toString()}
            text="Issues"
            className="py-3"
          >
            <FontAwesomeIcon
              icon={faInfoCircle as any}
              size="2x"
              className={"my-2"}
            />
          </CWidgetDropdown>
        </Link>
      </Col>
    </Row>
  );
};

export default memo(TopWidgets);
