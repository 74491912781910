import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../services/axios.service";

interface UserData {
  full_name: string;
  mobile: string;
  email: string;
  dob: string;
  vendor_email: string;
  verify_number: string;
  active: string;
  last_expiry_date: string;
  profile_pic: string;
  silver_email_id: string;
}

const ViewVendorPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [userData, setUserData] = useState<UserData | null>(null);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("id", id ?? "");

    Api.post("Admin/vendors/get_vendor", formData).then((res) => {
      if (res.data["status"] === "success") {
        setUserData(res.data["data"]);
      } else {
        alert(res.data["msg"]);
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div
            onClick={() => navigate(-1)}
            className="mr-4"
            style={{ display: "inline-block", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faArrowLeft as any} />
          </div>
          <h4 style={{ fontSize: 18, display: "inline-block" }}>
            Vendor Details
          </h4>
        </div>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-lg-6">
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <th>Full Name</th>
                    <td>{userData?.full_name}</td>
                  </tr>
                  <tr>
                    <th>Mobile</th>
                    <td>{userData?.mobile}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{userData?.email}</td>
                  </tr>
                  <tr>
                    <th>DOB</th>
                    <td>{userData?.dob}</td>
                  </tr>
                  <tr>
                    <th>Vendor Email</th>
                    <td>{userData?.vendor_email}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
            <div className="col-lg-6">
              <Table striped bordered hover>
                <tbody>
                  <tr>
                    <th>Verified</th>
                    <td>{userData?.verify_number}</td>
                  </tr>
                  <tr>
                    <th>Active</th>
                    <td>{userData?.active}</td>
                  </tr>
                  <tr>
                    <th>Last Expairy Date</th>
                    <td>{userData?.last_expiry_date}</td>
                  </tr>
                  <tr>
                    <th>Profile Image</th>
                    <td>{userData?.profile_pic}</td>
                  </tr>
                  <tr>
                    <th>
                      Silver Email <small>(provided by silver stays)</small>
                    </th>
                    <td>{userData?.silver_email_id}</td>
                  </tr>
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewVendorPage;
