import React, { useState, useEffect } from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { Api } from "../../../../../services/axios.service";

const HeaderDropdownMsg = () => {
  const [AllPendingRequest, setAllPendingRequest] = useState<any[]>([]);
  const [AllPendingRequestCount, setAllPendingRequestCount] = useState(0);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const formdata = new FormData();
    let token = localStorage.getItem("token");
    formdata.append("token", token ?? "");
    Api.post("Admin/dashboard/property_request", formdata).then((res) => {
      var data = res.data["data"];
      if (res.data["status"] === "success") {
        setAllPendingRequestCount(res.data["total_property_count"].length);
        setAllPendingRequest(data);
      } else {
        setAllPendingRequestCount(0);
        setAllPendingRequest([]);
      }
    });
  };

  return (
    <CDropdown
      inNav
      className="c-header-nav-item mx-2"
      //  direction="center"
      dir="center"
    >
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <FontAwesomeIcon icon={faBuilding as any} />
        <CBadge shape="pill" color="info">
          {AllPendingRequestCount}
        </CBadge>
      </CDropdownToggle>
      <CDropdownMenu className="pt-0" placement="bottom-end">
        <CDropdownItem header tag="div" color="light">
          <strong>You have {AllPendingRequestCount} property request</strong>
        </CDropdownItem>

        {AllPendingRequest.length > 0 &&
          AllPendingRequest.map((request, index) => {
            return (
              <Link
                className={"dropdown-item"}
                key={index.toString()}
                to={`/view-property-detail/${request.id}`}
              >
                <div className="message">
                  <div>
                    <small className="text-muted">{request.full_name}</small>
                  </div>
                  <div className="text-truncate font-weight-bold">
                    <span className="fa fa-phone-alt text-success"></span>{" "}
                    {request.mobile}
                  </div>
                  <div className="small text-muted text-truncate">
                    {request.address}, {request.state}, {request.city}
                  </div>
                </div>
              </Link>
            );
          })}

        <CDropdownItem className="text-center border-top">
          <Link to="/property-list">
            <strong>View all requests</strong>
          </Link>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default HeaderDropdownMsg;
