import React, { useState } from "react";
import { ButtonGroup, Spinner, Button } from "react-bootstrap";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { togglePopularHotel } from "../popular-hotels-page/PopularHotelsPage";

interface RemoveButtonProps {
  hotelId: number;
  loadHotels: () => void;
}

const RemoveButton: React.FC<RemoveButtonProps> = ({ hotelId, loadHotels }) => {
  const [actionLoading, setActionLoading] = useState<boolean>(false);

  const performAction = async () => {
    setActionLoading(true);
    await togglePopularHotel(hotelId);
    loadHotels();
    setActionLoading(false);
  };

  return (
    <ButtonGroup className="mr-2" aria-label="First group">
      {actionLoading ? (
        <Spinner animation="grow" />
      ) : (
        <Button variant="danger" onClick={performAction}>
          <FontAwesomeIcon icon={faTrash as any} />
        </Button>
      )}
    </ButtonGroup>
  );
};

export default RemoveButton;
