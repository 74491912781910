import React from "react";
import "./scss/style.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-quill/dist/quill.snow.css";
import { RouterProvider } from "react-router-dom";
import { router } from "./router/app.router";

const App = () => {
  return <RouterProvider router={router} />;
};

export default App;
