import { CCard, CCardHeader, CCardBody } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import moment from "moment";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import { DownloadTableExcel } from "react-export-table-to-excel";

interface TableDataType {
  order_id: string;
  user_name: string | null;
  full_name: string;
  payment: string;
  checkin_date: string;
  checkout_date: string;
  rooms: number;
  room_price: number;
  conve_fee: number;
  plateform_fee: number;
  commision: number;
  status: string;
}

interface HotelListType {
  id: string;
  hotel_name: string;
}

const WalkInBookingsList: React.FC = () => {
  const tableRef = useRef(null);
  const [tableData, setTableData] = useState<TableDataType[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(false);
  const [hotellistLoading, setHotellistLoading] = useState<boolean>(false);
  const [hotelList, setHotelList] = useState<HotelListType[]>([]);
  const [hotelId, setHotelId] = useState<string>("");
  const [checkinFrom, setCheckinFrom] = useState<string>("");
  const [checkinTo, setCheckinTo] = useState<string>("");
  const [vendorId] = useState<string>("");
  const [downloadFileName, setDownloadFileName] = useState<string>("");

  let TOTALNUMBEROFROOMS = 0;
  let TOTALNUMBEROFNIGHTS = 0;
  let TOTALROOMREVENUE = 0;
  let TOTALCONVEFEE = 0;
  let TOTALPLATFORMFEE = 0;
  let TOTALCOMMISSION = 0;
  let TOTALGST = 0;
  let GROSSOWNSHARE = 0;
  let TOTALSILSTAYSSHARE = 0;
  let PAYMENTBYOWN = 0;
  let PAYMENTBYSILVER = 0;

  useEffect(() => {
    loadData();
    getHotelList();
  }, []);

  const loadData = () => {
    setTableLoading(true);
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "null");

    Api.post("Admin/Booked_room/walk_in_bookings", formData).then((res) => {
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    });
  };

  const filterByDate = () => {
    setTableLoading(true);
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "null");
    formData.append("checkinfrom", checkinFrom);
    formData.append("checkinto", checkinTo);
    formData.append("hotel_id", hotelId);
    formData.append("vendor_id", vendorId);

    Api.post("Admin/Booked_room/bookings_by_date/walkin", formData).then(
      (res) => {
        if (res.data.status === "success") {
          setTableData(res.data.data);
        } else {
          setTableData([]);
          alert(res.data.msg);
        }
        setTableLoading(false);
      }
    );
  };

  const getHotelList = () => {
    setHotellistLoading(true);
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "null");

    Api.post("Admin/hotel", formData).then((res) => {
      if (res.data.status === "success") {
        setHotelList(res.data.data);
      } else {
        setHotelList([]);
      }
      setHotellistLoading(false);
    });
  };

  return (
    <div>
      <CCard>
        <CCardHeader
          className={"d-flex justify-content-between align-items-end"}
        >
          <div className="d-flex align-items-end">
            <Form.Group className="m-0" controlId="exampleForm.ControlSelect1">
              <Form.Label>Select Hotel</Form.Label>
              <Form.Control
                name="hotel_filter"
                onChange={(event) => setHotelId(event.target.value)}
                value={hotelId}
                as="select"
              >
                <option value="">--- Select Hotel ---</option>
                {hotellistLoading ? (
                  <option>--- Loading..... ---</option>
                ) : (
                  hotelList.map((list, index) => (
                    <option key={index.toString()} value={list.id}>
                      {list.hotel_name}
                    </option>
                  ))
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group
              className="m-0 mx-3"
              controlId="exampleForm.ControlSelect1"
            >
              <Form.Label>Checked-In From</Form.Label>
              <Form.Control
                name="checked_in_from"
                type="date"
                value={checkinFrom}
                onChange={(event) => setCheckinFrom(event.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mx-3 m-0"
              controlId="exampleForm.ControlSelect1"
            >
              <Form.Label>Checked-In To</Form.Label>
              <Form.Control
                name="checked_in_to"
                type="date"
                value={checkinTo}
                onChange={(event) => setCheckinTo(event.target.value)}
              />
            </Form.Group>
            <Button onClick={filterByDate} variant="primary" type="button">
              Filter
            </Button>
            <Button
              className="ml-3"
              onClick={() => {
                setCheckinFrom("");
                setCheckinTo("");
                setHotelId("");
                loadData();
              }}
              variant="primary"
              type="button"
            >
              Reset
            </Button>
          </div>
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Form.Group className="mx-3 m-0">
                <Form.Control
                  name="download_file_name"
                  placeholder={"Enter file name"}
                  type="text"
                  value={downloadFileName}
                  onChange={(event) => setDownloadFileName(event.target.value)}
                />
              </Form.Group>

              <DownloadTableExcel
                filename={downloadFileName}
                sheet="tablexlsx"
                currentTableRef={tableRef.current}
              >
                <button className="btn btn-primary">Download Sheet</button>
              </DownloadTableExcel>
            </div>
          </div>
        </CCardHeader>

        <CCardBody style={{ padding: 0 }}>
          {tableLoading ? (
            <LoadingComponent />
          ) : (
            <Table
              ref={tableRef}
              style={{ fontSize: 12, margin: 0 }}
              striped
              bordered
              hover
              size="sm"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Booking ID</th>
                  <th>Name</th>
                  <th>Booking Type</th>
                  <th>Checked-In</th>
                  <th>Checked-Out</th>
                  <th>No. of Rooms.</th>
                  <th>No. of Nights</th>
                  <th>Total No. of Nights</th>
                  <th>Total Room Revenue</th>
                  <th>Convienience Fee</th>
                  <th>Platform fee</th>
                  <th>Commision</th>
                  <th>GST (18%)</th>
                  <th>Gross Owner Share</th>
                  <th>Gross Silver Stays Share</th>
                  <th>Payment Collected by Owner</th>
                  <th>Payment Collected Silver Stays</th>
                </tr>
              </thead>
              <tbody>
                {tableData.length > 0 &&
                  tableData.map((data, index) => {
                    const no_of_nights = moment(data.checkout_date).diff(
                      moment(data.checkin_date),
                      "days"
                    );
                    const totalNumberofNights = no_of_nights * data.rooms;
                    const total_price = data.room_price * no_of_nights;
                    const total_conve_fee =
                      data.rooms * no_of_nights * data.conve_fee;
                    const total_platform_fee =
                      ((total_price - total_conve_fee) * data.plateform_fee) /
                      100;
                    const total_commission =
                      ((total_price - total_conve_fee) * data.commision) / 100;
                    const GST =
                      ((total_conve_fee +
                        total_platform_fee +
                        total_commission) *
                        18) /
                      100;

                    const GrossOwnerShare =
                      total_price -
                      total_conve_fee -
                      total_platform_fee -
                      total_commission -
                      GST;
                    const GrossSilverStaysShare = total_price - GrossOwnerShare;

                    // Checking Collection
                    let collectbyown = 0;
                    let collectbystays = 0;
                    if (data.payment === "online") {
                      collectbystays = total_price;
                    } else if (data.payment === "payathotel") {
                      if (data.status === "na") {
                        collectbyown = 0;
                      } else {
                        collectbyown = total_price;
                      }
                    }

                    // Total Counting
                    TOTALNUMBEROFROOMS += Number(data.rooms);
                    TOTALNUMBEROFNIGHTS += Number(totalNumberofNights);
                    TOTALROOMREVENUE += Number(total_price);
                    TOTALCONVEFEE += Number(total_conve_fee);
                    TOTALCOMMISSION += Number(total_commission);
                    TOTALPLATFORMFEE += Number(total_platform_fee);
                    TOTALGST += Number(GST);
                    GROSSOWNSHARE += Number(GrossOwnerShare);
                    TOTALSILSTAYSSHARE += Number(GrossSilverStaysShare);
                    PAYMENTBYOWN += Number(collectbyown);
                    PAYMENTBYSILVER += Number(collectbystays);

                    return (
                      <tr key={index.toString()}>
                        <td>{index + 1}</td>
                        <td>{"#" + data.order_id}</td>
                        <td>
                          {data.user_name === null
                            ? data.full_name
                            : data.user_name}
                        </td>
                        <td>{data.payment}</td>
                        <td>{data.checkin_date}</td>
                        <td>{data.checkout_date}</td>
                        <td>{data.rooms}</td>
                        <td>{no_of_nights}</td>
                        <td>{totalNumberofNights}</td>
                        <td>{total_price}</td>
                        <td>{total_conve_fee}</td>
                        <td>{total_platform_fee.toFixed(2)}</td>
                        <td>{total_commission.toFixed(2)}</td>
                        <td>{GST.toFixed(2)}</td>
                        <td>{GrossOwnerShare.toFixed(2)}</td>
                        <td>{GrossSilverStaysShare.toFixed(2)}</td>
                        {data.status === "na" ? (
                          <th>NA</th>
                        ) : (
                          <td>{collectbyown}</td>
                        )}
                        <td>{collectbystays}</td>
                      </tr>
                    );
                  })}
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Total</th>
                  <th></th>
                  <th>{TOTALNUMBEROFROOMS}</th>
                  <th></th>
                  <th>{TOTALNUMBEROFNIGHTS}</th>
                  <th>{TOTALROOMREVENUE}</th>
                  <th>{TOTALCONVEFEE}</th>
                  <th>{TOTALPLATFORMFEE.toFixed(2)}</th>
                  <th>{TOTALCOMMISSION.toFixed(2)}</th>
                  <th>{TOTALGST.toFixed(2)}</th>
                  <th>{GROSSOWNSHARE.toFixed(2)}</th>
                  <th>{TOTALSILSTAYSSHARE.toFixed(2)}</th>
                  <th>{PAYMENTBYOWN.toFixed(2)}</th>
                  <th>{PAYMENTBYSILVER.toFixed(2)}</th>
                </tr>
              </tbody>
            </Table>
          )}
        </CCardBody>
      </CCard>
    </div>
  );
};

export default WalkInBookingsList;
