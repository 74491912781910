interface Floor {
  checked: boolean;
  rooms: Room[];
}

interface Room {
  room_category: number;
}

export const msHelper = {
  ImageUrl: `${process.env.REACT_APP_BASE_URL}uploads/images/`,

  amenitiesFind: (findAmenityID: number, amenitiesArray: number[]): number => {
    const find = amenitiesArray.find((amenity) => amenity === findAmenityID);
    return find !== undefined ? 1 : 0;
  },

  policiesFind: (findPolicyID: number, PoliciesArray: number[]): number => {
    const find = PoliciesArray.find((policy) => policy === findPolicyID);
    return find !== undefined ? 1 : 0;
  },

  searchFLoor: (floor: number, floorsArray: number[]): number | undefined => {
    for (let i = 0; i < floorsArray.length; i++) {
      if (floorsArray[i] === floor) {
        return floorsArray[i];
      }
    }
    return undefined;
  },

  resetFloorEntry: (floors: Floor[]): Floor[] => {
    return floors.map((item) => ({
      ...item,
      checked: false,
      rooms: [],
    }));
  },

  roomCount: (floor: Floor, room_cat_id: number): number => {
    return floor.rooms.reduce((totalRooms, room) => {
      return room.room_category === room_cat_id ? totalRooms + 1 : totalRooms;
    }, 0);
  },

  PadNumber: (num: number | string, size: number): string => {
    let s = num.toString();
    while (s.length < size) s = "0" + s;
    return s;
  },
};
