import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Button, Image } from "react-bootstrap";
import {
  faPlus,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import { msHelper } from "../../services/ms-helper.service";
import AddRoomCateModal from "./components/AddRoomCateModal";
import RoomCateEditBlock from "./components/RoomCateEditBlock";

const fields = [
  "id",
  "category_image",
  "category",
  "action_active",
  "show_on_home",
];

const RoomCategoriesPage = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [showAddModel, setShowAddModel] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token as string);

    Api.post("Admin/categories/all_room_category", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setTableData(res.data["data"]);
          setTableLoading(false);
        } else {
          setTableData([]);
          setTableLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setTableData([]);
        setTableLoading(false);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const closeAddModel = () => {
    setShowAddModel(false);
  };

  const showOnHome = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token as string);
    formData.append("id", id.toString());

    Api.post("Admin/categories/showonoff_home_room_cate", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          loadData();
        } else {
          console.error("Error toggling show on home:", res.data["msg"]);
        }
      })
      .catch((error) => {
        console.error("Error toggling show on home:", error);
      });
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            All Rooms Categories
            <button
              onClick={() => {
                setShowAddModel(true);
              }}
              className="btn btn-success float-right"
            >
              Add Category <FontAwesomeIcon icon={faPlus as any} />
            </button>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  category_image: (item: any) => (
                    <td>
                      <div>
                        <Image
                          style={{ width: 50 }}
                          src={`${msHelper.ImageUrl}/room_category/${item.cate_image}`}
                        />
                      </div>
                    </td>
                  ),
                  action_active: (item: any) => (
                    <td>
                      <RoomCateEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                  show_on_home: (item: any) => (
                    <td>
                      <Button
                        variant="link"
                        onClick={() => {
                          showOnHome(item.id);
                        }}
                      >
                        <FontAwesomeIcon
                          size={"lg"}
                          color={item.on_home === 0 ? "#6c757d" : "#007bff"}
                          icon={
                            (item.on_home === 0
                              ? faToggleOff
                              : faToggleOn) as any
                          }
                        />
                      </Button>
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>

      <AddRoomCateModal
        show={showAddModel}
        closeModel={closeAddModel}
        refreshList={loadData}
      />
    </div>
  );
};

export default RoomCategoriesPage;
