import { Api } from "./axios.service";

interface IActions {
  formData?: boolean;
  showloading?: boolean;
  loading?: React.Dispatch<React.SetStateAction<boolean>>;
  onSuccessCallback: (data: any) => void;
  onError?: (msg: string) => void;
  url: string;
  values: FormData;
}

const ApiRequest = async (action: IActions) => {
  const {
    onSuccessCallback,
    loading,
    onError,
    formData = false,
    showloading = true,
  } = action;
  showloading && loading && loading(true);
  try {
    let response;
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (formData) {
      response = await Api.post(action.url, action.values, config);
    } else {
      response = await Api.post(action.url, action.values);
    }

    const data = response.data;
    if (data.status === "success") {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    } else {
      onError ? onError(data) : alert(data.msg);
    }
  } catch (error) {
    const newErr = error as { message: string };
    onError ? onError(newErr.message) : alert(newErr.message);
  } finally {
    loading && loading(false);
  }
};

export default ApiRequest;
