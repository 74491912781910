import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Button } from "react-bootstrap";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";

const fields = ["hotel_name", "username", "rating", "active"];

const HotelAllRatingPage: React.FC = () => {
  const [Table_data, setTableData] = useState<any[]>([]);
  const [TableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    Api.post("Admin/rating", formData).then((res) => {
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
      }
    });
  };

  const activeInactive = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", id.toString());

    Api.post("Admin/rating/active_inactive_rating", formData).then((res) => {
      if (res.data["status"] === "success") {
        loadData();
      } else {
        console.log(res.data);
      }
    });
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>All Ratings</CCardHeader>
          <CCardBody>
            {TableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={Table_data}
                fields={fields}
                striped
                tableFilter
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  active: (item: any) => (
                    <td>
                      <Button
                        variant="primary"
                        onClick={() => {
                          activeInactive(item.id);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={
                            (item.active === "0" ? faEyeSlash : faEye) as any
                          }
                        />
                      </Button>
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default HotelAllRatingPage;
