import { CCardBody, CCardHeader, CWidgetIcon } from "@coreui/react";
import { faUserAltSlash, faUsers } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Api } from "../../../../services/axios.service";

const SmallWidgets = () => {
  const [AllUsers, setAllUsers] = useState(0);
  const [AllVendors, setAllVendors] = useState(0);
  const [InactiveUsers, setInactiveUsers] = useState(0);
  const [InactiveVendors, setInactiveVendors] = useState(0);
  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    Api.post("Admin/dashboard/small_widget_data", formData)
      .then((res) => {
        var data = res.data["data"];
        if (res.data["status"] === "success") {
          setAllUsers(data.all_users.length);
          setAllVendors(data.all_vendors.length);
          setInactiveUsers(data.inactive_users.length);
          setInactiveVendors(data.inactive_vendors.length);
        } else {
          setAllUsers(0);
          setAllVendors(0);
          setInactiveUsers(0);
          setInactiveVendors(0);
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  };
  return (
    <Card>
      <CCardHeader>This Month Data</CCardHeader>
      <CCardBody>
        <Row>
          <Col lg="3">
            <Link to="/users">
              <CWidgetIcon
                text="All Users"
                header={AllUsers.toString()}
                color="primary"
              >
                <FontAwesomeIcon icon={faUsers as any} size="lg" />
              </CWidgetIcon>
            </Link>
          </Col>
          <Col lg="3">
            <Link to="/in-active-users">
              <CWidgetIcon
                text="In-Active Users"
                header={InactiveUsers.toString()}
                color="success"
              >
                <FontAwesomeIcon icon={faUserAltSlash as any} size="lg" />
              </CWidgetIcon>
            </Link>
          </Col>
          <Col lg="3">
            <Link to="/vendors">
              <CWidgetIcon
                text="All Vendors"
                header={AllVendors.toString()}
                color="warning"
              >
                <FontAwesomeIcon icon={faUsers as any} size="lg" />
              </CWidgetIcon>
            </Link>
          </Col>
          <Col lg="3">
            <Link to="/in-active-vendors">
              <CWidgetIcon
                text="In-Active Vendors"
                header={InactiveVendors.toString()}
                color="danger"
              >
                <FontAwesomeIcon icon={faUserAltSlash as any} size="lg" />
              </CWidgetIcon>
            </Link>
          </Col>
        </Row>
      </CCardBody>
    </Card>
  );
};

export default memo(SmallWidgets);
