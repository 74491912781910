import { CCard, CCardHeader, CCardBody } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Form, Table } from "react-bootstrap";
import { DownloadTableExcel } from "react-export-table-to-excel";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import { bookingSheetData } from "./helper/bookingData.helper";

// Define interfaces for the expected data types
interface TableDataItem {
  order_id: string;
  user_name: string | null;
  full_name: string;
  payment: string;
  checkin_date: string;
  checkout_date: string;
  rooms: number;
}

interface Hotel {
  id: string;
  hotel_name: string;
}

const BookingsList: React.FC = () => {
  const tableRef = useRef(null);
  const [TableData, setTableData] = useState<TableDataItem[]>([]);
  const [TableLoading, setTableLoading] = useState(false);
  const [hotellistLoading, setHotelListLoading] = useState(false);
  const [hotelList, setHotelList] = useState<Hotel[]>([]);
  const [hotelId, setHotelId] = useState<string>("");
  const [checkinFrom, setCheckinFrom] = useState<string>("");
  const [checkinTo, setCheckinTo] = useState<string>("");
  const [vendorId] = useState<string>("");

  const [downloadFileName, setDownloadFileName] = useState<string>("");

  let TOTALNUMBEROFROOMS = 0;
  let TOTALNUMBEROFNIGHTS = 0;
  let TOTALROOMREVENUE = 0;
  let TOTALCONVEFEE = 0;
  let TOTALPLATFORMFEE = 0;
  let TOTALCOMMISSION = 0;
  let TOTALGST = 0;
  let GROSSOWNSHARE = 0;
  let TOTALSILSTAYSSHARE = 0;
  let PAYMENTBYOWN = 0;
  let PAYMENTBYSILVER = 0;
  let TOTAL_TDS = 0;

  useEffect(() => {
    loadData();
    getHotelList();
  }, []);

  const loadData = () => {
    setTableLoading(true);
    const formdata = new FormData();
    let token = localStorage.getItem("token");
    formdata.append("token", token ?? "null");

    Api.post("Admin/Booked_room/get_booking_sheet_data/app", formdata).then(
      (res) => {
        if (res.data["status"] === "success") {
          setTableData(res.data["data"]);
          setTableLoading(false);
        } else {
          setTableData([]);
          setTableLoading(false);
        }
      }
    );
  };

  const FilterByDate = () => {
    setTableLoading(true);
    const formdata = new FormData();
    let token = localStorage.getItem("token");
    formdata.append("token", token ?? "null");

    formdata.append("checkinfrom", checkinFrom);
    formdata.append("checkinto", checkinTo);
    formdata.append("hotel_id", hotelId);
    formdata.append("vendor_id", vendorId);
    Api.post("Admin/Booked_room/bookings_by_date", formdata).then((res) => {
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
        alert(res.data["msg"]);
      }
    });
  };

  const getHotelList = () => {
    setHotelListLoading(true);
    const formdata = new FormData();
    let token = localStorage.getItem("token");
    formdata.append("token", token ?? "null");

    Api.post("Admin/hotel", formdata).then((res) => {
      if (res.data["status"] === "success") {
        setHotelList(res.data["data"]);
        setHotelListLoading(false);
      } else {
        setHotelList([]);
        setHotelListLoading(false);
      }
    });
  };

  return (
    <div>
      <CCard>
        <CCardHeader
          className={"d-flex justify-content-between align-items-end"}
        >
          <div className="d-flex align-items-end">
            <Form.Group className="m-0" controlId="exampleForm.ControlSelect1">
              <Form.Label>Select Hotel</Form.Label>
              <Form.Control
                name="hotel_filter"
                onChange={(event) => setHotelId(event.target.value)}
                value={hotelId}
                as="select"
              >
                <option value="">--- Select Hotel ---</option>
                {hotellistLoading ? (
                  <option>--- Loading..... ---</option>
                ) : (
                  hotelList.map((list) => (
                    <option key={list.id} value={list.id}>
                      {list.hotel_name}
                    </option>
                  ))
                )}
              </Form.Control>
            </Form.Group>

            <Form.Group
              className="m-0 mx-3"
              controlId="exampleForm.ControlSelect1"
            >
              <Form.Label>Checked-In From</Form.Label>
              <Form.Control
                name="checked_in_from"
                type="date"
                value={checkinFrom}
                onChange={(event) => setCheckinFrom(event.target.value)}
              />
            </Form.Group>
            <Form.Group
              className="mx-3 m-0"
              controlId="exampleForm.ControlSelect1"
            >
              <Form.Label>Checked-In To</Form.Label>
              <Form.Control
                name="checked_in_to"
                type="date"
                value={checkinTo}
                onChange={(event) => setCheckinTo(event.target.value)}
              />
            </Form.Group>
            <Button onClick={FilterByDate} variant="primary" type="button">
              Filter
            </Button>
            <Button
              className="ml-3"
              onClick={() => {
                setCheckinFrom("");
                setCheckinTo("");
                setHotelId("");
                loadData();
              }}
              variant="primary"
              type="button"
            >
              Reset
            </Button>
          </div>
          <div>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Form.Group className="mx-3 m-0">
                <Form.Control
                  name="download_file_name"
                  placeholder={"Enter file name"}
                  type="text"
                  value={downloadFileName}
                  onChange={(event) => setDownloadFileName(event.target.value)}
                />
              </Form.Group>
              <DownloadTableExcel
                filename={downloadFileName}
                sheet="tablexlsx"
                currentTableRef={tableRef.current}
              >
                <button className="btn btn-primary">Download Sheet</button>
              </DownloadTableExcel>
            </div>
          </div>
        </CCardHeader>

        <CCardBody style={{ padding: 0 }}>
          {TableLoading ? (
            <LoadingComponent />
          ) : (
            <Table
              ref={tableRef}
              style={{ fontSize: 12, margin: 0 }}
              striped
              bordered
              hover
              size="sm"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>Booking ID</th>
                  <th>Name</th>
                  <th>Booking Type</th>
                  <th>Checked-In</th>
                  <th>Checked-Out</th>
                  <th>No. of Rooms.</th>
                  <th>No. of Nights</th>
                  <th>Total No. of Nights</th>
                  <th>Total Room Revenue</th>
                  <th>Convenience Fee</th>
                  <th>Platform fee</th>
                  <th>Commission</th>
                  <th>GST (18%)</th>
                  <th>TDS (1%)</th>
                  <th>Gross Owner Share</th>
                  <th>Gross Silver Stays Share</th>
                  <th>Payment Collected by Owner</th>
                  <th>Payment Collected Silver Stays</th>
                </tr>
              </thead>
              <tbody>
                {TableData.length > 0 &&
                  TableData.map((data, index) => {
                    const sheetData = bookingSheetData(data);
                    const {
                      totalNumberofNights,
                      total_commission,
                      total_conve_fee,
                      GST,
                      GrossOwnerShare,
                      GrossSilverStaysShare,
                      collectbyown,
                      collectbystays,
                      total_platform_fee,
                      total_price,
                      no_of_nights,
                      TDS,
                    } = sheetData;

                    // Total Counting
                    TOTALNUMBEROFROOMS += Number(data.rooms);
                    TOTALNUMBEROFNIGHTS += Number(totalNumberofNights);
                    TOTALROOMREVENUE += Number(total_price);
                    TOTALCONVEFEE += Number(total_conve_fee);
                    TOTALCOMMISSION += Number(total_commission);
                    TOTALPLATFORMFEE += Number(total_platform_fee);
                    TOTALGST += Number(GST);
                    GROSSOWNSHARE += Number(GrossOwnerShare);
                    TOTALSILSTAYSSHARE += Number(GrossSilverStaysShare);
                    PAYMENTBYOWN += Number(collectbyown);
                    PAYMENTBYSILVER += Number(collectbystays);
                    TOTAL_TDS += Number(TDS);

                    return (
                      <tr key={index.toString()}>
                        <td>{index + 1}</td>
                        <td>{"#" + data.order_id}</td>
                        <td>
                          {data.user_name === null
                            ? data.full_name
                            : data.user_name}
                        </td>
                        <td>{data.payment}</td>
                        <td>{data.checkin_date}</td>
                        <td>{data.checkout_date}</td>
                        <td>{data.rooms}</td>
                        <td>{no_of_nights}</td>
                        <td>{totalNumberofNights}</td>
                        <td>{total_price}</td>
                        <td>{total_conve_fee}</td>
                        <td>{total_platform_fee.toFixed(2)}</td>
                        <td>{total_commission.toFixed(2)}</td>
                        <td>{GST.toFixed(2)}</td>
                        <td>{TDS.toFixed(2)}</td>
                        <td>{GrossOwnerShare.toFixed(2)}</td>
                        <td>{GrossSilverStaysShare.toFixed(2)}</td>
                        <td>{collectbyown}</td>
                        <td>{collectbystays}</td>
                      </tr>
                    );
                  })}
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                </tr>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th>Total</th>
                  <th></th>
                  <th>{TOTALNUMBEROFROOMS}</th>
                  <th></th>
                  <th>{TOTALNUMBEROFNIGHTS}</th>
                  <th>{TOTALROOMREVENUE}</th>
                  <th>{TOTALCONVEFEE}</th>
                  <th>{TOTALPLATFORMFEE.toFixed(2)}</th>
                  <th>{TOTALCOMMISSION.toFixed(2)}</th>
                  <th>{TOTALGST.toFixed(2)}</th>
                  <th>{TOTAL_TDS.toFixed(2)}</th>
                  <th>{GROSSOWNSHARE.toFixed(2)}</th>
                  <th>{TOTALSILSTAYSSHARE.toFixed(2)}</th>
                  <th>{PAYMENTBYOWN.toFixed(2)}</th>
                  <th>{PAYMENTBYSILVER.toFixed(2)}</th>
                </tr>
              </tbody>
            </Table>
          )}
        </CCardBody>
      </CCard>
    </div>
  );
};

export default BookingsList;
