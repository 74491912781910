import React, { useState, useEffect, ChangeEvent } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../services/axios.service";
import { msHelper } from "../../services/ms-helper.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import HotelCheckBox from "./components/HotelCheckBox";

const fields = ["hotel_id", "hotel_name", "room_category", "actual_price"];
const selectedFields = [
  "hotel_id",
  "hotel_name",
  "room_category",
  "actual_price",
  "new_price",
];

let allCheckboxArray: string[] = [];

export interface Room {
  hotel_id: string;
  hotel_name: string;
  room_category: string;
  actual_price: string;
  min_price?: string;
  max_price?: string;
  new_price?: string;
  id: string;
}

interface Category {
  id: string;
  category: string;
}

const PriceModalPage = () => {
  const [tableData, setTableData] = useState<Room[]>([]);
  const [selectedRooms, setSelectedRooms] = useState<Room[]>([]);
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  const [loadingCategory, setLoadingCategory] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [formError, setFormError] = useState<string>("");

  useEffect(() => {
    loadData();
    getCategoryList();
    allCheckboxArray = [];
  }, []);

  const loadData = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);

    Api.post("Admin/rooms/get_all_hotel_rooms", formData).then((res) => {
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    });
  };

  const getCategoryList = () => {
    setLoadingCategory(true);
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);

    Api.post("Admin/categories/active_room_category", formData).then((res) => {
      if (res.data.status === "success") {
        setCategoryList(res.data.data);
      } else {
        setCategoryList([]);
      }
      setLoadingCategory(false);
    });
  };

  const onSelect = (e: ChangeEvent<HTMLInputElement>, item: Room) => {
    let selectedData = [...selectedRooms];

    if (e.target.checked) {
      allCheckboxArray.push(e.target.value);
      selectedData.push(item);
      setSelectedRooms(selectedData);
    } else {
      allCheckboxArray = allCheckboxArray.filter(
        (checkboxItem) => checkboxItem !== e.target.value
      );
      selectedData = selectedData.filter((room) => room.id !== e.target.value);
      setSelectedRooms(selectedData);
    }
  };

  const priceIncreDecrement = (increDecre: number) => {
    setTableLoading(true);
    setFormError("");

    if (allCheckboxArray.length === 0) {
      setFormError("Please Select a Room");
      setTableLoading(false);
      return;
    }

    const formData = new FormData();
    const token = localStorage.getItem("token") || "";

    formData.append("token", token);
    formData.append("increDecre", increDecre.toString());

    allCheckboxArray.forEach((item) => {
      formData.append("rooms[]", item);
    });

    Api.post("Admin/rooms/price_incre_decrement", formData)
      .then((res) => {
        setFormError("");
        setTableLoading(false);
        if (res.data.status === "success") {
          setSelectedRooms(res.data.new_price_array);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const filterRoomByCate = (e: ChangeEvent<HTMLSelectElement>) => {
    setTableLoading(true);
    const category_id = e.target.value;
    if (category_id === "") {
      loadData();
      return;
    }
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);
    formData.append("category_id", category_id);

    Api.post("Admin/rooms/get_rooms_by_category", formData).then((res) => {
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    });
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          {formError && <div className="alert alert-danger">{formError}</div>}
          <CCardHeader className="d-flex justify-content-between align-items-center">
            All Hotel Rooms
            <div className="filter_Form d-flex justify-content-between align-items-center">
              <Form.Group
                controlId="exampleForm.ControlSelect1"
                className="m-0"
              >
                <Form.Control
                  name="vendor_filter"
                  onChange={filterRoomByCate}
                  as="select"
                >
                  <option value="">--- Select Category ---</option>
                  {loadingCategory ? (
                    <option>--- Loading..... ---</option>
                  ) : (
                    categoryList.map((list, index) => (
                      <option key={index.toString()} value={list.id}>
                        {list.category}
                      </option>
                    ))
                  )}
                </Form.Control>
              </Form.Group>

              <ButtonGroup className="ml-5">
                <Button
                  onClick={() => priceIncreDecrement(1)}
                  variant="success"
                >
                  <FontAwesomeIcon size="lg" icon={faAngleUp as any} />{" "}
                  Increment
                </Button>
                <Button onClick={() => priceIncreDecrement(0)} variant="danger">
                  <FontAwesomeIcon size="lg" icon={faAngleDown as any} />{" "}
                  Decrement
                </Button>
              </ButtonGroup>
            </div>
          </CCardHeader>
          <CCardBody>
            <h4>Selected Rooms</h4>
            <CDataTable
              items={selectedRooms}
              fields={selectedFields}
              pagination
              itemsPerPage={50}
              striped
              scopedSlots={{
                actual_price: (item: Room) => (
                  <td>
                    {item.actual_price}
                    <small className="small text-muted d-block">
                      {"Rs." + item.min_price + " - Rs." + item.max_price}
                    </small>
                  </td>
                ),
                hotel_id: (item: Room) => (
                  <td>{"#" + msHelper.PadNumber(item.hotel_id, 4)}</td>
                ),
                new_price: (item: Room) => (
                  <td>{item.new_price === undefined ? "-" : item.new_price}</td>
                ),
              }}
            />

            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                pagination
                tableFilter
                itemsPerPage={10}
                clickableRows
                striped
                scopedSlots={{
                  actual_price: (item: Room) => (
                    <td>
                      {item.actual_price}
                      <small className="small text-muted d-block">
                        {"Rs." +
                          item.min_price +
                          " - " +
                          "Rs." +
                          item.max_price}
                      </small>
                    </td>
                  ),
                  hotel_id: (item: Room) => (
                    <td>{"#" + msHelper.PadNumber(item.hotel_id, 4)}</td>
                  ),
                  hotel_name: (item: Room) => (
                    <td>
                      <HotelCheckBox
                        allCheckboxArray={allCheckboxArray}
                        item={item}
                        onSelect={(e, item) => onSelect(e, item)}
                      />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default PriceModalPage;
