import React, { useState, useEffect, ChangeEvent } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import moment from "moment";
import { ButtonGroup, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";
import Location from "../components/Location";
import BookedRoomsEditBlock from "../components/BookedRoomsEditBlock";

const fields = [
  "booking_id",
  "user_name",
  "hotel_name",
  "hotel_id",
  "checkin_date",
  "checkout_date",
  "Booking_date",
  "location",
  "status",
  "view_status",
];

interface Vendor {
  id: string;
  full_name: string;
}

interface TableData {
  booking_id: string;
  user_name: string;
  full_name: string;
  hotel_name: string;
  hotel_id: string;
  checkin_date: string;
  checkout_date: string;
  created_at: string;
  cncl_latitude: string;
  cncl_longitude: string;
  cncl_position: string;
  lat: string;
  lng: string;
  status: string;
}

const AllBookedRoomsPage = () => {
  const [tableData, setTableData] = useState<TableData[]>([]);
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [tableLoading, setTableLoading] = useState(true);
  const [loadingVendor, setLoadingVendor] = useState(false);

  useEffect(() => {
    loadData();
    getVendorsList();
  }, []);

  const loadData = async () => {
    const formdata = new FormData();
    const token = localStorage.getItem("token") ?? "";
    formdata.append("token", token);

    try {
      const res = await Api.post("Admin/Booked_room", formdata);
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error loading data", error);
    } finally {
      setTableLoading(false);
    }
  };

  const getVendorsList = async () => {
    setLoadingVendor(true);
    const formdata = new FormData();
    const token = localStorage.getItem("token") ?? "";
    formdata.append("token", token);

    try {
      const res = await Api.post("Admin/vendors", formdata);
      if (res.data.status === "success") {
        setVendorList(res.data.data);
      } else {
        setVendorList([]);
      }
    } catch (error) {
      console.error("Error loading vendors", error);
    } finally {
      setLoadingVendor(false);
    }
  };

  const filterVendorBooking = async (e: ChangeEvent<HTMLSelectElement>) => {
    setTableLoading(true);
    const vendorId = e.target.value;

    if (vendorId === "") {
      loadData();
      return;
    }

    const formdata = new FormData();
    const token = localStorage.getItem("token") ?? "";
    formdata.append("token", token);
    formdata.append("vendor_id", vendorId);

    try {
      const res = await Api.post(
        "Admin/Booked_room/get_vendor_booked_rooms",
        formdata
      );
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error filtering vendor bookings", error);
    } finally {
      setTableLoading(false);
    }
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader className="d-flex justify-content-between align-items-center">
            All Bookings
            <div className="filter_Form d-flex justify-content-between align-items-center">
              <Form.Group
                className="m-0"
                controlId="exampleForm.ControlSelect1"
              >
                <Form.Control
                  name="vendor_filter"
                  onChange={filterVendorBooking}
                  as="select"
                >
                  <option value="">--- Select Vendor ---</option>
                  {loadingVendor ? (
                    <option>--- Loading..... ---</option>
                  ) : (
                    vendorList.map((list, index) => (
                      <option key={list.id + index} value={list.id}>
                        {list.full_name}
                      </option>
                    ))
                  )}
                </Form.Control>
              </Form.Group>
              <ButtonGroup className="ml-5">
                <Link
                  target="_blank"
                  to="/booking-list"
                  className="btn btn-primary"
                >
                  <FontAwesomeIcon size="lg" icon={faCloudDownloadAlt as any} />
                  Booking Sheet
                </Link>
              </ButtonGroup>
            </div>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                tableFilter
                pagination
                itemsPerPage={10}
                scopedSlots={{
                  booking_id: (item: TableData) => (
                    <td>{"#" + item.booking_id}</td>
                  ),
                  user_name: (item: TableData) => (
                    <td>{item.user_name ?? item.full_name}</td>
                  ),
                  hotel_id: (item: TableData) => <td>{"#" + item.hotel_id}</td>,
                  Booking_date: (item: TableData) => (
                    <td>{moment(item.created_at).format("YYYY-MM-DD")}</td>
                  ),
                  location: (item: TableData) => (
                    <td>
                      <Location
                        lat={item.cncl_latitude}
                        lng={item.cncl_longitude}
                        position={item.cncl_position}
                        hotel_lat={item.lat}
                        hotel_lng={item.lng}
                        status={item.status}
                      />
                    </td>
                  ),
                  status: (item: TableData) => (
                    <td>
                      <span
                        className={
                          item.status === "booked"
                            ? "badge badge-success"
                            : item.status === "cancelled"
                            ? "badge badge-danger"
                            : item.status === "pending"
                            ? "badge badge-warning"
                            : "badge badge-secondary"
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                  ),
                  view_status: (item: TableData) => (
                    <td>
                      <BookedRoomsEditBlock
                        refreshList={loadData}
                        item={item}
                      />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default AllBookedRoomsPage;
