import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../../services/axios.service";
import { msHelper } from "../../../../services/ms-helper.service";

interface Props {
  image: {
    id: number;
    image: string;
    tag: string;
    on_front: number;
  };
  getRoomImages: () => void;
}

const HotelImageBlock: React.FC<Props> = (props) => {
  const [deletingImage, setDeletingImage] = useState(false);

  const deleteImage = (imageId: number) => {
    setDeletingImage(true);
    const formdata = new FormData();
    let token = localStorage.getItem("token");
    formdata.append("token", token || "");
    formdata.append("id", imageId.toString());
    Api.post("Admin/hotel/delete_hotel_image", formdata)
      .then((res) => {
        setDeletingImage(false);
        if (res.data["status"] === "success") {
          props.getRoomImages();
        }
      })
      .catch((error) => {
        setDeletingImage(false);
        console.log(error);
      });
  };

  const showOnFront = (id: number) => {
    const formdata = new FormData();
    let token = localStorage.getItem("token");
    formdata.append("token", token || "");
    formdata.append("id", id.toString());

    Api.post("Admin/hotel/showonoff_front_image", formdata).then((res) => {
      if (res.data["status"] === "success") {
        props.getRoomImages();
      } else {
        console.log(res.data);
      }
    });
  };

  let image = props.image;

  return (
    <div className={"mt-3 card"}>
      <img
        className={"img-fluid"}
        src={`${msHelper.ImageUrl}/hotels/${image.image}`}
        alt={""}
      />
      <div style={{ position: "absolute", background: "#fff", right: 0 }}>
        <button
          type="button"
          onClick={() => deleteImage(image.id)}
          className={"btn m-0 px-2 py-0"}
          style={{ fontWeight: "bold", color: "red" }}
        >
          <FontAwesomeIcon icon={faTimes as any} />
        </button>
      </div>
      <div style={{ textAlign: "center" }}>{image.tag}</div>
      <div
        className="d-flex justify-content-between align-items-center px-2"
        style={{ borderTop: "1px solid #ececec" }}
      >
        Show On Front
        <Button
          variant="link"
          onClick={() => {
            showOnFront(image.id);
          }}
        >
          <FontAwesomeIcon
            size={"lg"}
            color={image.on_front === 0 ? "#6c757d" : "#007bff"}
            icon={(image.on_front === 0 ? faToggleOff : faToggleOn) as any}
          />
        </Button>
      </div>
    </div>
  );
};

export default HotelImageBlock;
