import { CContainer, CFade } from "@coreui/react";
import React from "react";
import { Outlet } from "react-router-dom";

const Content = () => {
  return (
    <main className="c-main">
      <CContainer fluid>
        <CFade>
          <Outlet />
        </CFade>
      </CContainer>
    </main>
  );
};

export default Content;
