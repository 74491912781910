import { CDataTable } from "@coreui/react";
import React, { useCallback, useEffect, useState } from "react";
import { Api, formDataHelper } from "../../../services/axios.service";
import { API_URLS } from "../../../services/api-urls.service";
import { msHelper } from "../../../services/ms-helper.service";
import ActionButton from "./ActionButton";

interface SelectHotelModelProps {
  refreshList: () => void;
}

const fields = ["hotel_id", "vendor_name", "hotel_name", "address", "add"];

const SelectHotelModel: React.FC<SelectHotelModelProps> = ({ refreshList }) => {
  const [loading, setLoading] = useState(false);
  const [hotels, setHotels] = useState<any[]>([]);

  const loadHotels = useCallback(async () => {
    const formData = formDataHelper();
    const filterArray = { popular: 0, active: 1 };
    formData.append("filter", JSON.stringify(filterArray));
    setLoading(true);
    try {
      const res = await Api.post(API_URLS.GET_HOTELS, formData);
      if (res.data["status"] === "success") {
        setHotels(res.data["data"]);
      }
    } catch (error) {
      console.error("Error fetching hotels:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadHotels();
  }, [loadHotels]);

  return (
    <CDataTable
      items={hotels}
      fields={fields}
      loading={loading}
      striped
      itemsPerPage={10}
      tableFilter
      pagination
      scopedSlots={{
        hotel_id: (item: any) => (
          <td>{"#" + msHelper.PadNumber(item.id, 4)}</td>
        ),
        add: (item: any) => (
          <td>
            <ActionButton
              hotelId={item.id}
              refreshList={refreshList}
              loadHotels={loadHotels}
            />
          </td>
        ),
      }}
    />
  );
};

export default SelectHotelModel;
