import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row, Spinner } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

const AddHotelPage = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [formError, setFormError] = useState<any>({});
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [image, setImage] = useState<File | null>(null);
  const [amenityData, setAmenityData] = useState<any[]>([]);
  const [vendorList, setVendorList] = useState<any[]>([]);
  const [stateList, setStateList] = useState<any[]>([]);
  const [cityList, setCityList] = useState<any[]>([]);
  const [selectStateLoading, setSelectStateLoading] = useState<boolean>(true);
  const [selectCityLoading, setSelectCityLoading] = useState<boolean>(true);
  const [policyList, setPolicyList] = useState<any[]>([]);

  useEffect(() => {
    loadAmenities();
    loadStates();
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setImage(e.target.files[0]);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormError({});
    setFormSuccess("");

    const partialPayment = e.currentTarget.partial_payment.checked;
    const payAtHotel = e.currentTarget.pay_at_hotel.checked;
    const payOnline = e.currentTarget.pay_online.checked;

    if (!(partialPayment || payAtHotel || payOnline)) {
      setFormError({
        ...formError,
        payment_option: "Payment option field is required",
      });
      return;
    }

    const formData = new FormData(e.currentTarget);

    const token = localStorage.getItem("token");

    const allAmenitiesArray = e.currentTarget.amenities;
    Array.from(allAmenitiesArray).forEach((item: any) => {
      if (item.checked) {
        formData.append("amenities[]", item.value);
      }
    });

    const allPoliciesArray = e.currentTarget.policies;
    Array.from(allPoliciesArray).forEach((item: any) => {
      if (item.checked) {
        formData.append("policies[]", item.value);
      }
    });

    formData.append("token", token || "");

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/hotel/add", formData, config).then((res) => {
      window.scrollTo(0, 0);
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        (e.currentTarget as any).reset();
      } else {
        setFormError(res.data["formErrors"]);
      }
    });
  };

  const loadAmenities = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");

    Api.post("Admin/amenities", formData).then((res) => {
      if (res.data["status"] === "success") {
        setAmenityData(res.data["data"]);
        setLoading(false);
      } else {
        setAmenityData([]);
      }
    });

    Api.post("Admin/vendors", formData).then((res) => {
      if (res.data["status"] === "success") {
        setVendorList(res.data["data"]);
        setLoading(false);
      } else {
        setVendorList([]);
      }
    });

    Api.post("Admin/policy", formData).then((res) => {
      if (res.data["status"] === "success") {
        setPolicyList(res.data["data"]);
        setLoading(false);
      } else {
        setPolicyList([]);
      }
    });
  };

  const loadStateCity = (state: string) => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("state_id", state);

    Api.post("Admin/region/get_cities_by_state_id", formData).then((res) => {
      if (res.data["status"] === "success") {
        setCityList(res.data["data"]);
        setSelectCityLoading(false);
      } else {
        setCityList([]);
      }
    });
  };

  const loadStates = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");

    Api.post("Admin/region/get_states", formData).then((res) => {
      if (res.data["status"] === "success") {
        setStateList(res.data["data"]);
        setSelectStateLoading(false);
      } else {
        setStateList([]);
      }
    });
  };

  return (
    <div>
      <CCard>
        <CCardHeader>
          <h4>Add Hotel</h4>
        </CCardHeader>
        <CCardBody>
          {formSuccess !== "" && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form encType={"multipart/form-data"} onSubmit={handleSubmit}>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Hotel Information </h4>
                <Form.Group>
                  <Form.Label>Hotel Name</Form.Label>
                  <Form.Control
                    name="hotel_name"
                    type="text"
                    placeholder="Enter hotel name"
                  />
                  <div className="invalid-feedback d-block">
                    {formError.hotel_name}
                  </div>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Hotel Address</Form.Label>
                  <Form.Control
                    name="hotel_address"
                    type="text"
                    placeholder="Enter hotel address"
                  />
                  <div className="invalid-feedback d-block">
                    {formError.hotel_address}
                  </div>
                </Form.Group>
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Vendor</Form.Label>
                  <Form.Control name="vendor" as="select" custom>
                    <option value="">--- select vendor ---</option>
                    {vendorList.map((list, index) => (
                      <option key={index.toString()} value={list.id}>
                        {list.full_name}
                      </option>
                    ))}
                  </Form.Control>
                  <div className="invalid-feedback d-block">
                    {formError.vendor}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Description </h4>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    name="hotel_description"
                  />
                  <div className="invalid-feedback d-block">
                    {formError.hotel_description}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Select Amenities </h4>

                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div className="mt-3" key={`inline-checkbox`}>
                    {amenityData.map((list, index) => (
                      <Form.Check
                        name="amenities"
                        className="mycheckbox"
                        value={list.id}
                        key={index.toString()}
                        inline
                        label={list.amenity}
                        type={"checkbox"}
                        id={`inline-checkbox-${list.amenity}`}
                      />
                    ))}
                    <div className="invalid-feedback d-block">
                      {formError.amenities}
                    </div>
                  </div>
                )}
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Select Region </h4>

                <Row>
                  <Col lg="4">
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>Country</Form.Label>
                      <Form.Control name="country" as="select" custom>
                        <option value="">--- select Country ---</option>
                        <option value="India">India</option>
                      </Form.Control>
                      <div className="invalid-feedback d-block">
                        {formError.country}
                      </div>
                    </Form.Group>
                  </Col>
                  <Col lg="4">
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>State</Form.Label>
                      <Form.Control
                        name="state"
                        as="select"
                        custom
                        onChange={(
                          event: React.ChangeEvent<HTMLSelectElement>
                        ) => {
                          const state = event.target.value;
                          if (state === "") {
                            setCityList([]);
                          } else {
                            loadStateCity(state);
                          }
                        }}
                      >
                        <option value="">--- select State ---</option>
                        {stateList.map((list, index) => (
                          <option key={index.toString()} value={list.id}>
                            {list.name}
                          </option>
                        ))}
                      </Form.Control>
                      <div className="invalid-feedback d-block">
                        {formError.state}
                      </div>
                    </Form.Group>
                  </Col>

                  <Col lg="4">
                    <Form.Group controlId="exampleForm.SelectCustom">
                      <Form.Label>City</Form.Label>
                      <Form.Control name="city" as="select" custom>
                        <option value="">--- select city ---</option>
                        {cityList.map((list, index) => (
                          <option key={index.toString()} value={list.id}>
                            {list.city_name}
                          </option>
                        ))}
                      </Form.Control>
                      <div className="invalid-feedback d-block">
                        {formError.city}
                      </div>
                    </Form.Group>
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Hotel Check-in / Check-out time </h4>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Check-In Time</Form.Label>
                  <Form.Control
                    name="check_in_time"
                    type="time"
                    placeholder="Enter category name"
                  />
                  <div className="invalid-feedback d-block">
                    {formError.check_in_time}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Check-Out Time</Form.Label>
                  <Form.Control
                    name="check_out_time"
                    type="time"
                    placeholder="Enter category name"
                  />
                  <div className="invalid-feedback d-block">
                    {formError.check_out_time}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Select Hotel Policy & Rules </h4>
                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div className="mt-3" key={`inline-checkbox`}>
                    {policyList.map((list, index) => (
                      <Form.Check
                        name="policies"
                        className="mycheckbox"
                        value={list.id}
                        key={index.toString()}
                        label={list.policy}
                        type={"checkbox"}
                        id={`inline-checkbox-${list.policy}`}
                      />
                    ))}
                    <div className="invalid-feedback d-block">
                      {formError.policies}
                    </div>
                  </div>
                )}
              </Col>
            </Row>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Other Fees </h4>
              </Col>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>convenience fee</Form.Label>
                  <Form.Control
                    name="convenience_fee"
                    type="text"
                    placeholder="Enter convenience fee"
                  />
                  <div className="invalid-feedback d-block">
                    {formError.convenience_fee}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>
                    Commission <small>(in %)</small>
                  </Form.Label>
                  <Form.Control
                    name="commision"
                    type="text"
                    placeholder="Enter Commission "
                  />
                  <div className="invalid-feedback d-block">
                    {formError.commision}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>
                    Platform fee <small>(in %)</small>
                  </Form.Label>
                  <Form.Control
                    name="plateform_fee"
                    type="text"
                    placeholder="Enter Platform fee"
                  />
                  <div className="invalid-feedback d-block">
                    {formError.plateform_fee}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Payment Options</h4>
                <div className="mt-3" key={`inline-checkbox`}>
                  <Form.Check
                    name="partial_payment"
                    className="mycheckbox"
                    label={"Pay 10% now and rest at hotel"}
                    type={"checkbox"}
                    id={`inline-checkbox-${"pay 10% now and rest at hotel"}`}
                  />
                </div>
                <div className="mt-3" key={`inline-checkbox`}>
                  <Form.Check
                    name="pay_at_hotel"
                    className="mycheckbox"
                    label={"book now & pay at hotel"}
                    type={"checkbox"}
                    id={`inline-checkbox-${"book now & pay at hotel"}`}
                  />
                </div>
                <div className="mt-3" key={`inline-checkbox`}>
                  <Form.Check
                    name="pay_online"
                    className="mycheckbox"
                    label={"Book now & pay online"}
                    type={"checkbox"}
                    id={`inline-checkbox-${"Book now & pay online"}`}
                  />
                </div>
                <div className="invalid-feedback d-block">
                  {formError.payment_option}
                </div>
              </Col>
            </Row>

            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default AddHotelPage;
