import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import HotelMap from "./HotelMap";

const NO_LOCATION = "No Location";

function getDistanceFromLatLonInKm(
  lat1: number,
  lon1: number,
  lat2: number,
  lon2: number
): string {
  if (lat1 === 0 || lon1 === 0) {
    return NO_LOCATION;
  }
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(lat2 - lat1); // deg2rad below
  const dLon = deg2rad(lon2 - lon1);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(lat1)) *
      Math.cos(deg2rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d.toFixed(2) + " KM";
}

function deg2rad(deg: number): number {
  return deg * (Math.PI / 180);
}

interface LocationProps {
  lat: string;
  lng: string;
  position: string;
  hotel_lat: string;
  hotel_lng: string;
  status: string;
}

const Location: React.FC<LocationProps> = ({
  lat,
  lng,
  position,
  hotel_lat,
  hotel_lng,
  status,
}) => {
  const [showLocationModel, setShowLocationModel] = useState(false);

  const data = [
    {
      id: 1,
      name: "Hotel Location",
      latitude: hotel_lat,
      longitude: hotel_lng,
      circle: {
        radius: 1000,
        options: {
          strokeColor: "#ff0000",
        },
      },
    },
    {
      id: 1,
      name: "Hotel Location",
      latitude: hotel_lat,
      longitude: hotel_lng,
    },
  ];

  const distance =
    status === "cancelled"
      ? getDistanceFromLatLonInKm(
          Number(lat),
          Number(lng),
          Number(hotel_lat),
          Number(hotel_lng)
        )
      : NO_LOCATION;

  const showLocation = () => {
    if (distance !== NO_LOCATION) {
      setShowLocationModel(true);
    }
  };

  return (
    <div>
      <div
        onClick={showLocation}
        className={`btn ${
          distance === NO_LOCATION ? "btn-primary" : "btn-warning"
        }`}
      >
        {`${distance}`}
      </div>
      <Modal
        size="lg"
        show={showLocationModel}
        onHide={() => setShowLocationModel(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Distance From Hotel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <HotelMap
            center={{ lat: Number(hotel_lat), lng: Number(hotel_lng) }}
            zoom={15}
            places={data}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Location;
