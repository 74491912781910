import React, { useState, useEffect, FormEvent } from "react";
import { Button, Form, Col } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import ReactQuill from "react-quill";
import { Api } from "../../../services/axios.service";

const TermAndConditionPage: React.FC = () => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [termCondition, setTermCondition] = useState<string>("");

  useEffect(() => {
    loadSettings();
  }, []);

  const loadSettings = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    if (token) {
      formData.append("token", token);
    }

    Api.post("Admin/Settings", formData).then((res) => {
      const data = res.data["data"];
      if (res.data["status"] === "success") {
        setTermCondition(data.term_and_condition);
      } else {
        setTermCondition("");
      }
    });
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.currentTarget);
    const token = localStorage.getItem("token");
    if (token) {
      formData.append("token", token);
    }
    formData.append("term_and_condition", termCondition);

    Api.post("Admin/settings/update_term_and_condition", formData).then(
      (res) => {
        if (res.data["status"] === "success") {
          setFormSuccess(res.data["msg"]);
        } else {
          setFormError(res.data["formErrors"]);
        }
      }
    );
  };

  return (
    <>
      <div>
        <CCard>
          <CCardHeader>
            <h4>Terms and Conditions</h4>
          </CCardHeader>
          <CCardBody>
            {formError && <CAlert color="danger">{formError}</CAlert>}
            {formSuccess && <CAlert color="success">{formSuccess}</CAlert>}
            <Form onSubmit={handleSubmit}>
              <Col lg="12">
                <ReactQuill
                  style={{ height: 300, marginTop: 15, paddingBottom: 70 }}
                  value={termCondition}
                  onChange={(text) => setTermCondition(text)}
                />
              </Col>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </Form>
          </CCardBody>
        </CCard>
      </div>
    </>
  );
};

export default TermAndConditionPage;
