import React, { useEffect, useRef, useState } from "react";
import { CAlert, CCard, CCardBody, CCardHeader, CCol } from "@coreui/react";
// import { FCM_SETUP } from "../../Services/FCM";
import { Button, Form, Col, Row } from "react-bootstrap";
import { Multiselect } from "multiselect-react-dropdown";
import parse from "html-react-parser";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";

interface User {
  id: number;
  email: string;
}

function SendNotificationPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [accessToken, setAccessToken] = useState<string | null>(null);
  const [users, setUsers] = useState<User[]>([]);
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);
  const [allUserSelect, setAllUserSelect] = useState<boolean>(true);
  const [image, setImage] = useState<File | string>("");

  const userMultiselectRef = useRef<Multiselect>(null);
  const formRef = useRef<HTMLFormElement>(null);

  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [formLoading, setFormLoading] = useState<boolean>(false);

  useEffect(() => {
    doFCMSetup();
  }, []);

  const doFCMSetup = async () => {
    setLoading(true);
    // const token = await FCM_SETUP();
    await loadUsers();
    setLoading(false);

    setAccessToken("fkjdhfjsjsfkljsgfkjskljs");
    // if (token != null) {
    //   setAccessToken(token);
    // }
  };

  const loadUsers = async () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token as string);
    Api.post("Admin/users", formData).then((res) => {
      if (res.data["status"] === "success") {
        setUsers(res.data["data"]);
      } else {
        console.log("Status fail");
      }
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormLoading(true);

    try {
      setFormError("");
      setFormSuccess("");

      const formData = new FormData(e.currentTarget);
      let token = localStorage.getItem("token");
      formData.append("token", token as string);
      formData.append("selected_users", JSON.stringify(selectedUsers));

      const URL = "Admin/notification/send_notification";
      const res = await Api.post(URL, formData);
      const response = res.data;
      console.log("Res ", response);
      if (response.status === "success") {
        setFormError("");
        setFormSuccess(response.msg);
        if (formRef.current) {
          formRef.current.reset();
        }
        setSelectedUsers([]);
      } else {
        setFormError(response.msg);
        setFormSuccess("");
      }
    } catch (error) {
      console.log("Error ", error);
      setFormError(`Error: ${error}`);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <CCol xs="12" lg="12">
      <CCard>
        <CCardHeader>Send Notification</CCardHeader>

        {formError !== "" && <CAlert color="danger">{parse(formError)}</CAlert>}
        {formSuccess !== "" && (
          <CAlert color="success">{parse(formSuccess)}</CAlert>
        )}

        <CCardBody>
          {loading ? (
            <LoadingComponent />
          ) : accessToken == null ? (
            <Button
              onClick={() => {
                doFCMSetup();
              }}
              variant={"danger"}
            >
              Reset Token
            </Button>
          ) : (
            <Form
              ref={formRef}
              encType={"multipart/form-data"}
              onSubmit={handleSubmit}
            >
              <Row>
                <Col lg={"6"}>
                  <Form.Group>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                      name="title"
                      type="text"
                      placeholder="Enter notification title"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Message</Form.Label>
                    <Form.Control
                      name="message"
                      type="text"
                      placeholder="Enter notification message"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Upload Banner Image (Optional)</Form.Label>
                    <Form.File
                      onChange={(e: any) => setImage(e.target.files[0])}
                      name="image"
                    />
                  </Form.Group>
                </Col>

                <Col lg={"6"}>
                  <Form.Group>
                    <Form.Label>Select User</Form.Label>
                    <div className="custom-control custom-switch mb-4">
                      <input
                        name={"all_user"}
                        onChange={(e) => {
                          console.log("ALL User ", e.target.checked);
                          setAllUserSelect(e.target.checked);
                        }}
                        defaultChecked={true}
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch1"
                      />
                      <label
                        className="custom-control-label font-weight-bold text-black"
                        htmlFor="customSwitch1"
                      >
                        {`Send To All Users (Total - ${users.length})`}
                      </label>
                    </div>
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Select User</Form.Label>
                    <Multiselect
                      ref={userMultiselectRef}
                      options={users}
                      displayValue="email"
                      disable={allUserSelect}
                      placeholder={"Select user"}
                      selectedValues={selectedUsers}
                      onSelect={(selectedList, selectedItem) => {
                        console.log("selectedList", selectedList);
                        console.log("selectedItem", selectedItem);
                        setSelectedUsers(selectedList as User[]);
                      }}
                      onRemove={(selectedList, selectedItem) => {
                        console.log("selectedList", selectedList);
                        console.log("selectedItem", selectedItem);
                        setSelectedUsers(selectedList as User[]);
                      }}
                    />
                    {allUserSelect && (
                      <p>{`Off "Send To All Users" to send a notification to Select Users.`}</p>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Col lg={"12"}>
                <Button disabled={formLoading} variant="primary" type="submit">
                  {formLoading ? "Please wait..." : "Submit"}
                </Button>
              </Col>
            </Form>
          )}
        </CCardBody>
      </CCard>
    </CCol>
  );
}

export default SendNotificationPage;
