import { CAlert, CCard, CCardBody, CCardHeader } from "@coreui/react";
import React, { useEffect, useRef, useState } from "react";
import parse from "html-react-parser";
import {
  Col,
  Form,
  Row,
  Button,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { Api } from "../../../../services/axios.service";
import { msHelper } from "../../../../services/ms-helper.service";

const AppExit: React.FC = () => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [formLoading, setFormLoading] = useState<boolean>(false);
  const [image, setImage] = useState<string>("");

  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const formdata = new FormData();
      let token = localStorage.getItem("token");
      formdata.append("token", token as string);
      const URL = "Admin/notification/get_default_notification/app_access";
      const res = await Api.post(URL, formdata);
      const response = res.data;
      if (response.status === "success") {
        setFormError("");
        setFormSuccess("");
        const data = response.data;
        const form = formRef.current as any;
        form.title.value = data.title;
        form.message.value = data.message;
        if (data.image !== "") {
          setImage(`${msHelper.ImageUrl}notification/${data.image}`);
        }
      } else {
        setFormError(response.msg);
        setFormSuccess("");
      }
    } catch (error) {
      console.log("Error ", error);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormLoading(true);
    try {
      setFormError("");
      setFormSuccess("");
      const formdata = new FormData(e.currentTarget);
      let token = localStorage.getItem("token");
      formdata.append("token", token as string);
      const URL = "Admin/notification/update_default_notification/app_access";
      const res = await Api.post(URL, formdata);
      const response = res.data;
      if (response.status === "success") {
        setFormError("");
        setFormSuccess(response.msg);
      } else {
        setFormError(response.msg);
        setFormSuccess("");
      }
    } catch (error) {
      console.log("Error ", error);
      setFormError(`Error: ${error}`);
    } finally {
      setFormLoading(false);
    }
  };

  return (
    <CCard>
      <CCardHeader>
        {
          "Whenever someone closes the app a notification attached below will be given"
        }
      </CCardHeader>

      {formError !== "" && <CAlert color="danger">{parse(formError)}</CAlert>}
      {formSuccess !== "" && (
        <CAlert color="success">{parse(formSuccess)}</CAlert>
      )}

      <CCardBody>
        <Form
          ref={formRef}
          encType={"multipart/form-data"}
          onSubmit={handleSubmit}
        >
          <Row>
            <Col lg={"12"}>
              <Form.Label>Title</Form.Label>
              <InputGroup className="mb-3">
                <InputGroup.Append>
                  <InputGroup.Text
                    title={
                      "The Username will be attached at the start of the title"
                    }
                    id="basic-addon2"
                  >
                    User name,{" "}
                  </InputGroup.Text>
                </InputGroup.Append>
                <FormControl
                  placeholder="Enter notification title"
                  aria-label="Enter notification title"
                  aria-describedby="basic-addon2"
                  name={"title"}
                />
              </InputGroup>
              <Form.Group>
                <Form.Label>Message</Form.Label>
                <Form.Control
                  name="message"
                  type="text"
                  placeholder="Enter notification message"
                />
              </Form.Group>
              {image !== "" && (
                <img className={"w-100"} src={image} alt="Banner Preview" />
              )}

              <Form.Group>
                <Form.Label>Upload Banner Image</Form.Label>
                <Form.File
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.files && e.target.files[0]) {
                      var tmppath = URL.createObjectURL(e.target.files[0]);
                      setImage(tmppath);
                    }
                  }}
                  name="image"
                />
              </Form.Group>
            </Col>

            <Col lg={"12"}>
              <Button disabled={formLoading} variant="primary" type="submit">
                {formLoading ? "Please wait..." : "Submit"}
              </Button>
            </Col>
          </Row>
        </Form>
      </CCardBody>
    </CCard>
  );
};

export default AppExit;
