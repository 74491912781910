import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
  CAlert,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import parse from "html-react-parser";
import { useNavigate } from "react-router-dom";
import { Api } from "../../services/axios.service";
import useAuth from "../../hooks/auth/useAuth";

const Login: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const navigate = useNavigate();
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    if (isAuthenticated()) {
      navigate("/dashboard");
    } else {
      navigate("/");
    }
  }, [navigate]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === "email") setEmail(value);
    if (name === "password") setPassword(value);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    try {
      e.preventDefault();
      const formdata = new FormData();

      setFormError("");
      setFormSuccess("");

      formdata.append("email", email);
      formdata.append("pass", password);

      const res = await Api.post("Admin/admin_login", formdata);

      if (res.data.status === "success") {
        login(() => {
          const token = res.data["token_id"] + ":" + res.data["token"];
          localStorage.setItem("token", token);
          navigate("/dashboard");
        });
      } else {
        setFormError(res.data["msg"]);
      }
    } catch (error) {
      console.log("error-catch", error);
    }
  };

  return (
    <div className="c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol md="6">
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm onSubmit={handleSubmit}>
                    <h1 className="text-center mb-3">Login</h1>

                    {formError !== "" && (
                      <div>
                        <CAlert color="danger">{parse(formError)}</CAlert>
                      </div>
                    )}
                    {formSuccess !== "" && (
                      <div>
                        <CAlert color="success">{parse(formSuccess)}</CAlert>
                      </div>
                    )}

                    <CInputGroup className="mb-3">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-user" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        name="email"
                        onChange={handleChange}
                        value={email}
                        type="email"
                        placeholder="Email"
                        autoComplete="username"
                      />
                    </CInputGroup>

                    <CInputGroup className="mb-4">
                      <CInputGroupPrepend>
                        <CInputGroupText>
                          <CIcon name="cil-lock-locked" />
                        </CInputGroupText>
                      </CInputGroupPrepend>
                      <CInput
                        onChange={handleChange}
                        value={password}
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs="6">
                        <CButton type="submit" color="primary" className="px-4">
                          Login
                        </CButton>
                      </CCol>
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
