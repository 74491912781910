import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import VendorEditBlock from "../vendors-page/components/VendorEditBlock";
import { VendorData } from "../vendors-page/VendorsPage";

const fields = ["id", "full_name", "email", "mobile", "view_active"];

const InActiveVendorPage: React.FC = () => {
  const [tableData, setTableData] = useState<VendorData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const loadData = () => {
    const formdata = new FormData();
    let token = localStorage.getItem("token");
    formdata.append("token", token || "");
    Api.post("Admin/vendors/inactive_vendors", formdata).then((res) => {
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
      }
    });
  };

  const closeModel = () => {
    // Assuming closeModel functionality is not used in the current component
    // Keeping it as placeholder function for potential future use
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div className="float-left ">{"In-Active Vendors"}</div>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  id: (item: VendorData) => <td>{"#" + item.id}</td>,
                  view_active: (item: VendorData) => (
                    <td>
                      <VendorEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default InActiveVendorPage;
