import React, { useEffect, useState } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";

const fields = [
  "id",
  "vendor_name",
  "hotel_id",
  "hotel_name",
  "subject",
  "description",
  "bookings",
];

const RaiseIssues: React.FC = () => {
  const [Table_data, setTableData] = useState<any[]>([]);
  const [TableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    Api.post("Admin/raise_issues", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setTableData(res.data["data"]);
          setTableLoading(false);
        } else {
          setTableData([]);
          setTableLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error fetching raise issues:", error);
        setTableData([]);
        setTableLoading(false);
      });
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader
            className={"d-flex justify-content-between align-items-center"}
          >
            All Raise Issues
          </CCardHeader>
          <CCardBody>
            {TableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={Table_data}
                fields={fields}
                tableFilter
                pagination
                itemsPerPage={10}
                scopedSlots={{
                  id: (item: any) => <td>{item.id}</td>,
                  hotel_id: (item: any) => <td>#{item.hotel_id}</td>,
                  bookings: (item: any) => (
                    <td>
                      {JSON.parse(item.bookings).map(
                        (item: string, index: number) => (
                          <span key={index}>#{item}, </span>
                        )
                      )}
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default RaiseIssues;
