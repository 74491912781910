import React, { useCallback, useEffect, useState } from "react";
import {
  CBadge,
  CDropdown,
  CDropdownItem,
  CDropdownMenu,
  CDropdownToggle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";

import { Link } from "react-router-dom";
import ApiRequest from "../../../../../services/ApiRequest.service";
import { API_URLS } from "../../../../../services/api-urls.service";

const HeaderNotification = () => {
  const [requestCount, setRequestCount] = useState(0);
  const [requestsData, setRequestsData] = useState<any[]>([]);

  const getRequestCount = useCallback(() => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "null");
    formData.append("status", "pending");

    ApiRequest({
      url: API_URLS.GET_PRICE_UPDATE_REQUESTS,
      values: formData,
      onSuccessCallback: (data) => {
        setRequestCount(data.data.length);
      },
      onError: () => {
        setRequestCount(0);
      },
    });
  }, []);

  const getRequests = useCallback(() => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("status", "pending");
    formData.append("limit", "5");

    ApiRequest({
      url: API_URLS.GET_PRICE_UPDATE_REQUESTS,
      values: formData,
      onSuccessCallback: (data) => {
        setRequestsData(data.data);
      },
      onError: () => {
        setRequestsData([]);
      },
    });
  }, []);

  useEffect(() => {
    getRequestCount();
    getRequests();
  }, [getRequestCount, getRequests]);

  return (
    <CDropdown inNav className="c-header-nav-item mx-2">
      <CDropdownToggle className="c-header-nav-link" caret={false}>
        <CIcon name="cil-bell" />
        <CBadge shape="pill" color="danger">
          {requestCount}
        </CBadge>
      </CDropdownToggle>
      <CDropdownMenu placement="bottom-end" className="pt-0">
        {requestsData.length > 0 &&
          requestsData.map((request, index) => {
            return (
              <Link
                className={"dropdown-item"}
                key={index.toString()}
                to={`/hotels/room-price-requests`}
              >
                <div className="message">
                  <div>
                    <small className="text-muted">{request.vendor_name}</small>
                  </div>
                  <div className="text small">
                    New Price : ₹ {request.new_price}/-
                  </div>
                </div>
              </Link>
            );
          })}

        <CDropdownItem className="text-center border-top">
          <Link to="/hotels/room-price-requests">
            <strong>View all requests</strong>
          </Link>
        </CDropdownItem>
      </CDropdownMenu>
    </CDropdown>
  );
};

export default HeaderNotification;
