import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import EditRefundStatusModel from "./components/EditRefundStatusModel";

const fields = [
  "id",
  "full_name",
  "mobile",
  "status",
  "note",
  "action",
  "booking",
];

interface RefundRequestPageProps {}

interface Refund {
  id: number;
  full_name: string;
  mobile: string;
  status: string;
  note: string;
  booking_id: number;
}

const RefundRequestPage: React.FC<RefundRequestPageProps> = (props) => {
  const [tableData, setTableData] = useState<Refund[]>([]);
  const [editData, setEditData] = useState<Refund | null>(null);
  const [showModel, setShowModel] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const formData = new FormData();
    const token = localStorage.getItem("token") ?? "";
    formData.append("token", token);

    try {
      const res = await Api.post("Admin/refund", formData);
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error loading data:", error);
      setTableData([]);
    } finally {
      setTableLoading(false);
    }
  };

  const closeModel = () => {
    setShowModel(false);
  };

  const filterPropertyByStatus = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const status = e.target.value;

    if (status === "") {
      loadData();
      return;
    }

    const formData = new FormData();
    const token = localStorage.getItem("token") ?? "";
    formData.append("token", token);
    formData.append("status", status);

    try {
      const res = await Api.post("Admin/refund/get_refund_by_status", formData);
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
    } catch (error) {
      console.error("Error loading data by status:", error);
      setTableData([]);
    } finally {
      setTableLoading(false);
    }
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader className="d-flex justify-content-between align-items-center">
            All Refund Request
            <div className="filter_Form">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  name="property_filter"
                  onChange={filterPropertyByStatus}
                  as="select"
                >
                  <option value="">--- Select Status ---</option>
                  <option value="requested">Requested</option>
                  <option value="partially refunded">Partially Refunded</option>
                  <option value="success">Success</option>
                  <option value="cancelled">Cancelled</option>
                </Form.Control>
              </Form.Group>
            </div>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  id: (item: Refund) => <td>{"#" + item.id}</td>,
                  status: (item: Refund) => (
                    <td>
                      <span
                        className={
                          item.status === "success"
                            ? "badge badge-success"
                            : item.status === "cancelled"
                            ? "badge badge-danger"
                            : item.status === "partially refunded"
                            ? "badge badge-info"
                            : "badge badge-warning"
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                  ),
                  action: (item: Refund) => (
                    <td>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            setEditData(item);
                            setShowModel(true);
                          }}
                          variant="success"
                        >
                          <FontAwesomeIcon icon={faEdit as any} />
                        </Button>
                      </ButtonGroup>
                    </td>
                  ),
                  booking: (item: Refund) => (
                    <td>
                      <ButtonGroup>
                        <Link
                          to={`/view-booking-detail/${item.booking_id}`}
                          className="btn btn-primary"
                        >
                          <FontAwesomeIcon icon={faEye as any} />
                        </Link>
                      </ButtonGroup>
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
      {editData && (
        <EditRefundStatusModel
          data={editData}
          show={showModel}
          closeModel={closeModel}
          refreshList={loadData}
          {...props}
        />
      )}
    </div>
  );
};

export default RefundRequestPage;
