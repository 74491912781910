import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Api } from "../../services/axios.service";

const ViewUserPage = () => {
  const navigate = useNavigate();
  const id = useParams();

  const [userData, setUserData] = useState<any>("");
  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", id?.["id"] ?? "");

    Api.post("Admin/users/get_user", formData).then((res) => {
      if (res.data["status"] === "success") {
        setUserData(res.data["data"]);
      } else {
        alert(res.data["msg"]);
      }
    });
  };
  useEffect(loadData);
  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div
            onClick={() => navigate(-1)}
            className="mr-4"
            style={{ display: "inline-block" }}
          >
            <FontAwesomeIcon icon={faArrowLeft as any} />
          </div>
          <h4 style={{ fontSize: 18, display: "inline-block" }}>
            User Details
          </h4>
        </div>
        <div className="container-fluid my-4">
          <div className="row">
            <div className="col-lg-6">
              <Table striped bordered hover>
                <tr>
                  <th>Full Name</th>
                  <td>{userData?.full_name}</td>
                </tr>
                <tr>
                  <th>Mobile</th>
                  <td>{userData?.mobile}</td>
                </tr>
                <tr>
                  <th>Email</th>
                  <td>{userData?.email}</td>
                </tr>
                <tr>
                  <th>DOB</th>
                  <td>{userData?.dob}</td>
                </tr>
              </Table>
            </div>
            <div className="col-lg-6">
              <Table striped bordered hover>
                <tr>
                  <th>Verified</th>
                  <td>{userData?.verify_number}</td>
                </tr>
                <tr>
                  <th>Active</th>
                  <td>{userData?.active}</td>
                </tr>
                <tr>
                  <th>Last Expairy Date</th>
                  <td>{userData?.last_expiry_date}</td>
                </tr>
                <tr>
                  <th>Profile Image</th>
                  <td>{userData?.profile_pic}</td>
                </tr>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ViewUserPage;
