import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEyeSlash, faEye } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../services/axios.service";
import EditRoomCateModal from "./EditRoomCateModal";

interface RoomCateEditBlockProps {
  item: {
    id: number;
    active: string;
  };
  refreshList: () => void;
}

const RoomCateEditBlock: React.FC<RoomCateEditBlockProps> = ({
  item,
  refreshList,
}) => {
  const [editData, setEditData] = useState<any | null>(null);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");

  const closeModel = () => {
    setShowModel(false);
  };

  const activeInactive = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token as string);
    formData.append("id", id.toString());

    Api.post("Admin/categories/active_inactive_room_cate", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          refreshList();
        } else {
          setFormError(res.data["msg"]);
          console.log(res.data);
        }
      })
      .catch((error) => {
        console.error("Error toggling active status:", error);
        setFormError("An error occurred while toggling active status.");
      });
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Button
          variant="primary"
          onClick={() => {
            setEditData(item);
            setShowModel(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit as any} />
        </Button>
        <Button
          variant="success"
          onClick={() => {
            activeInactive(item.id);
          }}
        >
          <FontAwesomeIcon
            icon={(item.active === "0" ? faEyeSlash : faEye) as any}
          />
        </Button>
      </ButtonGroup>

      {editData && (
        <EditRoomCateModal
          data={editData}
          show={showModel}
          closeModel={closeModel}
          refreshList={refreshList}
        />
      )}
    </>
  );
};

export default RoomCateEditBlock;
