import React, { useState } from "react";
import { ButtonGroup, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../../services/axios.service";
import EditOfferModal from "./EditOfferModal";

interface OffersEditBlockProps {
  item: {
    id: number;
    // Define other properties of your item here
  };
  refreshList: () => void;
}

const OffersEditBlock: React.FC<OffersEditBlockProps> = ({
  item,
  refreshList,
}) => {
  const [editData, setEditData] = useState<any>(null);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [formError, setFormError] = useState<string>("");

  const closeModel = () => {
    setShowModel(false);
  };

  const deleteItem = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", id.toString());

    Api.post("Admin/offers/delete", formData).then((res) => {
      if (res.data["status"] === "success") {
        refreshList();
        alert(res.data["msg"]);
      } else {
        setFormError(res.data["msg"]);
        console.log(res.data);
      }
    });
  };

  return (
    <>
      <ButtonGroup className="mr-2" aria-label="First group">
        <Button
          variant="primary"
          onClick={() => {
            setEditData(item);
            setShowModel(true);
          }}
        >
          <FontAwesomeIcon icon={faEdit as any} />
        </Button>
        <Button
          onClick={() => deleteItem(item.id)}
          className="delete-btn"
          variant="danger"
        >
          <FontAwesomeIcon icon={faTrash as any} />
        </Button>
      </ButtonGroup>
      {editData && (
        <EditOfferModal
          data={editData}
          show={showModel}
          closeModel={closeModel}
          refreshList={refreshList}
          // Include other props as needed
        />
      )}
    </>
  );
};

export default OffersEditBlock;
