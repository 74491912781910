import React from "react";

const LoadingComponent = () => {
  return (
    <div
      className={
        "d-flex flex-column justify-content-center align-items-center my-5"
      }
    >
      <div className="spinner-grow my-3" role="status">
        <span className="sr-only">Loading...</span>
      </div>
      <h4>Data Loading......</h4>
    </div>
  );
};

export default LoadingComponent;
