import React, { useEffect, useState, ChangeEvent } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import moment from "moment";
import { Form } from "react-bootstrap";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";
import { msHelper } from "../../../services/ms-helper.service";
import BookedRoomsEditBlock from "../components/BookedRoomsEditBlock";

const fields = [
  "booking_id",
  "user_name",
  "hotel_name",
  "hotel_id",
  "checkin_date",
  "checkout_date",
  "Booking_date",
  "status",
  "view_status",
];

interface Booking {
  order_id: string;
  user_name: string;
  full_name: string;
  hotel_name: string;
  hotel_id: string;
  checkin_date: string;
  checkout_date: string;
  created_at: string;
  status: string;
}

interface Vendor {
  id: string;
  full_name: string;
}

const AllAppBookingsPage: React.FC = () => {
  const [tableData, setTableData] = useState<Booking[]>([]);
  const [vendorList, setVendorList] = useState<Vendor[]>([]);
  const [loadingVendor, setLoadingVendor] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
    getVendorsList();
  }, []);

  const loadData = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);

    Api.post("Admin/Booked_room/app_bookings", formData).then((res) => {
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    });
  };

  const getVendorsList = () => {
    setLoadingVendor(true);
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);

    Api.post("Admin/vendors", formData)
      .then((res) => {
        if (res.data.status === "success") {
          setVendorList(res.data.data);
        } else {
          setVendorList([]);
        }
        setLoadingVendor(false);
      })
      .catch((err) => {
        console.log("Vendor Api Error", err);
      });
  };

  const filterVendorBooking = (e: ChangeEvent<HTMLSelectElement>) => {
    setTableLoading(true);
    const vendor_id = e.target.value;

    if (vendor_id === "") {
      loadData();
      return;
    }
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);
    formData.append("vendor_id", vendor_id);

    Api.post("Admin/Booked_room/get_vendor_booked_rooms", formData).then(
      (res) => {
        if (res.data.status === "success") {
          setTableData(res.data.data);
        } else {
          setTableData([]);
        }
        setTableLoading(false);
      }
    );
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader className="d-flex justify-content-between align-items-center">
            Silver Stays Booking
            <div className="filter_Form">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  name="vendor_filter"
                  onChange={filterVendorBooking}
                  as="select"
                >
                  <option value="">--- Select Vendor ---</option>
                  {loadingVendor ? (
                    <option>--- Loading..... ---</option>
                  ) : (
                    vendorList.map((list, index) => (
                      <option key={index.toString()} value={list.id}>
                        {list.full_name}
                      </option>
                    ))
                  )}
                </Form.Control>
              </Form.Group>
            </div>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                tableFilter
                pagination
                itemsPerPage={10}
                scopedSlots={{
                  booking_id: (item: Booking) => <td>{"#" + item.order_id}</td>,
                  user_name: (item: Booking) => (
                    <td>{item.user_name || item.full_name}</td>
                  ),
                  hotel_id: (item: Booking) => (
                    <td>{"#" + msHelper.PadNumber(item.hotel_id, 4)}</td>
                  ),
                  Booking_date: (item: Booking) => (
                    <td>{moment(item.created_at).format("YYYY-MM-DD")}</td>
                  ),
                  status: (item: Booking) => (
                    <td>
                      <span
                        className={
                          item.status === "booked"
                            ? "badge badge-success"
                            : item.status === "cancelled"
                            ? "badge badge-danger"
                            : item.status === "pending"
                            ? "badge badge-warning"
                            : "badge badge-secondary"
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                  ),
                  view_status: (item: Booking) => (
                    <td>
                      <BookedRoomsEditBlock
                        refreshList={loadData}
                        item={item}
                      />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default AllAppBookingsPage;
