import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";

interface CoupleFriendlyProps {
  couple_friendly: boolean; // Adjust type according to your data type
}

const CoupleFriendly: React.FC<CoupleFriendlyProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(false);
  const { couple_friendly } = props;

  useEffect(() => {
    const coupleFriendlyNumber = Number(couple_friendly);
    console.log("Number(couple_friendly)", coupleFriendlyNumber);

    if (coupleFriendlyNumber) {
      console.log("Set True");
      setChecked(true);
    } else {
      console.log("Set False");
      setChecked(false);
    }
  }, [couple_friendly]);

  const handleCheckboxChange = () => {
    setChecked(!checked);
  };

  return (
    <Form.Check
      name="couple_friendly"
      className="mycheckbox"
      value={checked.toString()}
      inline
      label={`Couple Friendly`}
      type={"checkbox"}
      checked={checked}
      onChange={handleCheckboxChange}
      id={`inline-checkbox-couple-friendly`}
    />
  );
};

export default CoupleFriendly;
