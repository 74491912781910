import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { Room } from "../PriceModalPage";

interface Props {
  item: Room;
  allCheckboxArray: string[];
  onSelect: (e: React.ChangeEvent<HTMLInputElement>, item: Room) => void;
}

const HotelCheckBox: React.FC<Props> = ({
  item,
  allCheckboxArray,
  onSelect,
}) => {
  const [check, setCheck] = useState(false);

  useEffect(() => {
    const find = allCheckboxArray.find((room) => room === item.id);
    if (find === undefined) {
      setCheck(false);
    } else {
      setCheck(true);
    }
  }, [item, allCheckboxArray]);

  return (
    <div className="m-0" key={`inline-checkbox`}>
      <Form.Check
        name="room_id"
        className="mycheckbox"
        value={item.id}
        inline
        label={item.hotel_name}
        type={"checkbox"}
        checked={check}
        id={`inline-checkbox-${item.id}`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          setCheck(e.target.checked);
          onSelect(e, item);
        }}
      />
    </div>
  );
};

export default HotelCheckBox;
