import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { Modal, Form, Button, Col } from "react-bootstrap";

import { CAlert } from "@coreui/react";
import { Link, useNavigate } from "react-router-dom";
import { Api } from "../../../services/axios.service";
import { msHelper } from "../../../services/ms-helper.service";
import ROOMSFLOOR from "../../../services/ROOMSFLOOR";
import RoomsFloorCheckbox from "./RoomsFloorCheckbox";

interface AddRoomModalProps {
  show: boolean;
  closeModel: () => void;
  data: {
    id: string;
    vendor_id: string;
  };
}

const AddRoomModal: React.FC<AddRoomModalProps> = ({
  show,
  closeModel,
  data,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [hotel_id] = useState<string>(data.id);
  const [vendor_id] = useState<string>(data.vendor_id);
  const [categories_list, setCategories_list] = useState<any[]>([]);
  const [room_cate_id, setRoom_cate_id] = useState<string>("");
  const [roomFound, setRoomFound] = useState<boolean>(false);
  const [room_id, setRoom_id] = useState<string>("");
  const [otherFormFields, setOtherFormFields] = useState<boolean>(false);
  const [FloorSelectError, setFloorSelectError] = useState<boolean>(false);
  const [hotel_data, setHotel_data] = useState<any>("");
  const [ROOM_FLOORS, setROOM_FLOORS] = useState<any[]>(ROOMSFLOOR);
  const navigate = useNavigate();

  useEffect(() => {
    loadRoomCate();
  }, []);

  const loadRoomCate = async () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token!);
    const res = await Api.post("Admin/categories/all_room_category", formData);
    if (res.data["status"] === "success") {
      setCategories_list(res.data["data"]);
    } else {
      setCategories_list([]);
    }
  };

  const checkAddedRoom = async (e: ChangeEvent<HTMLSelectElement>) => {
    const Room_cate_id = e.target.value;

    if (Room_cate_id === "") {
      setOtherFormFields(false);
      setRoomFound(false);
      return;
    }

    setRoom_cate_id(Room_cate_id);
    const formData = new FormData();
    let token = localStorage.getItem("token");

    formData.append("token", token!);
    formData.append("hotel_id", data.id);
    formData.append("category_id", Room_cate_id);

    const res = await Api.post("Admin/rooms/check_added_rooms", formData);
    if (res.data["hotel"].room_floors !== "") {
      setROOM_FLOORS(JSON.parse(res.data["hotel"].room_floors));
    }
    if (res.data["status"] === "success") {
      const data = res.data["data"];
      setRoom_id(data.id);
      setRoomFound(true);
      setOtherFormFields(false);
      setHotel_data(res.data["hotel"]);
    } else {
      setRoomFound(false);
      setOtherFormFields(true);
      setHotel_data(res.data["hotel"]);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFormError("");
    setFormSuccess("");
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.target as HTMLFormElement);

    let onechecked = false;
    let numberOFtotalRooms = 0;
    if ((e.target as any).room_cate_id.value !== "") {
      ROOM_FLOORS.forEach((item, index) => {
        if (item["cat_checked_" + (e.target as any).room_cate_id.value]) {
          onechecked = true;
          numberOFtotalRooms += msHelper.roomCount(
            item,
            (e.target as any).room_cate_id.value
          );
        }
      });
    }

    if (!onechecked) {
      setFloorSelectError(true);
      return;
    }
    setFloorSelectError(false);
    let token = localStorage.getItem("token");

    formData.append("token", token!);
    formData.append("hotel_id", hotel_id);
    formData.append("vendor_id", vendor_id);
    formData.append("total_rooms", numberOFtotalRooms.toString());
    formData.append("room_cate_id", (e.target as any).room_cate_id.value);
    formData.append("room_floors", JSON.stringify(ROOM_FLOORS));

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    const res = await Api.post("Admin/rooms/add", formData, config);
    if (res.data["status"] === "success") {
      setFormSuccess(res.data["msg"]);
      navigate(`/room/edit/${res.data["room_id"]}`, { replace: true });
      setFormError("");
      setFormSuccess("");
      setRoomFound(false);
      setRoom_id("");
      setROOM_FLOORS(ROOMSFLOOR);
    } else {
      setFormError(res.data["formErrors"]);
    }
  };

  return (
    <div>
      <Modal
        size={"lg"}
        show={show}
        onHide={() => {
          setRoom_id("");
          setRoomFound(false);
          setOtherFormFields(false);
          closeModel();
        }}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Room</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSuccess !== "" ? (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          ) : (
            <div />
          )}
          <Form encType={"multipart/form-data"} onSubmit={handleSubmit}>
            <Form.Row>
              <Col lg="12">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Room Category</Form.Label>
                  <Form.Control
                    onChange={checkAddedRoom}
                    name="room_cate_id"
                    as="select"
                    custom
                  >
                    <option value="" selected={true}>
                      --- Select Category ---
                    </option>
                    {categories_list.map((list, index) => (
                      <option key={index.toString()} value={list.id}>
                        {list.category}
                      </option>
                    ))}
                  </Form.Control>
                  <div className="invalid-feedback d-block">
                    {formError && (formError as any).room_cate_id}
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
            {otherFormFields && (
              <Form.Row>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Min. Price</Form.Label>
                    <Form.Control
                      name="min_price"
                      type="text"
                      placeholder="Enter min price"
                    />
                    <div className="invalid-feedback d-block">
                      {formError && (formError as any).min_price}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="6">
                  <Form.Group>
                    <Form.Label>Max Price</Form.Label>
                    <Form.Control
                      name="max_price"
                      type="text"
                      placeholder="Enter max price"
                    />
                    <div className="invalid-feedback d-block">
                      {formError && (formError as any).max_price}
                    </div>
                  </Form.Group>
                </Col>
                <Col lg="12">
                  <div className="mt-3">
                    {ROOM_FLOORS.map((list, index) => (
                      <div
                        key={index.toString()}
                        className="FloorCheckbox shadow-sm"
                      >
                        <RoomsFloorCheckbox
                          FloorData={ROOM_FLOORS}
                          floor={list}
                          floorIndex={index}
                          key={index.toString()}
                          room_cate_id={room_cate_id}
                        />
                      </div>
                    ))}
                    {FloorSelectError && (
                      <div
                        style={{ fontSize: 16 }}
                        className="invalid-feedback d-block my-3"
                      >
                        {"Please Select Hotel Floor"}
                      </div>
                    )}
                  </div>
                </Col>
              </Form.Row>
            )}
            {roomFound ? (
              <Link
                to={`/room/edit/${room_id}`}
                className="btn btn-primary"
                type="submit"
              >
                Edit Room
              </Link>
            ) : (
              <Button variant="primary" type="submit">
                Add Room
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddRoomModal;
