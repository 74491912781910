import React, { forwardRef, useImperativeHandle, useState } from "react";
import { Modal, Button, Alert } from "react-bootstrap";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import ApiRequest from "../../../services/ApiRequest.service";
import { API_URLS } from "../../../services/api-urls.service";
import TextField from "../../form-components/TextField";
import SelectField from "../../form-components/SelectField";

interface ModalProps {
  loadData: () => void;
}

const PriceRequestFormInitialValues = {
  actual_price: "",
  min_price: "",
  max_price: "",
  status: "",
  cut_off_price: "",
};

const digitsOnly = (value: any) => /^\d+$/.test(value);

const validationSchema = Yup.object().shape({
  status: Yup.string().required(),
  actual_price: Yup.string().required("Field is Required"),
  min_price: Yup.string().when("status", {
    is: "approved",
    then: Yup.string()
      .required("Field is Required")
      .test("Digits only", "The field should have digits only", digitsOnly),
  }),
  max_price: Yup.string().when("status", {
    is: "approved",
    then: Yup.string()
      .required("Field is Required")
      .test("Digits only", "The field should have digits only", digitsOnly),
  }),
  cut_off_price: Yup.string().when("status", {
    is: "approved",
    then: Yup.string()
      .required("Field is Required")
      .test("Digits only", "The field should have digits only", digitsOnly),
  }),
});

const PriceRequestUpdateModal = forwardRef<any, ModalProps>((_props, ref) => {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState<any>();
  const [show, setShow] = useState(false);

  const toggleModal = () => {
    setSuccess("");
    setError("");
    setShow(!show);
  };

  useImperativeHandle(
    ref,
    () => ({
      updateData: (modalData: any) => setData(modalData),
      openModal: () => setShow(true),
    }),
    []
  );

  const handleFormSubmit = (values: any, resetForm: () => void) => {
    setSuccess("");
    setError("");

    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("actual_price", values.actual_price);
    formData.append("min_price", values.min_price);
    formData.append("max_price", values.max_price);
    formData.append("status", values.status);
    formData.append("cut_off_price", values.cut_off_price);
    formData.append("roomId", data?.room_id);
    formData.append("reqId", data?.id);

    ApiRequest({
      url: API_URLS.UPDATE_ROOM_PRICE_UPDATE_REQUEST,
      values: formData,
      loading: setLoading,
      onSuccessCallback: (res) => {
        setSuccess(res.msg);
        _props.loadData();
        resetForm();
        toggleModal();
      },
      onError: (res: any) => {
        setError(res?.msg);
      },
    });
  };

  return (
    <Modal show={show} onHide={toggleModal} keyboard={false}>
      <Modal.Header closeButton>
        <Modal.Title>Update Room Price Request</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {success && <Alert variant="success">{success}</Alert>}
        {error && <Alert variant="danger">{error}</Alert>}

        <Formik
          enableReinitialize={true}
          validationSchema={validationSchema}
          initialValues={{
            ...PriceRequestFormInitialValues,
            status: data?.status,
            actual_price: data?.new_price,
          }}
          onSubmit={(values, { resetForm }) =>
            handleFormSubmit(values, resetForm)
          }
        >
          {(formProps) => {
            const { values } = formProps;
            return (
              <Form onSubmit={formProps.handleSubmit}>
                <SelectField
                  formProps={formProps}
                  label="Status"
                  name="status"
                  options={[
                    { label: "Approve ", value: "approved" },
                    { label: "Reject", value: "rejected" },
                  ]}
                  selectLabel="label"
                  selectValue="value"
                />
                <TextField
                  disabled={true}
                  formProps={formProps}
                  label="Actual Price"
                  name="actual_price"
                />
                {values.status === "approved" && (
                  <>
                    <TextField
                      formProps={formProps}
                      label="Min Price"
                      name="min_price"
                    />
                    <TextField
                      formProps={formProps}
                      label="Max Price"
                      name="max_price"
                    />
                    <TextField
                      formProps={formProps}
                      label="Cut Off Price"
                      name="cut_off_price"
                    />
                  </>
                )}
                <Button disabled={loading} variant="primary" type="submit">
                  {loading ? "Wait...." : "Update"}
                </Button>
              </Form>
            );
          }}
        </Formik>
      </Modal.Body>
    </Modal>
  );
});

export default PriceRequestUpdateModal;
