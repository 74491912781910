import { useState, useCallback } from "react";

const useAuth = () => {
  const [authenticated, setAuthenticated] = useState<boolean>(() => {
    const token = localStorage.getItem("token");
    return token != null;
  });

  const login = useCallback((cb: () => void) => {
    setAuthenticated(true);
    cb();
  }, []);

  const logout = useCallback((cb: () => void) => {
    localStorage.removeItem("token");
    setAuthenticated(false);
    cb();
  }, []);

  const isAuthenticated = useCallback(() => {
    return authenticated;
  }, [authenticated]);

  return {
    login,
    logout,
    isAuthenticated,
  };
};

export default useAuth;
