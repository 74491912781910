import React, { useEffect, useState } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Image } from "react-bootstrap";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";
import { msHelper } from "../../../services/ms-helper.service";
import HomeCitiesEditBlock from "../components/HomeCitiesEditBlock";
import AddHomeCityModal from "../components/AddHomeCityModal";

const fields = ["id", "city_img", "city_name", "action"];

const AllCitiesPage = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [showCityModel, setShowCityModel] = useState(false);
  const [tableLoading, setTableLoading] = useState(true);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    Api.post("Admin/app_home/all_home_cities", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setTableData(res.data["data"]);
          setTableLoading(false);
        } else {
          setTableData([]);
          setTableLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error loading data:", error);
        setTableData([]);
        setTableLoading(false);
      });
  };

  const closeCityModel = () => {
    setShowCityModel(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            All Cities
            <button
              onClick={() => {
                setShowCityModel(true);
              }}
              className="btn btn-success float-right"
            >
              Add City <FontAwesomeIcon icon={faPlus as any} />
            </button>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  city_img: (item: any) => (
                    <td>
                      <div>
                        <Image
                          style={{ width: 40 }}
                          src={`${msHelper.ImageUrl}/cities/${item.city_img}`}
                        />
                      </div>
                    </td>
                  ),
                  action: (item: any) => (
                    <td>
                      <HomeCitiesEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>

      <AddHomeCityModal
        show={showCityModel}
        closeModel={closeCityModel}
        refreshList={loadData}
      />
    </div>
  );
};

export default AllCitiesPage;
