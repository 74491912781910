import React, { useState, useEffect } from "react";
import { Button, Form, Col, Row } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";
import { useParams } from "react-router-dom";

interface CouponData {
  title: string;
  sub_title: string;
  coupon_code: string;
  discount_type: string;
  coupon_appied_for: string;
  coupon_amount: string;
  expiry_date: string;
  minimum_spend: string;
  maximum_spend: string;
  usage_limit_coupon: string;
  usage_limit_user: string;
  up_to_limit: string;
  editData: string;
}

const EditCouponPage = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [formError, setFormError] = useState<any>("");
  const [formSuccess, setFormSuccess] = useState("");
  const [couponData, setCouponData] = useState<CouponData>({
    title: "",
    sub_title: "",
    coupon_code: "",
    discount_type: "",
    coupon_appied_for: "",
    coupon_amount: "",
    expiry_date: "",
    minimum_spend: "",
    maximum_spend: "",
    usage_limit_coupon: "",
    usage_limit_user: "",
    up_to_limit: "",
    editData: "",
  });

  useEffect(() => {
    loadCouponData();
  }, []);

  const loadCouponData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    let id = params.id ?? "";

    formData.append("token", token || "");
    formData.append("id", id);

    Api.post("Admin/coupon/edit", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          let editData = res.data["data"];
          setCouponData({
            ...editData,
            discount_type: editData.discount_type,
          });
        } else {
          setCouponData({
            ...couponData,
            editData: "",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleOnSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setCouponData({ ...couponData, discount_type: e.target.value });
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponData({ ...couponData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.currentTarget);
    let token = localStorage.getItem("token");
    let id = params.id ?? "";

    formData.append("token", token || "");
    formData.append("id", id);

    if (couponData.discount_type === "percentage") {
      formData.append("up_to_limit", couponData.up_to_limit);
    }

    try {
      const res = await Api.post("Admin/coupon/update", formData);
      if (res.data["status"] === "success") {
        window.scrollTo(0, 0);
        setFormSuccess(res.data["msg"]);
      } else {
        setFormError(res.data["formErrors"]);
      }
    } catch (error) {
      console.error("Error updating coupon:", error);
      setFormError("An error occurred while updating the coupon.");
    }
  };

  return (
    <div>
      <CCard>
        <CCardHeader>
          <h4>Edit Coupon</h4>
        </CCardHeader>
        <CCardBody>
          {formSuccess !== "" && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form encType={"multipart/form-data"} onSubmit={handleSubmit}>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Coupon Information </h4>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder="Enter coupon title"
                    defaultValue={couponData.title}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.title}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Sub title</Form.Label>
                  <Form.Control
                    name="sub_title"
                    type="text"
                    placeholder="Enter coupon subtitle"
                    defaultValue={couponData.sub_title}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.sub_title}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="4">
                <Form.Group>
                  <Form.Label>Coupon Applied For</Form.Label>
                  <Form.Control
                    name="coupon_appied_for"
                    as="select"
                    custom
                    value={couponData.coupon_appied_for}
                    onChange={handleChange}
                  >
                    <option value="">--- select ---</option>
                    <option value="pay_at_hotel">Pay At Hotel</option>
                    <option value="online">Online</option>
                    <option value="both">Both</option>
                  </Form.Control>
                  <div className="invalid-feedback d-block">
                    {formError?.coupon_appied_for}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="4">
                <Form.Group>
                  <Form.Label>Coupon Code</Form.Label>
                  <Form.Control
                    name="coupon_code"
                    type="text"
                    placeholder="Enter coupon code"
                    defaultValue={couponData.coupon_code}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.coupon_code}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="4">
                <Form.Group>
                  <Form.Label>Discount Type</Form.Label>
                  <Form.Control
                    name="discount_type"
                    as="select"
                    custom
                    value={couponData.discount_type}
                    onChange={handleOnSelect}
                  >
                    <option value="">--- select Type ---</option>
                    <option value="percentage">Percentage</option>
                    <option value="fixed">Fixed</option>
                  </Form.Control>

                  <div className="invalid-feedback d-block">
                    {formError?.discount_type}
                  </div>
                </Form.Group>
                {couponData.discount_type === "percentage" && (
                  <Form.Group>
                    <small>up to limit</small>
                    <Form.Control
                      name="up_to_limit"
                      type="text"
                      placeholder="Enter Limit"
                      defaultValue={couponData.up_to_limit}
                      onChange={handleChange}
                    />
                    <div className="invalid-feedback d-block">
                      {formError?.up_to_limit}
                    </div>
                  </Form.Group>
                )}
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Coupon Amount</Form.Label>
                  <Form.Control
                    name="coupon_amount"
                    type="text"
                    placeholder="Enter Amount"
                    defaultValue={couponData.coupon_amount}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.coupon_amount}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Coupon Expiry Date</Form.Label>
                  <Form.Control
                    name="expiry_date"
                    type="date"
                    defaultValue={couponData.expiry_date}
                    placeholder="Enter coupon code"
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.expiry_date}
                  </div>
                </Form.Group>
              </Col>
            </Row>
            <Row className="form-sections">
              <Col lg="12">
                <h4>Usage Restriction </h4>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Minimum Spend</Form.Label>
                  <Form.Control
                    name="minimum_spend"
                    type="text"
                    placeholder="Enter Minimum Spend"
                    defaultValue={couponData.minimum_spend}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.minimum_spend}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Maximum Spend </Form.Label>
                  <Form.Control
                    name="maximum_spend"
                    type="text"
                    placeholder="Enter Maximum Spend"
                    defaultValue={couponData.maximum_spend}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.maximum_spend}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Row className="form-sections">
              <Col lg="12">
                <h4>Usage Limits</h4>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Usage limit per coupon</Form.Label>
                  <Form.Control
                    name="usage_limit_coupon"
                    type="text"
                    placeholder="Usage limit per coupon"
                    defaultValue={couponData.usage_limit_coupon}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.usage_limit_coupon}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Usage limit per user</Form.Label>
                  <Form.Control
                    name="usage_limit_user"
                    type="text"
                    placeholder="Enter Usage limit per user"
                    defaultValue={couponData.usage_limit_user}
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.usage_limit_user}
                  </div>
                </Form.Group>
              </Col>
            </Row>

            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default EditCouponPage;
