import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Api } from "../../services/axios.service";
import { msHelper } from "../../services/ms-helper.service";

interface propertyData {
  property_name: string;
  full_name: string;
  mobile: string;
  email: string;
  state: string;
  city: string;
  pincode: string;
  address: string;
  street?: string;
  status: string;
  agreementFile?: string;
  propertyImages?: string[];
}

const ViewPropertyDetailsPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [propertyData, setPropertyData] = useState<propertyData | null>(null);
  const [loading, setLoading] = useState(true);

  const loadData = useCallback(() => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("id", id ?? "");

    Api.post("Admin/properties/get_property_details", formData).then((res) => {
      if (res.data["status"] === "success") {
        setPropertyData(res.data["data"]);
        setLoading(false);
      } else {
        alert(res.data["msg"]);
      }
    });
  }, [id]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const propertyImages: string[] = useMemo(() => {
    if (propertyData && propertyData.propertyImages) {
      return JSON.parse(propertyData?.propertyImages?.toString());
    }
    return [];
  }, [propertyData]);

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div
            onClick={() => navigate(-1)}
            className="mr-4"
            style={{ display: "inline-block", cursor: "pointer" }}
          >
            <FontAwesomeIcon icon={faArrowLeft as any} />
          </div>
          <h4 style={{ fontSize: 18, display: "inline-block" }}>
            Property Details
          </h4>
        </div>

        {loading ? (
          <div className=" my-5 text-center">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container-fluid my-4">
            <div className="row">
              <div className="col-lg-12">
                <h4 style={{ fontSize: 20 }}>Property Details</h4>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Name Of Property</th>
                      <td>{propertyData?.property_name}</td>
                    </tr>
                    <tr>
                      <th>Full Name</th>
                      <td>{propertyData?.full_name}</td>
                    </tr>
                    <tr>
                      <th>Mobile</th>
                      <td>{propertyData?.mobile}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{propertyData?.email}</td>
                    </tr>
                    <tr>
                      <th>State</th>
                      <td>{propertyData?.state}</td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>{propertyData?.city}</td>
                    </tr>
                    <tr>
                      <th>Pincode</th>
                      <td>{propertyData?.pincode}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{propertyData?.address}</td>
                    </tr>
                    <tr>
                      <th>Street</th>
                      <td>{propertyData?.street}</td>
                    </tr>
                    <tr>
                      <th>Status</th>
                      <td>{propertyData?.status}</td>
                    </tr>
                    {propertyData?.agreementFile && (
                      <tr>
                        <th>Agreement Doc</th>
                        <td>
                          <a
                            download
                            href={
                              msHelper.ImageUrl +
                              "property_images/" +
                              propertyData?.agreementFile
                            }
                            target="_blank"
                            rel="noreferrer"
                          >
                            Download Doc
                          </a>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
              {propertyData?.propertyImages && (
                <div className="col-lg-12">
                  <h4 style={{ fontSize: 20 }}>Property Images</h4>
                  <div className="property-img-block">
                    {propertyImages.map((img, index) => (
                      <div key={index} className="property-single-img-block">
                        <img
                          style={{ width: "100%" }}
                          src={msHelper.ImageUrl + "property_images/" + img}
                          alt=""
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewPropertyDetailsPage;
