import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";
import RoomsEditBlock from "../components/RoomsEditBlock";
import { useParams } from "react-router-dom";

const fields = ["id", "hotel_name", "room_category", "action_active"];

interface Room {
  id: string;
  hotel_name: string;
  room_category: string;
  active: string;
}

const AllRoomsPage = () => {
  const params = useParams();
  const [tableData, setTableData] = useState<Room[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    const id = params.id ?? "";

    formData.append("token", token ?? "");
    formData.append("id", id);

    Api.post("Admin/rooms", formData).then((res) => {
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    });
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>All Rooms</CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  action_active: (item: Room) => (
                    <td>
                      <RoomsEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default AllRoomsPage;
