import React, { useEffect, useState } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faWallet } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import AddVendorModal from "./components/AddVendorModal";
import VendorEditBlock from "./components/VendorEditBlock";

const fields = [
  "vendor_id",
  "full_name",
  "email",
  "silver_email_id",
  "mobile",
  "referrals",
  "edit_view_active",
];

export interface VendorData {
  id: number;
  vendor_id: string | null;
  full_name: string;
  email: string;
  silver_email_id: string;
  mobile: string;
  referrals: string;
  edit_view_active: boolean;
  active: string;
}

const VendorsPage = () => {
  const [tableData, setTableData] = useState<VendorData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);
  const [showModel, setShowModel] = useState<boolean>(false);

  const loadData = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");

    Api.post("Admin/vendors", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          console.log("Vendors ", res.data["data"]);
          setTableData(res.data["data"]);
        } else {
          setTableData([]);
        }
        setTableLoading(false);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  useEffect(() => {
    loadData();
  }, []);

  const closeModel = () => {
    setShowModel(false);
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            <div className="float-left">{"All Vendors"}</div>
            <div
              onClick={() => setShowModel(true)}
              className="btn btn-primary float-right"
            >
              {"Add Vendor"}
              <FontAwesomeIcon className="ml-2" icon={faPlus as any} />
            </div>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                columnFilter
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  vendor_id: (item: VendorData) => (
                    <th>
                      {item.vendor_id == null ? (
                        <small className="text-danger font-weight-bold">
                          {"No Hotel"}
                        </small>
                      ) : (
                        item.vendor_id
                      )}
                    </th>
                  ),
                  referrals: (item: VendorData) => (
                    <th>
                      <Link
                        className="btn btn-primary"
                        to={`/vendor/referrals/${item.id}`}
                      >
                        <FontAwesomeIcon icon={faWallet as any} />
                      </Link>
                    </th>
                  ),
                  edit_view_active: (item: VendorData) => (
                    <td>
                      <VendorEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
      <AddVendorModal
        refreshList={loadData}
        show={showModel}
        closeModel={closeModel}
      />
    </div>
  );
};

export default VendorsPage;
