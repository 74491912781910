import React from "react";

import {
  CCreateElement,
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavDivider,
  CSidebarNavTitle,
  CSidebarMinimizer,
  CSidebarNavDropdown,
  CSidebarNavItem,
} from "@coreui/react";
import { navigationJson } from "../_nav";
import { useSideDrawerStore } from "../../../context/side-drawer-context/SideDrawer.context";

const Sidebar = () => {
  const { show, toggleDrawer } = useSideDrawerStore();

  return (
    <CSidebar show={show} onShowChange={(val: any) => toggleDrawer(val)}>
      <CSidebarBrand className="d-md-down-none" to="/">
        <h4>Admin</h4>
      </CSidebarBrand>
      <CSidebarNav>
        <CCreateElement
          items={navigationJson}
          components={{
            CSidebarNavDivider,
            CSidebarNavDropdown,
            CSidebarNavItem,
            CSidebarNavTitle,
          }}
        />
      </CSidebarNav>
      <CSidebarMinimizer className="c-d-md-down-none" />
    </CSidebar>
  );
};

export default React.memo(Sidebar);
