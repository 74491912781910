import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import UserEditBlock from "./components/UserEditBlock";

const fields = ["id", "full_name", "email", "mobile", "view_active"];

export interface User {
  id: number;
  full_name: string;
  email: string;
  mobile: string;
  view_active: boolean;
  active: string;
}

const UsersPage = () => {
  const [tableData, setTableData] = useState<User[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    try {
      const response = await Api.post("Admin/users", formData);
      if (response.data.status === "success") {
        setTableData(response.data.data);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    } catch (error) {
      console.error("Error loading data", error);
      setTableLoading(false);
    }
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>All Users</CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                tableFilter
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  id: (item: User) => <td>{"#" + item.id}</td>,
                  view_active: (item: User) => (
                    <td>
                      <UserEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default UsersPage;
