import React from "react";
import { Form } from "react-bootstrap";
import { FormikValues, FormikHandlers } from "formik";

interface TextFieldProps {
  formProps: {
    errors: FormikValues;
    touched: FormikValues;
    values: FormikValues;
    handleBlur: FormikHandlers["handleBlur"];
    handleChange: FormikHandlers["handleChange"];
  };
  name: string;
  label: string;
  disabled?: boolean;
}

const TextField: React.FC<TextFieldProps> = ({
  formProps,
  name,
  label,
  disabled,
}) => {
  const { errors, touched, values, handleBlur, handleChange } = formProps;

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        onBlur={handleBlur}
        disabled={disabled}
        value={values[name]}
        onChange={handleChange}
        name={name}
        type="text"
        required
      />
      {errors[name] && touched[name] && (
        <Form.Text className="text-danger">{errors[name]}</Form.Text>
      )}
    </Form.Group>
  );
};

export default TextField;
