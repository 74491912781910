import React, { useEffect, useState } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import { Api } from "../../services/axios.service";
import UserEditBlock from "../users-page/components/UserEditBlock";

const fields = ["id", "full_name", "email", "mobile", "view_active"];

interface UserData {
  id: number;
  full_name: string;
  email: string;
  mobile: string;
  view_active: boolean;
  active: string;
}

const InActiveUsersPage: React.FC = () => {
  const [tableData, setTableData] = useState<UserData[]>([]);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const loadData = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");

    Api.post("Admin/users/inactive_users", formData).then((res) => {
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>All In-Active Users</CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  id: (item: UserData) => <td>{"#" + item.id}</td>,
                  view_active: (item: UserData) => (
                    <td>
                      <UserEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
    </div>
  );
};

export default InActiveUsersPage;
