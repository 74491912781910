import React, { useState } from "react";
import { Modal, Form, Button, Col } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

interface EditPropertyStatusModalProps {
  show: boolean;
  closeModel: () => void;
  data: {
    id: number;
    status: string;
    note: string;
  };
  refreshList: () => void;
}

const EditPropertyStatusModal: React.FC<EditPropertyStatusModalProps> = ({
  show,
  closeModel,
  data,
  refreshList,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormError("");
    setFormSuccess("");
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    let token = localStorage.getItem("token");
    let propertyId = data.id;

    formData.append("token", token || "");
    formData.append("property_id", propertyId.toString());
    formData.append("status", e.currentTarget.status.value);
    formData.append("note", e.currentTarget.note.value);

    Api.post("Admin/properties/update_status", formData).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        refreshList();
        setTimeout(() => {
          closeModel();
          setFormError("");
          setFormSuccess("");
          setLoading(false);
        }, 1000);
      } else {
        setFormError(res.data["formErrors"]);
        setLoading(false);
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={closeModel} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Update Property Status</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSuccess !== "" && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form encType="multipart/form-data" onSubmit={handleSubmit}>
            <Form.Row>
              <Col lg="12">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Status</Form.Label>
                  <Form.Control
                    name="status"
                    as="select"
                    custom
                    defaultValue={data.status}
                  >
                    <option value="">Select Status</option>
                    <option value="pending">Pending</option>
                    <option value="cancelled">Cancelled</option>
                    <option value="approved">Approved</option>
                  </Form.Control>
                  <div className="invalid-feedback d-block">{formError}</div>
                </Form.Group>
                <Form.Group controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Note:</Form.Label>
                  <Form.Control
                    defaultValue={data.note}
                    required
                    name="note"
                    as="textarea"
                    rows={3}
                  />
                  <div className="invalid-feedback d-block">{formError}</div>
                </Form.Group>
              </Col>
            </Form.Row>

            {loading ? (
              <Button variant="primary" type="button">
                Please Wait....
              </Button>
            ) : (
              <Button variant="primary" type="submit">
                Update
              </Button>
            )}
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditPropertyStatusModal;
