import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHotel,
  faPlus,
  faToggleOff,
  faToggleOn,
} from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Api } from "../../../services/axios.service";
import LoadingComponent from "../../../container/loading-component/LoadingComponent";
import { msHelper } from "../../../services/ms-helper.service";
import AddRoomModal from "../components/AddRoomModal";
import HotelEditBlock from "../components/HotelEditBlock";

const fields = [
  "hotel_id",
  "vendor_name",
  "hotel_name",
  "address",
  "actions",
  "add_room",
  "view_room",
  "show_on_home",
];

const AllHotelsPage: React.FC = () => {
  const [Table_data, setTableData] = useState<any[]>([]);
  const [editData, setEditData] = useState<any>(null);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [TableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    try {
      const res = await Api.post("Admin/Hotel", formData);
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
      }
    } catch (error) {
      console.error("Error fetching hotels:", error);
      setTableData([]);
      setTableLoading(false);
    }
  };

  const showOnHome = (id: number) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("id", id.toString());

    Api.post("Admin/hotel/showonoff_home", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          loadData();
        } else {
          console.log(res.data);
        }
      })
      .catch((error) => {
        console.error("Error showing on home:", error);
      });
  };

  const closeModel = () => {
    setShowModel(false);
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>All Hotels</CCardHeader>
          <CCardBody>
            {TableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={Table_data}
                fields={fields}
                striped
                sorter
                itemsPerPage={10}
                tableFilter
                pagination
                scopedSlots={{
                  hotel_id: (item: any) => (
                    <td>{"#" + msHelper.PadNumber(item.id, 4)}</td>
                  ),
                  actions: (item: any) => (
                    <td>
                      <HotelEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                  add_room: (item: any) => (
                    <td>
                      <Button
                        onClick={() => {
                          setEditData(item);
                          setShowModel(true);
                        }}
                        variant="success"
                      >
                        <FontAwesomeIcon icon={faPlus as any} />
                      </Button>
                    </td>
                  ),

                  view_room: (item: any) => (
                    <td>
                      <Link
                        to={`/rooms/${item.id}`}
                        className="btn btn-primary"
                      >
                        <FontAwesomeIcon icon={faHotel as any} />
                      </Link>
                    </td>
                  ),
                  show_on_home: (item: any) => (
                    <td>
                      <Button
                        variant="link"
                        onClick={() => {
                          showOnHome(item.id);
                        }}
                      >
                        <FontAwesomeIcon
                          size={"lg"}
                          color={
                            Number(item.on_home) === 0 ? "#6c757d" : "#007bff"
                          }
                          icon={
                            (Number(item.on_home) === 0
                              ? faToggleOff
                              : faToggleOn) as any
                          }
                        />
                      </Button>
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
      {editData != null && (
        <AddRoomModal
          data={editData}
          show={showModel}
          closeModel={closeModel}
        />
      )}
    </div>
  );
};

export default AllHotelsPage;
