import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { Button, ButtonGroup, Form } from "react-bootstrap";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import EditPropertyStatusModal from "./components/EditPropertyStatusModal";

interface Property {
  id: number;
  full_name: string;
  mobile: string;
  status: string;
  note: string;
}

const fields = ["id", "full_name", "mobile", "status", "note", "action"];

const PropertyListPage = () => {
  const [tableData, setTableData] = useState<Property[]>([]);
  const [editData, setEditData] = useState<Property | null>(null);
  const [showModel, setShowModel] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  const loadData = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token || "");

    Api.post("Admin/properties", formData).then((res) => {
      if (res.data["status"] === "success") {
        setTableData(res.data["data"]);
        setTableLoading(false);
      } else {
        setTableData([]);
        setTableLoading(false);
      }
    });
  };

  const closeModel = () => {
    setShowModel(false);
  };

  const filterPropertyByStatus = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const status = e.target.value;

    if (status === "") {
      loadData();
      return;
    }

    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token || "");
    formData.append("status", status);

    Api.post("Admin/properties/get_property_by_status", formData).then(
      (res) => {
        if (res.data["status"] === "success") {
          setTableData(res.data["data"]);
          setTableLoading(false);
        } else {
          setTableData([]);
          setTableLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader
            className={"d-flex justify-content-between align-items-center"}
          >
            All Properties
            <div className="filter_Form">
              <Form.Group controlId="exampleForm.ControlSelect1">
                <Form.Control
                  name="property_filter"
                  onChange={filterPropertyByStatus}
                  as="select"
                >
                  <option value="">--- Select Status ---</option>
                  <option value="pending">Pending</option>
                  <option value="cancelled">Cancelled</option>
                  <option value="approved">Approved</option>
                </Form.Control>
              </Form.Group>
            </div>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  id: (item: Property) => <td>{"#" + item.id}</td>,
                  status: (item: Property) => (
                    <td>
                      <span
                        className={
                          item.status === "approved"
                            ? "badge badge-success"
                            : item.status === "cancelled"
                            ? "badge badge-danger"
                            : "badge badge-warning"
                        }
                      >
                        {item.status}
                      </span>
                    </td>
                  ),
                  action: (item: Property) => (
                    <td>
                      <ButtonGroup>
                        <Button
                          onClick={() => {
                            setEditData(item);
                            setShowModel(true);
                          }}
                          variant="success"
                        >
                          <FontAwesomeIcon icon={faEdit as any} />
                        </Button>
                        <Link
                          to={`/view-property-detail/${item.id}`}
                          className={"btn btn-primary"}
                        >
                          <FontAwesomeIcon icon={faEye as any} />
                        </Link>
                      </ButtonGroup>
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>
      {editData && (
        <EditPropertyStatusModal
          data={editData}
          show={showModel}
          closeModel={closeModel}
          refreshList={loadData}
        />
      )}
    </div>
  );
};

export default PropertyListPage;
