import React, { useState, useEffect, ChangeEvent, FormEvent } from "react";
import { Button, Form, Col, Spinner } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import { msHelper } from "../../../services/ms-helper.service";
import { Api } from "../../../services/axios.service";
import { useParams } from "react-router-dom";
import AddHotelImages from "../components/AddHotelImages";
import AmenitiesCheckBox from "../components/AmenitiesCheckBox";
import EditRoomsFloorCheckBox from "../components/EditRoomsFloorCheckBox";

interface Category {
  id: string;
  category: string;
}

interface Amenity {
  id: string;
  name: string;
}

interface Floor {
  [key: string]: boolean | string;
}

const EditRoomPage = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const [pageError, setPageError] = useState(false);
  const [formError, setFormError] = useState<any>({});
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [categoriesList, setCategoriesList] = useState<Category[]>([]);
  const [roomData, setRoomData] = useState<any | null>(null);
  const [amenityData, setAmenityData] = useState<Amenity[]>([]);
  const [roomFloors, setRoomFloors] = useState<Floor[]>([]);
  const [oneChecked, setOneChecked] = useState(false);
  const [roomCateId, setRoomCateId] = useState<string>("");

  useEffect(() => {
    loadRoom();
    loadRoomCate();
    loadAmenities();
  }, []);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setRoomData((prev: any) =>
      prev ? { ...prev, [e.target.name]: e.target.value } : null
    );
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.currentTarget);

    let oneChecked = false;
    let numberOfTotalRooms = 0;
    if (roomCateId !== "") {
      roomFloors.forEach((item) => {
        if (item["cat_checked_" + roomCateId]) {
          oneChecked = true;
          numberOfTotalRooms += msHelper.roomCount(
            item as any,
            Number(roomCateId ?? "0")
          );
        }
      });
    }

    if (!oneChecked) {
      setFormError({ FloorSelectError: true });
      return;
    }
    setFormError({ FloorSelectError: false });

    const token = localStorage.getItem("token");
    const id = params.id ?? "";
    formData.append("token", token ?? "");

    const allAmenitiesArray = e.currentTarget.amenities;
    for (let i = 0; i < allAmenitiesArray.length; i++) {
      const item = allAmenitiesArray[i];
      if (item.checked) {
        formData.append("amenities[]", item.value);
      }
    }

    formData.append("id", id);
    formData.append("room_cate_id", e.currentTarget.room_cate_id.value);

    if (roomData) {
      formData.append("hotel_id", roomData.hotel_id);
      formData.append("total_rooms", numberOfTotalRooms.toString());
      formData.append("min_price", e.currentTarget.min_price.value);
      formData.append("max_price", e.currentTarget.max_price.value);
      formData.append("cut_off_price", e.currentTarget.cut_off_price.value);
      formData.append("actual_price", e.currentTarget.actual_price.value);
      formData.append("room_floors", JSON.stringify(roomFloors));
    }

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/rooms/update", formData, config).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        window.scrollTo(0, 0);
      } else {
        setFormError(res.data["formErrors"]);
      }
    });
  };

  const loadRoom = () => {
    setLoading(true);
    setPageError(false);
    const formData = new FormData();
    const token = localStorage.getItem("token");
    const id = params.id ?? "";
    formData.append("token", token ?? "");
    formData.append("id", id);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    Api.post("Admin/rooms/get_room", formData, config)
      .then((res) => {
        if (res.data["status"] === "success") {
          const data = res.data["data"];
          setRoomData(data);
          setRoomFloors(JSON.parse(data.room_floors));
          setRoomCateId(data.room_cate_id);
          setLoading(false);
        } else {
          setPageError(true);
          setLoading(false);
        }
      })
      .catch(() => {
        setLoading(false);
        setPageError(true);
      });
  };

  const loadRoomCate = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");
    Api.post("Admin/categories/all_room_category", formData).then((res) => {
      if (res.data["status"] === "success") {
        setCategoriesList(res.data["data"]);
      } else {
        setCategoriesList([]);
      }
    });
  };

  const loadAmenities = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token");
    formData.append("token", token || "");
    Api.post("Admin/amenities", formData).then((res) => {
      if (res.data["status"] === "success") {
        setAmenityData(res.data["data"]);
      } else {
        setAmenityData([]);
      }
    });
  };

  if (loading) {
    return (
      <div
        style={{
          height: 300,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <div
          style={{ width: "3em", height: "3em" }}
          className="spinner-grow"
          role="status"
        >
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  }

  if (pageError) {
    return (
      <div
        style={{
          height: 300,
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        <h3>OOPS! Something went wrong. Please try again.</h3>
      </div>
    );
  }

  if (!roomData) {
    return null;
  }

  return (
    <div>
      <CCard>
        <CCardHeader>
          <h4>Edit Room</h4>
        </CCardHeader>
        <CCardBody>
          {formSuccess && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form onSubmit={handleSubmit} encType={"multipart/form-data"}>
            <Form.Row>
              <Col lg="12">
                <p>
                  <b>Hotel Name:</b> {roomData.hotel_name}
                </p>
                <p>
                  <b>Room Category:</b> {roomData.room_category}
                </p>
              </Col>
              <Col lg="12">
                <Form.Group
                  className={"d-none"}
                  controlId="exampleForm.SelectCustom"
                >
                  <Form.Label>Room Category</Form.Label>
                  <Form.Control name="room_cate_id" as="select" custom>
                    <option value="">--- Select Category ---</option>
                    {categoriesList.map((list, index) => (
                      <option
                        selected={roomData.room_cate_id === list.id}
                        key={index.toString()}
                        value={list.id}
                      >
                        {list.category}
                      </option>
                    ))}
                  </Form.Control>
                  {formError.room_cate_id && (
                    <div className="invalid-feedback d-block">
                      {formError.room_cate_id}
                    </div>
                  )}
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Min. Price</Form.Label>
                  <Form.Control
                    name="min_price"
                    type="text"
                    placeholder="Enter min price"
                    value={roomData.min_price}
                    onChange={handleChange}
                  />
                  {formError.min_price && (
                    <div className="invalid-feedback d-block">
                      {formError.min_price}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Max Price</Form.Label>
                  <Form.Control
                    name="max_price"
                    type="text"
                    placeholder="Enter max price"
                    value={roomData.max_price}
                    onChange={handleChange}
                  />
                  {formError.max_price && (
                    <div className="invalid-feedback d-block">
                      {formError.max_price}
                    </div>
                  )}
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Actual Price</Form.Label>
                  <Form.Control
                    name="actual_price"
                    type="text"
                    placeholder="Enter actual price"
                    value={roomData.actual_price}
                    onChange={handleChange}
                  />
                  {formError.actual_price && (
                    <div className="invalid-feedback d-block">
                      {formError.actual_price}
                    </div>
                  )}
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Cut Off Price</Form.Label>
                  <Form.Control
                    name="cut_off_price"
                    type="text"
                    placeholder="Enter cut off price"
                    value={roomData.cut_off_price}
                    onChange={handleChange}
                  />
                  {formError.cut_off_price && (
                    <div className="invalid-feedback d-block">
                      {formError.cut_off_price}
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col lg="12" className={"my-3"}>
                <h4>Select Amenities </h4>

                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <div className="mt-3" key={`inline-checkbox`}>
                    {amenityData.map((list, index) => (
                      <AmenitiesCheckBox
                        key={index.toString()}
                        amenity={list as any}
                        amenities={roomData.amenities}
                      />
                    ))}
                    {formError.amenities && (
                      <div className="invalid-feedback d-block">
                        {formError.amenities}
                      </div>
                    )}
                  </div>
                )}
              </Col>
            </Form.Row>

            <Form.Row>
              <Col lg="12" className={"my-3"}>
                <h4>Select Floors</h4>

                {loading ? (
                  <div className="text-center">
                    <Spinner animation="border" />
                  </div>
                ) : (
                  <Form.Row>
                    <div
                      className="mt-3 EditFloorBlock"
                      key={`inline-checkbox`}
                    >
                      {roomFloors.length > 0 &&
                        roomFloors.map((list, index) => (
                          <Col lg="6" key={index.toString()}>
                            <div className="FloorCheckbox shadow-sm">
                              <EditRoomsFloorCheckBox
                                room_cate_id={roomData.room_cate_id}
                                floor={list}
                                floorIndex={index}
                                key={index.toString()}
                                FloorData={roomFloors}
                              />
                            </div>
                          </Col>
                        ))}

                      {formError.FloorSelectError && (
                        <div
                          style={{ fontSize: 16 }}
                          className="invalid-feedback d-block my-3"
                        >
                          {"Please Select Hotel Floor"}
                        </div>
                      )}
                    </div>
                  </Form.Row>
                )}
              </Col>
            </Form.Row>

            <Button variant="primary" type="submit">
              Update Room
            </Button>
          </Form>

          <div>
            <AddHotelImages roomId={roomData.id} hotelId={roomData.hotel_id} />
          </div>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default EditRoomPage;
