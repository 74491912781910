import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Modal, Row } from "react-bootstrap";
import { Api } from "../../../../services/axios.service";
import ButtonLoading from "../../../../container/button-loading/ButtonLoading";
import HotelImageBlock from "./HotelImageBlock";

interface AddHotelImagesModalProps {
  hotel_id: string; // Adjust type as per your actual hotel_id type
}

const AddHotelImagesModal: React.FC<AddHotelImagesModalProps> = (props) => {
  const [selectedFiles, setselectedFiles] = useState<any[]>([]); // Adjust type as per your API response
  const [tag, setTag] = useState<string>("");
  const [formError, setFormError] = useState<string>("");
  const [success, setSuccess] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [selectFile, setSelectFile] = useState<File | null>(null);
  const [imageModel, setImageModel] = useState<boolean>(false);
  const [FormLoading, setFormLoading] = useState<boolean>(false);
  const filRef = useRef<HTMLInputElement>(null);

  const onFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      setSelectFile(event.target.files[0]);
    }
  };

  useEffect(() => {
    getRoomImages();
  }, [props]);

  const uploadFileToServer = () => {
    setFormLoading(true);
    if (selectFile == null) {
      alert("Please Select Hotel Image");
      setFormLoading(false);
      return;
    }

    setFormError("");
    setSuccess("");
    setError("");

    const formdata = new FormData();
    let token = localStorage.getItem("token");

    if (token) {
      formdata.append("token", token);
    }
    formdata.append("hotel_id", props.hotel_id);
    formdata.append("tag", tag);
    formdata.append("image", selectFile);

    let confige = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/hotel/add_hotel_image", formdata, confige)
      .then((res) => {
        setFormLoading(false);
        if (res.data["status"] === "success") {
          setSelectFile(null);
          if (filRef.current) {
            filRef.current.value = "";
          }
          setSuccess(res.data["msg"]);
          setTag("");
          getRoomImages();
          setFormError("");
        } else {
          if (res.data["form_error"] === 0) {
            setError(res.data["msg"]);
            setFormError("");
          } else {
            setFormError(res.data["formErrors"]);
          }
        }
      })
      .catch((error) => {
        if (filRef.current) {
          filRef.current.value = "";
        }
        console.log(error);
      });
  };

  const getRoomImages = () => {
    const formdata = new FormData();
    let token = localStorage.getItem("token");
    if (token) {
      formdata.append("token", token);
    }
    formdata.append("id", props.hotel_id);

    Api.post("Admin/hotel/get_hotel_images", formdata)
      .then((res) => {
        if (res.data["status"] === "success") {
          setselectedFiles(res.data["data"]);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <div className={"py-2"}>
        <Button
          onClick={() => setImageModel(true)}
          className={"btn btn-primary"}
        >
          ADD HOTEL IMAGE
        </Button>
        <div className={"mt-3"}>
          <Row>
            {selectedFiles.map((image, index) => (
              <Col key={index.toString()} lg="3">
                <HotelImageBlock
                  key={index.toString()}
                  image={image}
                  getRoomImages={getRoomImages}
                />
              </Col>
            ))}
          </Row>
        </div>
      </div>
      <Modal
        show={imageModel}
        onHide={() => setImageModel(false)}
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Image To Hotel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {success !== "" && (
            <div className={"alert alert-success"} role="alert">
              {success}
            </div>
          )}
          {error !== "" && (
            <div className={"alert alert-error"} role="alert">
              {error}
            </div>
          )}

          <div className="form-group">
            <label>Select Hotel Image</label>
            <input
              onChange={onFileChange}
              ref={filRef}
              type="file"
              className="form-control-file"
            />
          </div>
          {selectFile != null && (
            <div className={"mb-3"}>
              <img
                alt="test"
                style={{ width: "100%" }}
                className={"img-thumbnail"}
                src={URL.createObjectURL(selectFile)}
              />
            </div>
          )}
          <div className="form-group">
            <label>Add Tag</label>
            <input
              className="form-control"
              onChange={(e) => {
                setTag(e.target.value);
              }}
              type="text"
              placeholder="Add Tag Name"
            />
            {formError !== "" && (
              <div className="invalid-feedback d-block">{formError}</div>
            )}
          </div>

          <div onClick={() => uploadFileToServer()}>
            <ButtonLoading
              type="button"
              title="ADD IMAGE"
              loading={FormLoading}
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AddHotelImagesModal;
