import React, { useState, FormEvent } from "react";
import { Button, Form, Col, Modal } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

interface AddPolicyModalProps {
  show: boolean;
  closeModel: () => void;
  refreshList: () => void;
}

const AddPolicyModal: React.FC<AddPolicyModalProps> = ({
  show,
  closeModel,
  refreshList,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.currentTarget);
    let token = localStorage.getItem("token");

    if (token) {
      formData.append("token", token);
    }
    formData.append("policy", e.currentTarget.policy.value);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/policy/add", formData, config).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        refreshList();
        setTimeout(() => {
          closeModel();
          setFormError("");
          setFormSuccess("");
        }, 1000);
      } else {
        setFormError(res.data["msg"]);
        console.log(res.data);
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={closeModel} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && <CAlert color="danger">{formError}</CAlert>}
          {formSuccess && <CAlert color="success">{formSuccess}</CAlert>}
          <Form encType={"multipart/form-data"} onSubmit={handleSubmit}>
            <Form.Row>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Policy & Rules</Form.Label>
                  <Form.Control
                    name="policy"
                    type="text"
                    placeholder="Enter Policy & Rules"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Button variant="primary" type="submit">
              Add Policy
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddPolicyModal;
