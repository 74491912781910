import React, { useState, useEffect } from "react";
import { CCol, CCard, CCardHeader, CCardBody, CDataTable } from "@coreui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Api } from "../../services/axios.service";
import LoadingComponent from "../../container/loading-component/LoadingComponent";
import AddPolicyModal from "./components/AddPolicyModal";
import PolicyEditBlock from "./components/PolicyEditBlock";

const fields = ["id", "policy", "action_active"];

const AllPolicyPage = () => {
  const [tableData, setTableData] = useState<any[]>([]);
  const [editData, setEditData] = useState<any>(null);
  const [showAddModel, setShowAddModel] = useState<boolean>(false);
  const [tableLoading, setTableLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    if (token) {
      formData.append("token", token);
      const res = await Api.post("Admin/policy", formData);
      if (res.data.status === "success") {
        setTableData(res.data.data);
      } else {
        setTableData([]);
      }
      setTableLoading(false);
    }
  };

  const closeAddModel = () => {
    setShowAddModel(false);
  };

  return (
    <div>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            All Policies
            <button
              onClick={() => setShowAddModel(true)}
              className="btn btn-success float-right"
            >
              Add Policy <FontAwesomeIcon icon={faPlus as any} />
            </button>
          </CCardHeader>
          <CCardBody>
            {tableLoading ? (
              <LoadingComponent />
            ) : (
              <CDataTable
                items={tableData}
                fields={fields}
                striped
                itemsPerPage={10}
                pagination
                scopedSlots={{
                  action_active: (item: any) => (
                    <td>
                      <PolicyEditBlock refreshList={loadData} item={item} />
                    </td>
                  ),
                }}
              />
            )}
          </CCardBody>
        </CCard>
      </CCol>

      <AddPolicyModal
        show={showAddModel}
        closeModel={closeAddModel}
        refreshList={loadData}
      />
    </div>
  );
};

export default AllPolicyPage;
