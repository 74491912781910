import React, { useCallback, useEffect, useState } from "react";
import { CCard, CCardBody, CCardHeader, CCol, CDataTable } from "@coreui/react";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import { Api, formDataHelper } from "../../../services/axios.service";
import { API_URLS } from "../../../services/api-urls.service";
import { msHelper } from "../../../services/ms-helper.service";
import RemoveButton from "../components/RemoveButton";
import SelectHotelModel from "../components/SelectHotelModel";

const fields = ["hotel_id", "vendor_name", "hotel_name", "address", "remove"];

interface Hotel {
  id: number;
  vendor_name: string;
  hotel_name: string;
  address: string;
}

export const togglePopularHotel = async (hotelId: number) => {
  const formData = formDataHelper();
  formData.append("id", hotelId.toString());
  return Api.post(API_URLS.UPDATE_POPULAR_HOTEL, formData);
};

const PopularHotelsPage = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [hotels, setHotels] = useState<Hotel[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);

  const loadActiveHotels = useCallback(async () => {
    const formData = formDataHelper();
    const filterArray = { popular: 1 };
    formData.append("filter", JSON.stringify(filterArray));
    setLoading(true);
    try {
      const res = await Api.post(API_URLS.GET_HOTELS, formData);
      if (res.data["status"] === "success") {
        setHotels(res.data["data"]);
      }
    } catch (error) {
      console.error("Error loading hotels:", error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadActiveHotels();
  }, [loadActiveHotels]);

  return (
    <React.Fragment>
      <CCol xs="12" lg="12">
        <CCard>
          <CCardHeader>
            Popular Hotels
            <button
              onClick={() => setShowModal(true)}
              className="btn btn-success float-right"
            >
              Add Popular Hotel <FontAwesomeIcon icon={faPlus as any} />
            </button>
          </CCardHeader>
          <CCardBody>
            <CDataTable
              items={hotels}
              fields={fields}
              loading={loading}
              striped
              itemsPerPage={10}
              tableFilter
              pagination
              scopedSlots={{
                hotel_id: (item: any) => (
                  <td>{"#" + msHelper.PadNumber(item.id, 4)}</td>
                ),
                remove: (item: any) => (
                  <td>
                    <RemoveButton
                      hotelId={item.id}
                      loadHotels={loadActiveHotels}
                    />
                  </td>
                ),
              }}
            />
          </CCardBody>
        </CCard>
      </CCol>
      <Modal size="lg" onHide={() => setShowModal(false)} show={showModal}>
        <div className="p-3">
          <SelectHotelModel refreshList={loadActiveHotels} />
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default PopularHotelsPage;
