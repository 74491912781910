import React, { useState } from "react";
import { Button, Form, Col } from "react-bootstrap";
import { CCard, CCardHeader, CCardBody, CAlert } from "@coreui/react";
import parse from "html-react-parser";
import { Api } from "../../../services/axios.service";

const AddAminitiesPage = () => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [icon, setIcon] = useState<File | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.currentTarget);
    const token = localStorage.getItem("token");

    if (token) {
      formData.append("token", token);
    }

    formData.append("amenity", formData.get("amenity") as string);
    formData.append("icon", icon as File);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    try {
      const res = await Api.post("Admin/Amenities/add", formData, config);
      if (res.data.status === "success") {
        setFormSuccess(res.data.msg);
        (e.currentTarget as HTMLFormElement).reset();
      } else {
        setFormError(res.data.msg);
        console.log(res.data);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      setFormError("An error occurred while submitting the form.");
    }
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setIcon(e.target.files[0]);
    }
  };

  return (
    <div>
      <CCard>
        <CCardHeader>
          <h4>Add Amenity</h4>
        </CCardHeader>
        <CCardBody>
          {formError && (
            <div>
              <CAlert color="danger">{parse(formError)}</CAlert>
            </div>
          )}
          {formSuccess && (
            <div>
              <CAlert color="success">{parse(formSuccess)}</CAlert>
            </div>
          )}
          <Form onSubmit={handleSubmit}>
            <Form.Row>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Amenity Name</Form.Label>
                  <Form.Control
                    name="amenity"
                    type="text"
                    placeholder="Enter amenity name"
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Select Icon</Form.Label>
                  <Form.File onChange={onChange} name="icon" />
                </Form.Group>
              </Col>
            </Form.Row>
            <Button variant="primary" type="submit">
              Submit
            </Button>
          </Form>
        </CCardBody>
      </CCard>
    </div>
  );
};

export default AddAminitiesPage;
