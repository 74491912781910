import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { Api } from "../../../services/axios.service";

interface BookingData {
  full_name: string;
  user_name: string;
  mobile: string;
  user_mobile: string;
  user_email: string;
  current_room_price: number;
  order_id: string;
  hotel_name: string;
  guest: string;
  rooms: string;
  checkin_date: string;
  checkout_date: string;
  room_price: number;
  price_drop: number;
  payment: string;
  status: string;
  payment_status: string;
  created_at: string;
  cancelled_at: string;
  payment_response: string;
  partial_amount: number;
  partial_pending_amount: number;
}

const ViewBookingDetailPage: React.FC = (props) => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const [bookingData, setBookingData] = useState<BookingData | null>(null);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    const formData = new FormData();
    const token = localStorage.getItem("token") || "";
    formData.append("token", token);
    formData.append("id", id ?? "");
    Api.post("Admin/booked_room/booking_details", formData).then((res) => {
      if (res.data.status === "success") {
        setBookingData(res.data.data);
        setLoading(false);
      } else {
        alert(res.data.msg);
      }
    });
  };

  return (
    <div>
      <div className="card">
        <div className="card-header">
          <div
            onClick={() => navigate(-1)}
            className="mr-4"
            style={{ display: "inline-block" }}
          >
            <FontAwesomeIcon icon={faArrowLeft as any} />
          </div>
          <h4 style={{ fontSize: 18, display: "inline-block" }}>
            Booking Details
          </h4>
        </div>

        {loading ? (
          <div className="my-5 text-center">
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        ) : (
          <div className="container-fluid my-4">
            <div className="row">
              <div className="col-lg-12">
                <h4 style={{ fontSize: 20, marginBottom: 20 }}>
                  User Information
                </h4>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Full Name</th>
                      <td>
                        {bookingData?.full_name || bookingData?.user_name}
                      </td>
                    </tr>
                    <tr>
                      <th>Mobile</th>
                      <td>{bookingData?.mobile || bookingData?.user_mobile}</td>
                    </tr>
                    {bookingData?.user_email && (
                      <tr>
                        <th>Email</th>
                        <td>{bookingData.user_email}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              {bookingData?.current_room_price &&
                bookingData?.current_room_price > 0 && (
                  <div className="col-12">
                    <p className="alert alert-primary">
                      Room Price (One Night) -- Rs.
                      {bookingData.current_room_price}/-
                    </p>
                  </div>
                )}

              <div className="col-lg-6">
                <h4 style={{ fontSize: 20, marginBottom: 20 }}>
                  Booking Information
                </h4>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Booking Id</th>
                      <td>#{bookingData?.order_id}</td>
                    </tr>
                    <tr>
                      <th>Hotel</th>
                      <td>{bookingData?.hotel_name}</td>
                    </tr>
                    <tr>
                      <th>Guest</th>
                      <td>{bookingData?.guest}</td>
                    </tr>
                    <tr>
                      <th>Room</th>
                      <td>{bookingData?.rooms}</td>
                    </tr>
                    <tr>
                      <th>Check In date</th>
                      <td>{bookingData?.checkin_date}</td>
                    </tr>
                    <tr>
                      <th>Check Out date</th>
                      <td>{bookingData?.checkout_date}</td>
                    </tr>
                  </tbody>
                </Table>
              </div>
              <div className="col-lg-6">
                <h4 style={{ fontSize: 20, marginBottom: 20 }}>
                  Payment Information
                </h4>
                <Table striped bordered hover>
                  <tbody>
                    <tr>
                      <th>Room Price</th>
                      <td>{bookingData?.room_price}</td>
                    </tr>
                    <tr>
                      <th>Price Drop</th>
                      <td>{bookingData?.price_drop}</td>
                    </tr>
                    <tr>
                      <th>Payment Type</th>
                      <td>{bookingData?.payment}</td>
                    </tr>
                    <tr>
                      <th>Booking Status</th>
                      <td>{bookingData?.status}</td>
                    </tr>

                    {bookingData?.payment === "payathotel" && (
                      <tr>
                        <th>Payment Status</th>
                        <td>pending</td>
                      </tr>
                    )}

                    {bookingData?.payment !== "payathotel" && (
                      <tr>
                        <th>Payment Status</th>
                        {bookingData?.payment === "walkin" ? (
                          <td>success</td>
                        ) : (
                          <td>{bookingData?.payment_status}</td>
                        )}
                      </tr>
                    )}
                    <tr>
                      <th>Booking Date/Time</th>
                      <td>{bookingData?.created_at}</td>
                    </tr>
                    {bookingData?.status === "cancelled" && (
                      <tr>
                        <th>Cancellation Date/Time</th>
                        <td>{bookingData?.cancelled_at}</td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>

              {bookingData?.payment === "partial" &&
                bookingData.payment_response && (
                  <div className="col-lg-12">
                    <h4 style={{ fontSize: 20, marginBottom: 20 }}>
                      CCAvenue Information
                    </h4>
                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <th>Order ID</th>
                          <td>
                            {JSON.parse(bookingData.payment_response).order_id}
                          </td>
                        </tr>
                        <tr>
                          <th>Reference Id</th>
                          <td>
                            {
                              JSON.parse(bookingData.payment_response)
                                .tracking_id
                            }
                          </td>
                        </tr>
                        <tr>
                          <th>Paid Amount (Online)</th>
                          <td>{bookingData.partial_amount}</td>
                        </tr>
                        <tr>
                          <th>Paid Amount (Offline)</th>
                          <td>{bookingData.partial_pending_amount}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}

              {bookingData?.payment === "online" &&
                bookingData.payment_response && (
                  <div className="col-lg-12">
                    <h4 style={{ fontSize: 20, marginBottom: 20 }}>
                      CCAvenue Information
                    </h4>
                    <Table striped bordered hover>
                      <tbody>
                        <tr>
                          <th>Order ID</th>
                          <td>
                            {JSON.parse(bookingData.payment_response).order_id}
                          </td>
                        </tr>
                        <tr>
                          <th>Reference Id</th>
                          <td>
                            {
                              JSON.parse(bookingData.payment_response)
                                .tracking_id
                            }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewBookingDetailPage;
