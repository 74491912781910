import {
  Circle,
  GoogleMap,
  Marker,
  useJsApiLoader,
} from "@react-google-maps/api";
import React, { Fragment } from "react";

interface Place {
  id: number;
  name: string;
  latitude: string;
  longitude: string;
  circle?: {
    radius: number;
    options: {
      strokeColor: string;
    };
  };
}

interface MapProps {
  zoom: number;
  center: {
    lat: number;
    lng: number;
  };
  places: Place[];
}

const HotelMap = (props: MapProps) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyA16lJaEyxIY169-9tpNufGJCzF3FxHRxQ", // replace with your actual API key
  });

  console.log("isLoaded", isLoaded);
  console.log("loadError", loadError);

  if (!isLoaded) {
    return null;
  }
  return (
    <GoogleMap
      zoom={props.zoom}
      center={props.center}
      mapContainerStyle={{ height: 700, width: "100%" }}
    >
      {props.places.map((place) => (
        <Fragment key={place.id}>
          <Marker
            position={{
              lat: parseFloat(place.latitude),
              lng: parseFloat(place.longitude),
            }}
          />
          {place.circle && (
            <Circle
              center={{
                lat: parseFloat(place.latitude),
                lng: parseFloat(place.longitude),
              }}
              radius={place.circle.radius}
              options={place.circle.options}
            />
          )}
        </Fragment>
      ))}
    </GoogleMap>
  );
};

export default HotelMap;
