import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Form, Col, InputGroup, FormControl, Button } from "react-bootstrap";

interface Room {
  room_filled: boolean;
  room_name: string;
  room_category: string;
}

interface Floor {
  index: number;
  floor_name: string;
  rooms: Room[];
  [key: string]: any; // Dynamic keys for checked categories
}

interface RoomsFloorCheckboxProps {
  floor: Floor;
  floorIndex: number;
  room_cate_id: string;
  FloorData: Floor[];
}

const RoomsFloorCheckbox: React.FC<RoomsFloorCheckboxProps> = (props) => {
  const [checked, setChecked] = useState<boolean>(false);
  const [room_number, setRoomNumber] = useState<string>("");
  const [refreshLayout, setRefreshLayout] = useState<boolean>(false);

  const AddRoom = (floorIndx: number, roomno: string) => {
    if (roomno !== "") {
      const room_obj: Room = {
        room_filled: false,
        room_name: roomno,
        room_category: props.room_cate_id,
      };

      let find = false;
      props.FloorData.forEach((element) => {
        element.rooms.forEach((r) => {
          if (r.room_name === roomno) {
            find = true;
          }
        });
      });

      if (!find) {
        props.FloorData[floorIndx].rooms.push(room_obj);
        setRoomNumber("");
      }
    }
  };

  const RemoveRoom = (floor: number, roomindex: number) => {
    props.FloorData[floor].rooms.splice(roomindex, 1);
    setRefreshLayout(!refreshLayout);
  };

  const OnSelectRoomFloor = (floorIndex: number) => {
    const currentCheck = !checked;
    setChecked(currentCheck);
    props.FloorData[floorIndex][`cat_checked_${props.room_cate_id}`] =
      currentCheck;
  };

  return (
    <>
      <Form.Check
        name="room_floors"
        value={props.floor.index}
        label={props.floor.floor_name}
        type={"checkbox"}
        checked={checked}
        onChange={() => {
          OnSelectRoomFloor(props.floorIndex);
        }}
        id={`inline-checkbox-${props.floor.floor_name}`}
      />
      {checked && (
        <div className="room_add_block mt-3">
          <div className="All_rooms_Block">
            {props.floor.rooms.length > 0 && (
              <div>
                {props.floor.rooms.map((room, room_index) => {
                  return (
                    <div key={room_index.toString()} className="single_room">
                      <p
                        style={{
                          textDecoration:
                            room.room_category !== props.room_cate_id
                              ? "line-through"
                              : "none",
                        }}
                        className="m-0 pr-3"
                      >
                        {room.room_name}
                      </p>
                      {room.room_category === props.room_cate_id && (
                        <button
                          onClick={() => {
                            RemoveRoom(props.floorIndex, room_index);
                          }}
                          type="button"
                          className="btn text-danger p-0"
                        >
                          <FontAwesomeIcon icon={faTimes as any} />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <Col className="pl-0" lg="6">
            <InputGroup className="mb-3">
              <FormControl
                value={room_number}
                onChange={(event) => {
                  setRoomNumber(event.target.value);
                }}
                placeholder="Enter Room Number"
              />
              <InputGroup.Append>
                <Button
                  onClick={() => {
                    AddRoom(props.floorIndex, room_number);
                  }}
                  type="button"
                  variant="outline-primary"
                >
                  Add
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </div>
      )}
    </>
  );
};

export default RoomsFloorCheckbox;
