export const navigationJson = [
  {
    _tag: "CSidebarNavItem",
    name: "Dashboard",
    to: "/dashboard",
    icon: "cil-speedometer",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Users",
    to: "/users",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Vendors",
    to: "/vendors",
  },

  {
    _tag: "CSidebarNavItem",
    name: "Property List",
    to: "/property-list",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Refund Request",
    to: "/refund-request",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Price Modal",
    to: "/price-modal",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Raise Issues",
    to: "/raise-issue",
  },
  //  Booked rooms
  {
    _tag: "CSidebarNavDropdown",
    name: "Booked Rooms",
    to: "/booked-rooms",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "All Bookings",
        to: "/booked-rooms/all-bookings",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Silver Stays Booking",
        to: "/booked-rooms/all-app-bookings",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Previous Bookings",
        to: "/booked-rooms/all-previous-bookings",
      },
      {
        _tag: "CSidebarNavItem",
        name: "No Show Bookings",
        to: "/booked-rooms/all-no-show-bookings",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Walk-In Bookings",
        to: "/booked-rooms/all-walk-in-bookings",
      },
    ],
  },
  // App Hotels
  {
    _tag: "CSidebarNavDropdown",
    name: "Hotel",
    route: "/hotels",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "All Hotels",
        to: "/hotels/all-hotels",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Add Hotel",
        to: "/hotels/add-hotels",
      },
      {
        _tag: "CSidebarNavItem",
        name: "All Ratings",
        to: "/hotels/all-ratings",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Hotel Room Price Request",
        to: "/hotels/room-price-requests",
      },
    ],
  },
  // Home Settings
  {
    _tag: "CSidebarNavDropdown",
    name: "Home Settings",
    route: "/home-setting",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Home Cities",
        to: "/home-setting/home-cities",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Popular Hotels",
        to: "/home-setting/popular-hotels",
      },
    ],
  },
  // End
  {
    _tag: "CSidebarNavDropdown",
    name: "Coupons",
    route: "/coupons",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "All Coupon",
        to: "/coupons/all-coupons",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Add Coupon",
        to: "/coupons/add-coupons",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Offers",
    route: "/offers",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "All Offers",
        to: "/offers/all-offers",
      },
    ],
  },
  {
    _tag: "CSidebarNavDropdown",
    name: "Amenities",
    route: "/amenities",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "All Amenities",
        to: "/amenities/all-amenities",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Add Amenities",
        to: "/amenities/add-amenities",
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Rooms Category",
    route: "/rooms-category",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "All Rooms Category",
        to: "/rooms-category/all-rooms-category",
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Notification",
    route: "/notification",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Send Notification",
        to: "/notification/send",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Default Notifications",
        to: "/notification/default-notifications",
      },
    ],
  },

  {
    _tag: "CSidebarNavDropdown",
    name: "Policy",
    route: "/policy",
    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "All Policy",
        to: "/policy/all-policy",
      },
    ],
  },
  // App Settings
  {
    _tag: "CSidebarNavDropdown",
    name: "Settings",
    route: "/settings",

    _children: [
      {
        _tag: "CSidebarNavItem",
        name: "Guest policy",
        to: "/settings/guest-policy",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Cancellation policy",
        to: "/settings/cancellation-policy",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Privacy Policy",
        to: "/settings/term-and-condition",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Vendor Policy",
        to: "/settings/vendor-policy",
      },
      {
        _tag: "CSidebarNavItem",
        name: "Genral Information",
        to: "/settings/genral-information",
      },
    ],
  },
  {
    _tag: "CSidebarNavItem",
    name: "Helps",
    to: "/helps",
  },
  {
    _tag: "CSidebarNavItem",
    name: "Contact Us",
    to: "/contacts",
  },
];
