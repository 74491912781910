import React from "react";
import { Form } from "react-bootstrap";
import { FormikValues, FormikHandlers } from "formik";

interface Option {
  [key: string]: any;
}

interface SelectFieldProps {
  formProps: {
    errors: FormikValues;
    touched: FormikValues;
    values: FormikValues;
    handleBlur: FormikHandlers["handleBlur"];
    handleChange: FormikHandlers["handleChange"];
  };
  name: string;
  label: string;
  disabled?: boolean;
  options: Option[];
  selectValue: string;
  selectLabel: string;
}

const SelectField: React.FC<SelectFieldProps> = ({
  formProps,
  name,
  label,
  disabled,
  options,
  selectValue,
  selectLabel,
}) => {
  const { errors, touched, values, handleBlur, handleChange } = formProps;

  return (
    <Form.Group>
      <Form.Label>{label}</Form.Label>
      <Form.Control
        as="select"
        onBlur={handleBlur}
        disabled={disabled}
        value={values[name]}
        onChange={handleChange}
        name={name}
      >
        <option value={""}>Select</option>
        {options.length > 0 &&
          options.map((option, index) => (
            <option key={index} value={option[selectValue]}>
              {option[selectLabel]}
            </option>
          ))}
      </Form.Control>
      {errors[name] && touched[name] && (
        <Form.Text className="text-danger">{errors[name]}</Form.Text>
      )}
    </Form.Group>
  );
};

export default SelectField;
