import React, { useState } from "react";
import { Form, Col, InputGroup, FormControl, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

interface EditRoomsFloorCheckBoxProps {
  room_cate_id: string;
  floor: any;
  floorIndex: number;
  FloorData: any[];
}

const EditRoomsFloorCheckBox: React.FC<EditRoomsFloorCheckBoxProps> = ({
  room_cate_id,
  floor,
  floorIndex,
  FloorData,
}) => {
  const [checked, setChecked] = useState(
    !!floor["cat_checked_" + room_cate_id]
  );
  const [room_number, setroom_number] = useState("");
  const [refreshLayout, setrefreshLayout] = useState(false);

  const AddRoom = (floorIndx: number, roomno: string) => {
    if (roomno !== "") {
      let room_obj = {
        room_filled: false,
        room_name: roomno,
        room_category: room_cate_id,
      };

      let find = false;
      FloorData.forEach((element) => {
        element.rooms.forEach((r: any) => {
          if (r.room_name === roomno) {
            find = true;
          }
        });
      });
      if (!find) {
        FloorData[floorIndx].rooms.push(room_obj);
        setroom_number("");
      }
    }
  };

  const RemoveRoom = (roomindex: number) => {
    floor.rooms.splice(roomindex, 1);
    setrefreshLayout(!refreshLayout);
  };

  const OnSelectRoomFloor = (floorIndex: number) => {
    let currentCheck = !checked;
    setChecked(currentCheck);
    FloorData[floorIndex]["cat_checked_" + room_cate_id] = currentCheck;
  };

  return (
    <>
      <Form.Check
        name="room_floors"
        value={floor.index}
        label={floor.floor_name}
        type={"checkbox"}
        checked={checked}
        onChange={() => {
          OnSelectRoomFloor(floorIndex);
        }}
        id={`inline-checkbox-${floor.floor_name}`}
      />
      {checked && (
        <div className="room_add_block mt-3">
          <div className="All_rooms_Block">
            {floor.rooms.length > 0 && (
              <div>
                {floor.rooms.map((room: any, room_index: any) => {
                  return (
                    <div key={room_index.toString()} className="single_room">
                      <p
                        style={{
                          textDecoration:
                            room.room_category !== room_cate_id
                              ? "line-through"
                              : undefined,
                        }}
                        className="m-0 pr-3"
                      >
                        {room.room_name}
                      </p>

                      {room.room_category === room_cate_id && (
                        <button
                          onClick={() => {
                            RemoveRoom(room_index);
                          }}
                          type="button"
                          className="btn text-danger p-0"
                        >
                          <FontAwesomeIcon icon={faTimes as any} />
                        </button>
                      )}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
          <Col className="pl-0">
            <InputGroup className="mb-3">
              <FormControl
                onChange={(event) => {
                  setroom_number(event.target.value);
                }}
                placeholder="Enter Room Number"
              />
              <InputGroup.Append>
                <Button
                  onClick={() => {
                    AddRoom(floorIndex, room_number);
                  }}
                  type="button"
                  variant="outline-primary"
                >
                  Add
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </Col>
        </div>
      )}
    </>
  );
};

export default EditRoomsFloorCheckBox;
