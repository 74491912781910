const ROOMSFLOOR = [
  { index: "1", floor_name: "Ground Floor", rooms: [], checked: false },
  { index: "2", floor_name: "1st Floor", rooms: [], checked: false },
  { index: "3", floor_name: "2nd Floor", rooms: [], checked: false },
  { index: "4", floor_name: "3rd Floor", rooms: [], checked: false },
  { index: "5", floor_name: "4th Floor", rooms: [], checked: false },
  { index: "6", floor_name: "6th Floor", rooms: [], checked: false },
  { index: "7", floor_name: "7th Floor", rooms: [], checked: false },
  { index: "8", floor_name: "8th Floor", rooms: [], checked: false },
  { index: "9", floor_name: "9th Floor", rooms: [], checked: false },
  { index: "10", floor_name: "10th Floor", rooms: [], checked: false },
];

export default ROOMSFLOOR;
