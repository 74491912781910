import React, { useState, useEffect } from "react";
import { Button, Form, Col, Modal } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

interface OfferModalProps {
  show: boolean;
  closeModel: () => void;
  refreshList: () => void;
}

const AddOfferModal: React.FC<OfferModalProps> = ({
  show,
  closeModel,
  refreshList,
}) => {
  const [formError, setFormError] = useState<any>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [couponList, setCouponList] = useState<any[]>([]);
  const [selectCouponLoading, setSelectCouponLoading] = useState<boolean>(true);

  useEffect(() => {
    loadCoupons();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Example of dynamic state setting
    // TypeScript will infer the type based on target's name and value
    const { name, value } = e.target;
    setFormError(""); // Reset form error on change
    setFormSuccess(""); // Reset form success on change
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.target as any);

    let token = localStorage.getItem("token");

    formData.append("token", token ?? "");
    formData.append("sub_title", formData.get("sub_title") as string);
    formData.append("title", formData.get("title") as string);
    formData.append("valid_till", formData.get("valid_till") as string);
    formData.append("discription", formData.get("discription") as string);
    formData.append("coupon_code", formData.get("coupon_code") as string);

    Api.post("Admin/offers/add", formData).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        refreshList();
        closeModel();
      } else {
        setFormError(res.data["formErrors"]);
      }
    });
  };

  const loadCoupons = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    Api.post("Admin/coupon", formData).then((res) => {
      if (res.data["status"] === "success") {
        setCouponList(res.data["data"]);
        setSelectCouponLoading(false);
      } else {
        setCouponList([]);
      }
    });
  };

  return (
    <div>
      <Modal show={show} onHide={closeModel} keyboard={false} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Add Offer</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formSuccess && (
            <div>
              <CAlert color="success">{formSuccess}</CAlert>
            </div>
          )}
          <Form encType="multipart/form-data" onSubmit={handleSubmit}>
            <Form.Row>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    type="text"
                    placeholder="Enter offer title"
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.title}
                  </div>
                </Form.Group>
              </Col>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Sub Title</Form.Label>
                  <Form.Control
                    name="sub_title"
                    type="text"
                    placeholder="Enter sub title"
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.sub_title}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group controlId="exampleForm.SelectCustom">
                  <Form.Label>Coupon</Form.Label>
                  <Form.Control
                    name="coupon_code"
                    as="select"
                    custom
                    onChange={handleChange}
                  >
                    <option value="">--- select Coupon ---</option>
                    {couponList.map((list: any, index) => (
                      <option key={index.toString()} value={list?.coupon_code}>
                        {list?.coupon_code}
                      </option>
                    ))}
                  </Form.Control>
                  <div className="invalid-feedback d-block">
                    {formError?.coupon_code}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="6">
                <Form.Group>
                  <Form.Label>Valid Till</Form.Label>
                  <Form.Control
                    name="valid_till"
                    type="text"
                    placeholder="Enter Validity"
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.valid_till}
                  </div>
                </Form.Group>
              </Col>

              <Col lg="12">
                <Form.Group>
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={5}
                    placeholder="Enter description"
                    name="description"
                    onChange={handleChange}
                  />
                  <div className="invalid-feedback d-block">
                    {formError?.description}
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>

            <Button variant="primary" type="submit">
              Add
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddOfferModal;
