import React, { useState } from "react";
import { Modal, Form, Button, Col, Image } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";
import { msHelper } from "../../../services/ms-helper.service";

interface EditAminitiesModalProps {
  show: boolean;
  closeModel: () => void;
  data: {
    id: number;
    amenity: string;
    icon: File | string;
  };
  refreshList: () => void;
}

const EditAminitiesModal: React.FC<EditAminitiesModalProps> = ({
  show,
  closeModel,
  data,
  refreshList,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [amenity, setAmenity] = useState<string>(data.amenity);
  const [icon, setIcon] = useState<File | string>(data.icon);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAmenity(e.target.value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setIcon(e.target.files[0]);
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.target as any);
    let token = localStorage.getItem("token");
    let id = data.id;

    formData.append("token", token ?? "");
    formData.append("id", id.toString());
    formData.set("amenity", amenity);
    formData.set("icon", icon);

    let config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/Amenities/update", formData, config).then((res) => {
      if (res.data["status"] === "success") {
        setFormSuccess(res.data["msg"]);
        refreshList();
        setTimeout(() => {
          closeModel();
          setFormError("");
          setFormSuccess("");
          setIcon(data.icon);
        }, 1000);
      } else {
        setFormError(res.data["msg"]);
        console.error(res.data);
      }
    });
  };

  return (
    <div>
      <Modal size="lg" show={show} onHide={closeModel} keyboard={false}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Amenity</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && <CAlert color="danger">{formError}</CAlert>}
          {formSuccess && <CAlert color="success">{formSuccess}</CAlert>}
          <Form encType="multipart/form-data" onSubmit={handleSubmit}>
            <Form.Row>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Amenity Name</Form.Label>
                  <Form.Control
                    name="amenity"
                    type="text"
                    placeholder="Enter amenity name"
                    value={amenity}
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col lg="6">
                <Form.Group>
                  <Form.Label>Select Icon</Form.Label>
                  <Form.File onChange={onChange} name="icon" />
                </Form.Group>
              </Col>
              <Col lg="6">
                <div>
                  <Image
                    style={{ width: 50 }}
                    src={`${msHelper.ImageUrl}/amenity/${icon}`}
                  />
                </div>
              </Col>
            </Form.Row>

            <Button variant="primary" type="submit">
              Update
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default EditAminitiesModal;
