import React, { useState, useEffect } from "react";
import { Modal, Form, Button, Col } from "react-bootstrap";
import { CAlert } from "@coreui/react";
import { Api } from "../../../services/axios.service";

interface AddHomeCityModalProps {
  show: boolean;
  closeModel: () => void;
  refreshList: () => void;
}

const AddHomeCityModal: React.FC<AddHomeCityModalProps> = ({
  show,
  closeModel,
  refreshList,
}) => {
  const [formError, setFormError] = useState<string>("");
  const [formSuccess, setFormSuccess] = useState<string>("");
  const [city_img, setCityImg] = useState<any>("");
  const [state_list, setStateList] = useState<any[]>([]);
  const [city_list, setCityList] = useState<any[]>([]);
  const [select_state_loading, setSelectStateLoading] = useState<boolean>(true);
  const [select_city_loading, setSelectCityLoading] = useState<boolean>(true);

  useEffect(() => {
    loadStates();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCityImg(e.target.value);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCityImg(e.target.files?.[0]);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setFormError("");
    setFormSuccess("");

    const formData = new FormData(e.target as any);
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("city_id", formData.get("city") as string);
    formData.append("state_id", formData.get("state_id") as string);
    formData.append("city_img", city_img);

    var config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    Api.post("Admin/app_home/add_city", formData, config)
      .then((res) => {
        if (res.data["status"] === "success") {
          setFormSuccess(res.data["msg"]);
          refreshList();
          setTimeout(() => {
            closeModel();
            setFormError("");
            setFormSuccess("");
          }, 1000);
        } else {
          setFormError(res.data["msg"]);
        }
      })
      .catch((error) => {
        setFormError("Error adding city: " + error.message);
      });
  };

  const loadStateCity = (state: string) => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");
    formData.append("state_id", state);

    Api.post("Admin/region/get_cities_by_state_id", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setCityList(res.data["data"]);
          setSelectCityLoading(false);
        } else {
          setCityList([]);
        }
      })
      .catch((error) => {
        console.error("Error loading cities:", error);
      });
  };

  const loadStates = () => {
    const formData = new FormData();
    let token = localStorage.getItem("token");
    formData.append("token", token ?? "");

    Api.post("Admin/region/get_states", formData)
      .then((res) => {
        if (res.data["status"] === "success") {
          setStateList(res.data["data"]);
          setSelectStateLoading(false);
        } else {
          setStateList([]);
        }
      })
      .catch((error) => {
        console.error("Error loading states:", error);
      });
  };

  return (
    <div>
      <Modal show={show} onHide={closeModel} keyboard={false} centered>
        <Modal.Header closeButton>
          <Modal.Title>Add City</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {formError && <CAlert color="danger">{formError}</CAlert>}
          {formSuccess && <CAlert color="success">{formSuccess}</CAlert>}
          <Form onSubmit={handleSubmit} encType="multipart/form-data">
            <Form.Row>
              <Col lg="12">
                <Form.Group controlId="stateSelect">
                  <Form.Label>State</Form.Label>
                  <Form.Control
                    as="select"
                    name="state_id"
                    onChange={(event) => {
                      let state = event.target.value;
                      if (state === "") {
                        setCityList([]);
                      } else {
                        loadStateCity(state);
                      }
                    }}
                    custom
                  >
                    <option value="">--- Select State ---</option>
                    {state_list.map((list, index) => (
                      <option key={index.toString()} value={list.id}>
                        {list.name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>

              <Col lg="12">
                <Form.Group controlId="citySelect">
                  <Form.Label>City</Form.Label>
                  <Form.Control as="select" name="city" custom>
                    <option value="">--- Select City ---</option>
                    {city_list.map((list, index) => (
                      <option key={index.toString()} value={list.id}>
                        {list.city_name}
                      </option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col lg="12">
                <Form.Group>
                  <Form.Label>Select Image</Form.Label>
                  <Form.File onChange={onChange} name="city_img" />
                </Form.Group>
              </Col>
            </Form.Row>

            <Button variant="primary" type="submit">
              Add City
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default AddHomeCityModal;
