import { createBrowserRouter } from "react-router-dom";
import LoginPage from "../pages/login-page/LoginPage";
import BookingsList from "../pages/booking-list/BookingList";
import NoShowBookingsList from "../pages/no-show-booking-list/NoShowBookingsList";
import WalkInBookingsList from "../pages/walk-in-booking-list/WalkInBookingList";
import TheLayout from "../container/layout/TheLayout";
import Dashboard from "../pages/dashboard/Dashboard";
import UsersPage from "../pages/users-page/UsersPage";
import ViewUserPage from "../pages/view-user-page/ViewUserPage";
import ViewBookingDataPage from "../pages/view-booking-data-page/ViewBookingDataPage";
import ViewIssueDataPage from "../pages/view-issue-data-page/ViewIssueDataPage";
import InActiveUsersPage from "../pages/in-active-users-page/InActiveUsersPage";
import VendorsPage from "../pages/vendors-page/VendorsPage";
import InActiveVendorPage from "../pages/in-active-vendor-page/InActiveVendorPage";
import PropertyListPage from "../pages/property-list-page/PropertyListPage";
import ViewPropertyDetailsPage from "../pages/view-property-details-page/ViewPropertyDetailsPage";
import ViewVendorPage from "../pages/view-vendor-page/ViewVendorPage";
import HelpsPage from "../pages/helps-page/HelpsPage";
import ContactsPage from "../pages/contacts-page/ContactsPage";
import RefundRequestPage from "../pages/refund-request-page/RefundRequestPage";
import AllBookedRoomsPage from "../pages/booked-rooms-pages/all-booked-room-page/AllBookedRoomsPage";
import ViewBookingDetailPage from "../pages/booked-rooms-pages/view-booking-detail-page/ViewBookingDetailPage";
import AllAppBookingsPage from "../pages/booked-rooms-pages/all-app-bookings-page/AllAppBookingsPage";
import AllPreviousBookingsPage from "../pages/booked-rooms-pages/all-previous-bookings-page/AllPreviousBookingsPage";
import AllNoShowBookingPage from "../pages/booked-rooms-pages/all-no-show-booking-page/AllNoShowBookingPage";
import AllWalkingBookingPage from "../pages/booked-rooms-pages/all-walking-bookings-page/AllWalkingBookingPage";
import PriceModalPage from "../pages/price-modal-page/PriceModalPage";
import RaiseIssuePage from "../pages/raise-issue-page/RaiseIssuePage";
import AllHotelsPage from "../pages/hotel-pages/all-hotels-page/AllHotelsPage";
import AllRoomsPage from "../pages/rooms-page/all-rooms-page/AllRoomsPage";
import EditRoomPage from "../pages/rooms-page/edit-room-page/EditRoomPage";
import EditHotelPage from "../pages/hotel-pages/edit-hotel-page/EditHotelPage";
import AddHotelPage from "../pages/hotel-pages/add-hotel-page/AddHotelPage";
import HotelAllRatingPage from "../pages/hotel-pages/hotel-all-rating-page/HotelAllRatingPage";
import HotelRoomPriceRequestsPage from "../pages/hotel-pages/hotel-room-price-request-page/HotelRoomPriceRequestPage";
import AllCitiesPage from "../pages/home-setting-pages/all-cities-page/AllCitiesPage";
import PopularHotelsPage from "../pages/home-setting-pages/popular-hotels-page/PopularHotelsPage";
import AllCouponsPage from "../pages/coupons-pages/all-coupons-pages/AllCouponsPage";
import EditCouponPage from "../pages/coupons-pages/edit-coupon-page/EditCouponPage";
import AddCouponPage from "../pages/coupons-pages/add-coupon-page/AddCouponPage";
import AllOffers from "../pages/all-offers-page/AllOffersPage";
import AllAminitiesPage from "../pages/amenities-pages/all-amenities-page/AllAmenitiesPage";
import AddAminitiesPage from "../pages/amenities-pages/add-amenities-page/AddAmenitiesPage";
import RoomCategoriesPage from "../pages/room-categories-page/RoomCategoriesPage";
import SendNotificationPage from "../pages/notification-pages/send-notification-page/SendNotificationPage";
import DefaultNotifications from "../pages/notification-pages/deafult-notifications/DefaultNotifications";
import AllPolicyPage from "../pages/all-policy-page/AllPolicyPage";
import GuestPolicyPage from "../pages/settings-page/guest-policy-page/GuestPolicyPage";
import VendorPolicyPage from "../pages/settings-page/vendor-policy-page/VendorPolicyPage";
import CancellationPolicy from "../pages/settings-page/cancellatio-policy-page/CancellationPolicyPage";
import TermAndConditionPage from "../pages/settings-page/term-and-condition-page/TermAndConditionPage";
import GeneralInformationPage from "../pages/settings-page/general-information-page/GeneralInformationPage";

export const router = createBrowserRouter([
  { path: "/", element: <LoginPage /> },
  { path: "/booking-list", element: <BookingsList /> },
  { path: "/no-show-booking-list", element: <NoShowBookingsList /> },
  { path: "/walk-in-booking-list", element: <WalkInBookingsList /> },
  {
    path: "/",
    element: <TheLayout />,
    children: [
      {
        path: "dashboard",
        element: <Dashboard />,
      },
      {
        path: "users",
        element: <UsersPage />,
      },
      {
        path: "view-user/:id",
        element: <ViewUserPage />,
      },
      {
        path: "view-booking-data/:filter",
        element: <ViewBookingDataPage />,
      },
      {
        path: "view-issue-data/:filter",
        element: <ViewIssueDataPage />,
      },
      {
        path: "in-active-users",
        element: <InActiveUsersPage />,
      },
      {
        path: "vendors",
        element: <VendorsPage />,
      },
      {
        path: "in-active-vendors",
        element: <InActiveVendorPage />,
      },
      {
        path: "property-list",
        element: <PropertyListPage />,
      },
      {
        path: "view-property-detail/:id",
        element: <ViewPropertyDetailsPage />,
      },
      {
        path: "view-vendor/:id",
        element: <ViewVendorPage />,
      },
      {
        path: "helps",
        element: <HelpsPage />,
      },
      {
        path: "contacts",
        element: <ContactsPage />,
      },
      {
        path: "refund-request",
        element: <RefundRequestPage />,
      },
      {
        path: "booked-rooms",
        element: <AllBookedRoomsPage />,
      },
      {
        path: "booked-rooms/all-bookings",
        element: <AllBookedRoomsPage />,
      },
      {
        path: "booked-rooms/all-app-bookings",
        element: <AllAppBookingsPage />,
      },
      {
        path: "view-booking-detail/:id",
        element: <ViewBookingDetailPage />,
      },
      {
        path: "booked-rooms/all-previous-bookings",
        element: <AllPreviousBookingsPage />,
      },
      {
        path: "booked-rooms/all-no-show-bookings",
        element: <AllNoShowBookingPage />,
      },
      {
        path: "booked-rooms/all-walk-in-bookings",
        element: <AllWalkingBookingPage />,
      },
      {
        path: "price-modal",
        element: <PriceModalPage />,
      },
      {
        path: "raise-issue",
        element: <RaiseIssuePage />,
      },
      {
        path: "hotels",
        element: <AllHotelsPage />,
      },
      {
        path: "hotels/all-hotels",
        element: <AllHotelsPage />,
      },
      {
        path: "room/edit/:id",
        element: <EditRoomPage />,
      },
      {
        path: "rooms/:id",
        element: <AllRoomsPage />,
      },
      {
        path: "hotels/edit-hotel/:id",
        element: <EditHotelPage />,
      },
      {
        path: "hotels/add-hotels",
        element: <AddHotelPage />,
      },
      {
        path: "hotels/all-ratings",
        element: <HotelAllRatingPage />,
      },
      {
        path: "hotels/room-price-requests",
        element: <HotelRoomPriceRequestsPage />,
      },
      {
        path: "home-setting/home-cities",
        element: <AllCitiesPage />,
      },
      {
        path: "home-setting/popular-hotels",
        element: <PopularHotelsPage />,
      },
      {
        path: "coupons/all-coupons",
        element: <AllCouponsPage />,
      },
      {
        path: "coupons/edit-coupon/:id",
        element: <EditCouponPage />,
      },
      {
        path: "coupons/add-coupons",
        element: <AddCouponPage />,
      },
      {
        path: "offers/all-offers",
        element: <AllOffers />,
      },
      {
        path: "amenities/all-amenities",
        element: <AllAminitiesPage />,
      },
      {
        path: "amenities/add-amenities",
        element: <AddAminitiesPage />,
      },
      {
        path: "rooms-category/all-rooms-category",
        element: <RoomCategoriesPage />,
      },
      {
        path: "notification/send",
        element: <SendNotificationPage />,
      },
      {
        path: "notification/default-notifications",
        element: <DefaultNotifications />,
      },
      {
        path: "policy/all-policy",
        element: <AllPolicyPage />,
      },
      {
        path: "settings/guest-policy",
        element: <GuestPolicyPage />,
      },
      {
        path: "settings/vendor-policy",
        element: <VendorPolicyPage />,
      },
      {
        path: "settings/cancellation-policy",
        element: <CancellationPolicy />,
      },
      {
        path: "settings/term-and-condition",
        element: <TermAndConditionPage />,
      },
      {
        path: "settings/genral-information",
        element: <GeneralInformationPage />,
      },
      {
        path: "settings",
        element: <GuestPolicyPage />,
      },
    ],
  },
]);
