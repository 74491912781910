import React, { useEffect, useState } from "react";
import { Col, Modal } from "react-bootstrap";
import MapPicker from "react-google-map-picker";

interface Props {
  lat: number;
  lng: number;
}

interface Location {
  lat: number;
  lng: number;
}

const DefaultLocation: Location = { lat: 22.7196, lng: 75.8577 }; // INDORE
const DefaultZoom = 13;

const HotelLocationPicker: React.FC<Props> = (props) => {
  const [defaultLocation, setDefaultLocation] =
    useState<Location>(DefaultLocation);
  const [location, setLocation] = useState<Location>(defaultLocation);
  const [zoom, setZoom] = useState<number>(DefaultZoom);
  const [showLocationModel, setShowLocationModel] = useState<boolean>(false);

  useEffect(() => {
    if (Number(props.lat) === 0 || Number(props.lng) === 0) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      console.log("From Hotel ", props.lat);
      setLocation({
        lat: Number(props.lat),
        lng: Number(props.lng),
      });
    }
  }, [props.lat, props.lng]);

  const handleChangeLocation = (lat: number, lng: number) => {
    setLocation({ lat, lng });
  };

  const handleChangeZoom = (newZoom: number) => {
    setZoom(newZoom);
  };

  return (
    <Col>
      <div style={{ marginBottom: "10px" }}>
        Lat: <input readOnly type="text" name="lat" value={location.lat} />
        <span style={{ margin: "0 10px" }}></span>
        Lon: <input readOnly type="text" name="lng" value={location.lng} />
      </div>
      <div
        onClick={() => setShowLocationModel(true)}
        className="btn btn-primary"
      >
        <i className="fas fa-map-marker-alt"></i>
        {" Pick Location"}
      </div>
      <Modal
        size="lg"
        show={showLocationModel}
        onHide={() => setShowLocationModel(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title>Location </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <MapPicker
              defaultLocation={location}
              zoom={zoom}
              style={{ height: "500px", width: "100%" }}
              onChangeLocation={handleChangeLocation}
              onChangeZoom={handleChangeZoom}
              apiKey="AIzaSyA16lJaEyxIY169-9tpNufGJCzF3FxHRxQ"
            />
          </div>
        </Modal.Body>
      </Modal>
    </Col>
  );
};

export default HotelLocationPicker;
