import moment from "moment";

export const bookingSheetData = (data: any) => {
  const no_of_nights = moment(data.checkout_date).diff(
    moment(data.checkin_date),
    "days"
  );
  const totalNumberofNights = no_of_nights * data.rooms;
  const total_price = data.room_price;
  const total_conve_fee = data.rooms * no_of_nights * data.conve_fee;
  const total_platform_fee =
    ((total_price - total_conve_fee) * data.plateform_fee) / 100;
  const total_commission =
    ((total_price - total_conve_fee) * data.commision) / 100;
  const GST =
    ((total_conve_fee + total_platform_fee + total_commission) * 18) / 100;

  const TDS = (total_price * 1) / 100;

  const GrossOwnerShare =
    total_price -
    total_conve_fee -
    total_platform_fee -
    total_commission -
    GST -
    TDS;
  const GrossSilverStaysShare = total_price - GrossOwnerShare;

  //   Checking Collection
  let collectbyown = 0;
  let collectbystays = 0;
  if (data.payment === "online") {
    collectbystays = total_price;
  } else if (data.payment === "payathotel") {
    collectbyown = total_price;
  } else if (data.payment === "partial") {
    collectbystays = data.partial_amount;
    collectbyown = data.partial_pending_amount;
  }

  return {
    totalNumberofNights,
    total_price,
    total_conve_fee,
    total_commission,
    total_platform_fee,
    GST,
    GrossOwnerShare,
    GrossSilverStaysShare,
    collectbyown,
    collectbystays,
    no_of_nights,
    TDS,
  };
};
