import React, { memo, useEffect, useState } from "react";
import { CChartBar } from "@coreui/react-chartjs";
import { Api } from "../../../../../services/axios.service";

interface Props {
  updateDataSet: (datasets: any, total_month_count: number) => void;
  style?: React.CSSProperties;
}

interface Dataset {
  backgroundColor: string;
  borderColor: string;
  borderWidth: number;
  color: string;
  data: number[];
  label: string;
  pointHoverBackgroundColor: string;
}

const MainChart = (props: Props) => {
  const [chartLabels, setChartLabels] = useState<number[]>([]);
  const [defaultDatasets, setDefaultDatasets] = useState<Dataset[]>([]);

  const defaultOptions = {
    maintainAspectRatio: false,
    legend: {
      display: true,
    },
    scales: {
      xAxes: [
        {
          gridLines: {
            drawOnChartArea: false,
          },
        },
      ],
      // yAxes: [{
      //   ticks: {
      //     beginAtZero: true,
      //     maxTicksLimit: 5,
      //     stepSize: Math.ceil(250 / 5),
      //     max: 50000
      //   },
      //   gridLines: {
      //     display: true
      //   }
      // }]
    },
    elements: {
      point: {
        radius: 0,
        hitRadius: 10,
        hoverRadius: 4,
        hoverBorderWidth: 3,
      },
    },
  };

  useEffect(() => {
    getDateWiseBooking();
  }, []);

  const getDateWiseBooking = () => {
    loadDataDay();
  };

  const loadDataDay = async () => {
    try {
      const formData = new FormData();
      let token = localStorage.getItem("token");
      formData.append("token", token ?? "");
      const response = await Api.post("Admin/dashboard/graph_by_day", formData);
      const res = response.data;
      const data = res.data;
      console.log("data: ", data);

      if (res.status === "success") {
        setChartLabels(data.chart_labels || []);
        setDefaultDatasets(data.datasets || []);
        props.updateDataSet(data.datasets, data.total_month_count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  if (!defaultDatasets || defaultDatasets.length === 0) {
    return null;
  }

  return (
    <>
      {/* <CChartBar
        style={props.style}
        datasets={defaultDatasets}
        options={defaultOptions}
        labels={chartLabels}
      /> */}
    </>
  );
};

export default memo(MainChart);
